import { getField, updateField } from 'vuex-map-fields';
// import actions from '../medico/ApiList/actions';
// import axios from '@/modules/common/axiosERP';
// import { util } from '@/plugins/util';
export default {
  namespaced: true,
  state: {
    isLoading: true,
    pagina_actual: 1,
    por_pagina: 50,
    pagina_opciones: [50, 100],
    totalFilas: 0,
    delayTimeout: null,
    filtroSeleccionado: 'clinica',
    listaReserva: [],
    estado: true,
    busqueda: null,
    selectedItem: null,
    confirmarReserva: false,
    reservaId: null,
    isFacturaTerceroReserva: false,
    isReservaClinica: false,
  },
  getters: {
    getField,
    getReservaId: (state) => state.reservaId,
    getIsFacturaTerceroReserva: (state) => state.isFacturaTerceroReserva,
    getIsReservaClinica: (state) => state.isReservaClinica,
  },
  mutations: {
    updateField,
    setIsLoading: (state, data) => { state.isLoading = data; },
    setPaginaActual: (state, data) => { state.pagina_actual = data; },
    setPorPagina: (state, data) => { state.por_pagina = data; },
    setTotalFilas: (state, data) => { state.totalFilas = data; },
    setDelayTimeout: (state, data) => { state.delayTimeout = data; },
    setFiltroSeleccionado: (state, data) => { state.filtroSeleccionado = data; },
    setListaReserva: (state, data) => { state.listaReserva = data; },
    setEstado: (state, data) => { state.estado = data; },
    setBusqueda: (state, data) => { state.busqueda = data; },
    setSelectItem: (state, data) => { state.selectedItem = data; },
    setConfirmarReserva: (state, data) => { state.cierreConfirmado = data; },
    setReservaId: (state, data) => { state.reservaId = data; },
    setIsFacturaTerceroReserva: (state, data) => { state.isFacturaTerceroReserva = data; },
    setIsReservaClinica: (state, data) => { state.isReservaClinica = data; },

  },
  actions: {
    resetModalReserva({ commit }) {
      commit('setIsLoading', true);
      commit('setPaginaActual', 1);
      commit('setPorPagina', 50);
      commit('setTotalFilas', 0);
      commit('setDelayTimeout', null);
      commit('setFiltroSeleccionado', 'clinica');
      commit('setListaReserva', []);
      commit('setEstado', true);
      commit('setBusqueda', null);
      commit('setSelectItem', null);
      commit('setConfirmarReserva', false);
      commit('setReservaId', null);
      commit('setIsFacturaTerceroReserva', false);
      commit('setIsReservaClinica', false);
    },
  },
};
