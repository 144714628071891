export default {
  namespaced: true,
  state: {
    pacientes: [],
    listaFamiliares: [],
  },
  getters: {
    getPacientes: (state) => state.pacientes,
    getlistaFamiliares: (state) => state.listaFamiliares,
  },
  mutations: {
    setPacientes: (state, data) => { state.pacientes = data; },
    setlistaFamiliares: (state, data) => { state.listaFamiliares = data; },
    addItemlistaFamiliares: (state, item) => state.listaFamiliares.push(item),
  },
};
