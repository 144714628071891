import { isBloqueado } from '../utilidades';

export default {
  isBloqueadoFecha(state) {
    const KEY = 'VC_FECHA';
    return isBloqueado(state, KEY);
  },
  isBloqueadoCambiarMoneda(state) {
    const KEY = 'VC_MONEDA';
    return isBloqueado(state, KEY);
  },
  isBloqueadoFacturaManual(state) {
    const KEY = 'VC_FACTURA_MANUAL';
    return isBloqueado(state, KEY);
  },
  isBloqueadoVentaSinFactura(state) {
    const KEY = 'VC_SIN_FACTURA';
    return isBloqueado(state, KEY);
  },
  isBloqueadoAseguradora(state) {
    const KEY = 'VC_ACTIVAR_ASEGURADORA';
    return isBloqueado(state, KEY);
  },
  isBloqueadoPrecioVenta(state) {
    const KEY = 'VC_EDITAR_PRECIO_VENTA';
    return isBloqueado(state, KEY);
  },
  isBloqueadoAjusteAnticipo(state) {
    const KEY = 'VC_HACER_AJUSTE';
    return isBloqueado(state, KEY);
  },
  isBloqueadoDarACredito(state) {
    const KEY = 'VC_VENTA_CREDITO';
    return isBloqueado(state, KEY);
  },
  isBloqueadoCrearNuevoPaciente(state) {
    const KEY = 'VC_CREAR_PACIENTES';
    return isBloqueado(state, KEY);
  },
  isBloqueadoMedicoTerceros(state) {
    const KEY = 'CLINICA_MEDICO_TERCERO';
    return isBloqueado(state, KEY);
  },
  isBloqueadoReConsulta(state) {
    const KEY = 'VC_RECONSULTA';
    return isBloqueado(state, KEY);
  },
};
