import { getField, updateField } from 'vuex-map-fields';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

const ESTADO_INICIAL = {
  cierreConfirmado: false,
  listaServicios: [],
};

export default {
  namespaced: true,
  state: { ...ESTADO_INICIAL },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setCierreConfirmado: (state, data) => { state.cierreConfirmado = data; },
  },
  actions: {
    async getServicios({ commit }) {
      try {
        const REQUEST = {
          categoria_id: 0,
          actividad_economica_id: 0,
          factura_tercero: false,
        };
        const RESPONSE = await axios.get('/clinic/invoicing/services', {
          params: REQUEST,
        });
        const { servicios } = RESPONSE.data.data;
        commit('updateField', { path: 'listaServicios', value: servicios });
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async getNumeroFactura({ commit, rootState, rootGetters }, servicioID) {
      try {
        let medicoID = null;
        if (rootState.formFactura.medicoSelected !== null) {
          medicoID = rootState.formFactura.medicoSelected.id;
        }
        let aseguradoraID = null;
        if (rootState.formFactura.aseguradoraSelected !== null) {
          aseguradoraID = rootState.formFactura.aseguradoraSelected.id;
        }
        const CON_FACTURA = rootGetters['formFactura/isTipoFacturaConFactura'];
        const REQUEST = {
          servicio_id: servicioID,
          is_factura: CON_FACTURA,
          tipo_factura: rootGetters['formFactura/getTipoFactura'],
          factura_tercero: rootState.formFactura.checkFacturaTercero,
          medico_id: medicoID,
          seguro_medico: rootState.formFactura.checkSeguroEstado,
          aseguradora_id: aseguradoraID,
        };
        const RESPONSE = await axios.get('/clinic/invoicing/service/prices', {
          params: REQUEST,
        });
        const NUMERO_FACTURA = RESPONSE.data.data.numero_factura;
        const DOCUMENTO_SECTOR_ID = RESPONSE.data.data.documento_sector_id;
        const TIPO_DOCUMENTO_ID = RESPONSE.data.data.tipo_documento_id;
        const MODO_EDICION = rootGetters['facturacion/getModoEdicion'];

        commit('addServiceModal/setDocumentoSectorId', DOCUMENTO_SECTOR_ID, { root: true });
        commit('addServiceModal/setSiatTipoFacturaId', TIPO_DOCUMENTO_ID, { root: true });
        if (CON_FACTURA && MODO_EDICION === false) {
          const FACTURA_TERCERO = rootState.formFactura.checkFacturaTercero;
          const TIPO_FACTURA_MANUAL = rootGetters['formFactura/isTipoFacturaManual'];
          if (!(FACTURA_TERCERO && TIPO_FACTURA_MANUAL)) {
            commit('formFactura/setNroFactura', NUMERO_FACTURA, { root: true });
          }
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    resetModalCierreTratamiento({ commit }) {
      Object.entries(ESTADO_INICIAL).forEach(([key, value]) => {
        commit('updateField', { path: key, value });
      });
    },
  },
};
