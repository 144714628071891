import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    listaServicios: [],
  },
  getters: {
    getField,
    subTotal(state) {
      let total = 0.00;
      state.listaServicios.forEach((item) => {
        if (item.estado === 1) {
          total = parseFloat(total) + parseFloat(item.sub_total);
        }
      });
      return total;
    },
    totalSeguro(state) {
      let total = 0.00;
      state.listaServicios.forEach((item) => {
        if (item.estado === 1) {
          total = parseFloat(total) + (parseFloat(item.sub_total_seguro) * item.cantidad);
        }
      });
      return total;
    },
    Total(state, getters) {
      return parseFloat(getters.subTotal) - parseFloat(getters.totalSeguro);
    },
  },
  mutations: {
    updateField,
    setListaServicios: (state, data) => { state.listaServicios = data; },
    addItemListaServicios: (state, item) => state.listaServicios.push(item),
  },
  actions: {
    resetTablaServicios({ commit }) {
      commit('setListaServicios', []);
    },
  },
};
