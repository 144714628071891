import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    tablaHonorarioMedico: [],
    listaServicios: [],
    listaCategorias: [],
    servicioSelected: null,
    categoriaSelected: null,
    precioCosto: 0,
    precioPaciente: 0,
  },
  getters: {
    getField,
    tablaHonorarioMedicoFiltered(state) {
      return state.tablaHonorarioMedico.filter((item) => item.estado === true);
    },
  },
  mutations: {
    updateField,
    setTablaHonorarioMedico: (state, data) => { state.tablaHonorarioMedico = data; },
    setListaServicios: (state, data) => { state.listaServicios = data; },
    setListaCategorias: (state, data) => { state.listaCategorias = data; },
    setServicioSelected: (state, data) => { state.servicioSelected = data; },
    setCategoriaSelected: (state, data) => { state.categoriaSelected = data; },
    setPrecioCosto: (state, data) => { state.precioCosto = data; },
    setPrecioPaciente: (state, data) => { state.precioPaciente = data; },
  },
  actions: {
    resetModalHonorarioMedico({ commit }) {
      commit('setTablaHonorarioMedico', []);
      commit('setListaServicios', []);
      commit('setListaCategorias', []);
      commit('setServicioSelected', null);
      commit('setCategoriaSelected', null);
      commit('setPrecioCosto', 0);
      commit('setPrecioPaciente', 0);
    },
  },
};
