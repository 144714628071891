import store from '@/store/index';
import { redirectIfIsMedic } from '@/store/modules/privilegios/utilidades';

const title = 'Consulta-Externa';
const aseguradoraRoutes = [
  {
    path: 'consulta-externa',
    name: 'consulta-externa-index',
    component: () => import('../views/index.vue'),
    meta: {
      title,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasTipoUser'];
      const MEDICO_ID = store.getters['main/medico_id'];
      redirectIfIsMedic(PERMISO, next, MEDICO_ID);
    },
  },
  {
    path: 'consulta-externa/atencion-paciente',
    name: 'ce-atencion-paciente-index',
    component: () => import('../views/atencionPaciente.vue'),
    meta: {
      title: 'Atención Paciente',
    },
  },
  {
    path: 'consulta-externa/:id/edit',
    name: 'consulta-externa-edit',
    component: () => import('../views/edit.vue'),
    meta: {
      title: `${title} | Editar`,
    },
  },
  {
    path: 'consulta-externa/:id/paciente',
    name: 'consulta-externa-paciente',
    component: () => import('../views/pacienteMedico.vue'),
    meta: {
      title,
    },
  },
];
export default aseguradoraRoutes;
