export default {
  namespaced: true,
  state: {
    parentescos: [],
  },
  getters: {
    getParentescos: (state) => state.parentescos,
  },
  mutations: {
    setParentescos: (state, data) => { state.parentescos = data; },
  },
};
