import { hasPermision, hasFuncionalidadEmpresa } from './utilidades';

export default {
  hasPermisionListarCalendario(state) {
    const KEY = 'CAL_LISTAR';
    const ACCION = 'listar';
    return hasPermision(state, KEY, ACCION);
  },
  hasFuncionalidadCalendario(state) {
    const FUNCIONALIDADS = state.funcionalidadAccions;
    const FUNCIONALIDAD_ID = FUNCIONALIDADS.CAL_LISTAR.funcionalidad_id;
    const ACCION = FUNCIONALIDADS.CAL_LISTAR.accion;
    return hasFuncionalidadEmpresa(state, FUNCIONALIDAD_ID, ACCION);
  },
};
