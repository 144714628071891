import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function callStoreApi({ commit, rootState }) {
  try {
    const NOMBRE_CATEGORIA = rootState.formularioCategoriaLinae.nombreCategoria;
    const CODIGO_CATEGORIA = rootState.formularioCategoriaLinae.codigoCategoria;
    const LISTA_SUB_CATEGORIAS = rootState.formularioCategoriaLinae.listaSubCategorias;
    const REQUEST = {
      nombre: NOMBRE_CATEGORIA,
      codigo: CODIGO_CATEGORIA,
      sub_categorias: LISTA_SUB_CATEGORIAS,
    };
    const RESPONSE = await axios.post('/clinic/parametros/categoria_linae/store', REQUEST);
    util.showNotify(RESPONSE.data.message, 'success');
    commit('formularioCategoriaLinae/setComponentShow', 'TheIndexCategoriaLinae', { root: true });
    return true;
  } catch (error) {
    const FALLO_VALIDACION = 422;
    if (error.response.status !== FALLO_VALIDACION) {
      util.showNotify(error.response.data.message, 'error');
    } else {
      Object.entries(error.response.data.data).forEach(([, mensajes]) => {
        mensajes.forEach((texto) => util.showNotify(texto, 'error'));
      });
    }
    return false;
  }
}
