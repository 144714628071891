import BootstrapVue from 'bootstrap-vue';
import VueTagsInput from '@johmun/vue-tags-input';
import VueTheMask from 'vue-the-mask';
import Vuelidate from 'vuelidate';
import VueLazyload from 'vue-lazyload';
import Meta from 'vue-meta';
import Breadcumb from '../components/breadcumb.vue';

import '@/assets/styles/sass/themes/lite-purple.scss';

export default {
  install(Vue) {
    Vue.use(BootstrapVue);
    Vue.component(
      'large-sidebar',
      () => import('../containers/layouts/largeSidebar'),
    );

    Vue.component(
      'compact-sidebar',
      () => import('../containers/layouts/compactSidebar'),
    );
    Vue.component(
      'vertical-sidebar',
      () => import('../containers/layouts/verticalSidebar'),
    );
    Vue.component(
      'customizer',
      () => import('../components/common/customizer.vue'),
    );
    Vue.component('breadcumb', Breadcumb);
    Vue.component('vue-perfect-scrollbar', () => import('vue-perfect-scrollbar'));
    // Vue.component(VueCropper);
    Vue.use(Meta, {
      keyName: 'metaInfo',
      attribute: 'data-vue-meta',
      ssrAttribute: 'data-vue-meta-server-rendered',
      tagIDKeyName: 'vmid',
      refreshOnceOnNavigation: true,
    });
    Vue.use(Vuelidate);
    Vue.use(VueTheMask);
    Vue.use(VueTagsInput);
    Vue.use(VueLazyload, {
      observer: true,
      // optional
      observerOptions: {
        rootMargin: '0px',
        threshold: 0.1,
      },
    });
  },
};
