import store from '@/store/index';
import { redirectIfHasPermission } from '@/store/modules/privilegios/utilidades';

const title = 'Paciente';
const pacienteRoutes = [
  {
    path: 'paciente',
    name: 'paciente-index',
    component: () => import('../views/index.vue'),
    meta: {
      title,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionListarPaciente'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'paciente/create',
    name: 'paciente-create',
    component: () => import('../views/create.vue'),
    meta: {
      title: `${title} | Nuevo`,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionNuevoPaciente'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'paciente/:id/edit',
    name: 'paciente-edit',
    component: () => import('../views/edit.vue'),
    meta: {
      title: `${title} | Editar`,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionModificarPaciente'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'paciente/importar',
    name: 'paciente-import',
    component: () => import('../views/import.vue'),
    meta: {
      title: `${title} | Importar`,
    },
  },
];
export default pacienteRoutes;
