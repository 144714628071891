import store from '@/store/index';
import { redirectIfHasPermission } from '@/store/modules/privilegios/utilidades';

const title = 'Calendario';
const calendarioRoutes = [
  {
    path: 'calendario',
    name: 'calendario-index',
    component: () => import('../views/index.vue'),
    meta: {
      title,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionListarCalendario'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
];
export default calendarioRoutes;
