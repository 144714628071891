import facturacion from './facturacion';
import formFactura from './formFactura';
import modalTratamientoEnCurso from './modalTratamientoEnCurso';
import modalCierreTratamiento from './modalCierreTratamiento';
import seguroMedicoBar from './seguroMedicoBar';
import tableAndAddService from './tableAndAddService';
import addServiceModal from './addServiceModal';
import formaPagoModal from './formaPagoModal';
import formaPagoFacturaTercero from './formaPagoFacturaTercero';
import detalleFactura from './detalleFactura';
import modalReserva from './modalReserva';

const facturacionStore = {
  facturacion,
  formFactura,
  modalTratamientoEnCurso,
  modalCierreTratamiento,
  seguroMedicoBar,
  tableAndAddService,
  addServiceModal,
  formaPagoModal,
  formaPagoFacturaTercero,
  detalleFactura,
  modalReserva,
};

export default facturacionStore;
