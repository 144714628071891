import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  async getDataServicioComplementario({ commit }, dto) {
    try {
      const servicioComplId = dto.catServicioCompId;
      const SEGURO = dto.seguro;
      commit('setLoading', true);
      const params = {
        aseguradora_id: SEGURO ? SEGURO.id : '',
      };
      const response = await axios.get(`/clinic/servicio_complementario/servicios/list/${servicioComplId}`, { params });
      commit('setServicios', response.data.data);
    } catch (error) {
      const FALLO_VALIDACION = 422;
      if (error.response.status !== FALLO_VALIDACION) {
        util.showNotify(error.response.data.message, 'error');
      } else {
        Object.entries(error.response.data.data).forEach(([, mensajes]) => {
          mensajes.forEach((texto) => util.showNotify(texto, 'error'));
        });
      }
    } finally {
      commit('setLoading', false);
    }
  },
  async getPrecioServicios({ commit }, dto) {
    const ASEGURADORA_ID = dto.aseguradora_id;
    const SERVICIO_ID = dto.servicio_id;
    const SEGURO_MEDICO = ASEGURADORA_ID !== null;
    const TIPO_CAMBIO = dto.tipo_cambio;
    const TIPO_MONEDA = dto.tipo_moneda;
    const MONEDA_ID = dto.moneda_id;
    const MONEDAS = dto.monedas;
    try {
      const REQUEST = {
        servicio_id: SERVICIO_ID,
        is_factura: false,
        tipo_factura: null,
        factura_tercero: false,
        medico_id: null,
        seguro_medico: SEGURO_MEDICO,
        aseguradora_id: ASEGURADORA_ID,
      };
      const RESPONSE = await axios.get('/clinic/invoicing/service/prices', {
        params: REQUEST,
      });
      const { precios } = RESPONSE.data.data;
      const { servicio } = RESPONSE.data.data;
      const SEGURO_SERVICIO = RESPONSE.data.data.seguro_servicio;
      const PRECIOS_CONFIG = {
        precios,
        seguroServicio: SEGURO_SERVICIO,
        monedas: MONEDAS,
        monedaSelectedId: MONEDA_ID,
        tipoMoneda: TIPO_MONEDA,
        monedaServicioID: servicio.moneda_id,
        tipoCambio: TIPO_CAMBIO,
      };
      commit('setPrecioTipoCambio', PRECIOS_CONFIG);
      commit('setPrecioNormalSelected', precios[0]);
      commit('setPrecioMontoNormal', precios[0].monto);
    } catch (error) {
      util.showNotify(error.response.data.message, 'error');
    }
  },
  resetDataTheModaServios({ commit }) {
    commit('setServicios', []);
    commit('setServicioSelected', null);
    commit('setLoading', false);
    commit('setListaPrecioSeguros', []);
    commit('setListaPrecios', []);
    commit('setPrecioSeguroSelected', null);
    commit('setPrecioNormalSelected', null);
    commit('setPrecioMontoNormal', 0);
  },
};
