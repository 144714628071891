import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function updateNombreDiagnostico({ commit, rootState }) {
  try {
    const NOMBRE_DIAGNOSTICO = rootState.formularioClasificacion.nombreDiagnostico;
    const NOMBRE_TRATAMIENTO_PRINCIPAL = rootState.formularioClasificacion.nombreTratamientoPrincipal;
    const ID = rootState.formularioClasificacion.idNombreDiagnostico;
    if (!NOMBRE_DIAGNOSTICO) {
      util.showNotify('El nombre del diagnostico es requerido', 'warn');
      return;
    }
    const REQUEST = {
      id: ID,
      nombre: NOMBRE_DIAGNOSTICO,
      nombre_tratamiento_principal: NOMBRE_TRATAMIENTO_PRINCIPAL,
      nombre_farmacia: null,
    };
    const RESPONSE = await axios.post('/clinic/parametros/nombre_diagnostico', REQUEST);
    const NOMBRE_PARAMETO = RESPONSE.data.data.nombre_parametro;
    if (NOMBRE_PARAMETO) {
      commit('formularioClasificacion/setNombreDiagnositco', NOMBRE_PARAMETO.nombre_diagnostico, { root: true });
      commit('formularioClasificacion/setIdNombreDiagnositco', NOMBRE_PARAMETO.id, { root: true });
      commit('formularioClasificacion/setNombreTratamientoPrincipal', NOMBRE_PARAMETO.nombre_tratamiento_principal, { root: true });
    }
    util.showNotify(RESPONSE.data.message, 'success');
  } catch (error) {
    util.showNotify(error.response.data.message, 'error');
  }
}
