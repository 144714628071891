import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    isDisabledAddButton: true,
    actividadEconomicaId: 0,
    categoriaServiciosSelected: null,
    categoriaServicios: [],
    serviciosSelected: [],
    servicios: [],
    precioSelected: null,
    precios: [],
    precioSelectedCombo: '',
    cantidad: 1,
    listaTemporal: [],
    listaDetalleTemporal: [],
    impuestos: null,
    precioSeguroSelected: null,
    listaPrecioSeguros: [],
    inputPrecioSeguro: 0,
  },
  getters: {
    getField,
    getSubTotalComboServicios(state) {
      let subTotalModal = 0.00;
      state.listaDetalleTemporal.forEach((item) => {
        subTotalModal = parseFloat(subTotalModal) + parseFloat(item.precio_unitario);
      });
      return subTotalModal;
    },
  },
  mutations: {
    updateField,
    setIsDisabledAddButton: (state, data) => { state.isDisabledAddButton = data; },
    setActividadEconomicaId: (state, data) => { state.actividadEconomicaId = data; },
    setCategoriaServiciosSelected: (state, data) => { state.categoriaServiciosSelected = data; },
    setCategoriaServicios: (state, data) => { state.categoriaServicios = data; },
    setServiciosSelected: (state, data) => { state.serviciosSelected = data; },
    setServicios: (state, data) => { state.servicios = data; },
    setPrecioSelected: (state, data) => { state.precioSelected = data; },
    setPrecios: (state, data) => { state.precios = data; },
    setPrecioSelectedCombo: (state, data) => { state.precioSelectedCombo = data; },
    setCantidad: (state, data) => { state.cantidad = data; },
    setListaTemporal: (state, data) => { state.listaTemporal = data; },
    setItemListaTemporal: (state, data) => { state.listaTemporal.push(data); },
    setListaDetalleTemporal: (state, data) => { state.listaDetalleTemporal = data; },
    setItemListaDetalleTemporal: (state, data) => { state.listaDetalleTemporal.push(data); },
    deleteLastListaDetalleTemporal: (state) => { state.listaDetalleTemporal.pop(); },
    setImpuestos: (state, data) => { state.impuestos = data; },
    setPrecioSeguroSelected: (state, data) => { state.precioSeguroSelected = data; },
    setListaPrecioSeguros: (state, data) => { state.listaPrecioSeguros = data; },
    setInputPrecioSeguro: (state, data) => { state.inputPrecioSeguro = data; },

    setPrecioTipoCambio(state, data) {
      state.precios = data.precios;
      state.listaPrecioSeguros = data.seguroServicio ?? [];
      state.precios.forEach((precio) => {
        let conversion = 1;
        const MONEDA_PRINCIPAL = 1;
        const monedaIdServicio = data.monedaServicioID;
        if (parseInt(monedaIdServicio, 10) !== parseInt(data.monedaSelectedId, 10)) {
          if (data.tipoMoneda === MONEDA_PRINCIPAL) {
            const { tc } = data.monedas.find((moneda) => moneda.id === monedaIdServicio);
            conversion = tc;
          } else {
            conversion = (1 / data.tipoCambio);
          }
        }
        /* eslint no-param-reassign: "error" */
        precio.monto *= parseFloat(conversion);
      });
      state.listaPrecioSeguros.forEach((precio) => {
        let conversion = 1;
        const MONEDA_PRINCIPAL = 1;
        const monedaIdServicio = data.monedaServicioID;
        if (parseInt(monedaIdServicio, 10) !== parseInt(data.monedaSelectedId, 10)) {
          if (data.tipoMoneda === MONEDA_PRINCIPAL) {
            const { tc } = data.monedas.find((moneda) => moneda.id === monedaIdServicio);
            conversion = tc;
          } else {
            conversion = (1 / data.tipoCambio);
          }
        }
        /* eslint no-param-reassign: "error" */
        precio.monto *= parseFloat(conversion);
        precio.seguro *= parseFloat(conversion);
      });
    },
    deleteItemListaTemporal(state, data) {
      state.listaTemporal = state.listaTemporal.filter((el) => (
        el.id !== data
      ));
    },
    deleteItemListaDetalleTemporal(state, data) {
      state.listaDetalleTemporal = state.listaDetalleTemporal.filter((el) => (
        el.tipo_servicio_id !== data
      ));
    },
  },
  actions: {
    resetModalWhenClosed({ commit, rootState, dispatch }) {
      commit('setServiciosSelected', []);
      commit('setListaTemporal', []);
      commit('setListaDetalleTemporal', []);
      commit('setPrecioSelected', null);
      commit('setPrecios', []);
      commit('setPrecioSelectedCombo', '');
      commit('setPrecioSeguroSelected', null);
      commit('setListaPrecioSeguros', []);
      commit('setInputPrecioSeguro', 0);
      commit('setCantidad', 1);
      if (rootState.tablaServicios.listaServicios.length === 0) {
        commit('setActividadEconomicaId', 0);
        dispatch('cotizacionAPIS/obtenerServicios', null, { root: true });
      }
      commit('setIsDisabledAddButton', true);
    },
    resetDatosModalServicios({ commit }) {
      commit('setActividadEconomicaId', 0);
      commit('setServiciosSelected', []);
      commit('setListaTemporal', []);
      commit('setListaDetalleTemporal', []);
      commit('setPrecioSelected', null);
      commit('setPrecios', []);
      commit('setPrecioSelectedCombo', '');
      commit('setCantidad', 1);
      commit('setPrecioSeguroSelected', null);
      commit('setListaPrecioSeguros', []);
      commit('setInputPrecioSeguro', 0);
      commit('setIsDisabledAddButton', true);
    },
    resetModalServicios({ commit }) {
      commit('setIsDisabledAddButton', true);
      commit('setActividadEconomicaId', 0);
      commit('setCategoriaServiciosSelected', null);
      commit('setCategoriaServicios', []);
      commit('setServiciosSelected', []);
      commit('setServicios', []);
      commit('setPrecioSelected', null);
      commit('setPrecios', []);
      commit('setPrecioSelectedCombo', '');
      commit('setCantidad', 1);
      commit('setListaTemporal', []);
      commit('setListaDetalleTemporal', []);
      commit('setImpuestos', null);
      commit('setPrecioSeguroSelected', null);
      commit('setListaPrecioSeguros', []);
      commit('setInputPrecioSeguro', 0);
    },
  },
};
