import Vue from 'vue';
import Notifications from 'vue-notification';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import GullKit from './plugins/gull.kit';

import './boot/sentry';
import './boot/analytics';

Vue.use(vueNumeralFilterInstaller);
Vue.use(GullKit);
Vue.use(Notifications);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
