import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    busqueda: null,
    listarTabla: [],
    estado: true,
    porPagina: 50,
    paginaActual: 1,
    paginaOpciones: [50, 100],
    sortBy: 'numero',
    isLoading: true,
    sortDesc: true,
    totalFilas: 0,
    fields: [
      {
        key: 'editar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
      },
      {
        key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
      },
      {
        key: 'numero', label: 'N°', thClass: 'th-custom-color', thStyle: { width: '5%' }, sortable: true,
      },
      {
        key: 'codigo', label: 'Codigo', thClass: 'th-custom-color', thStyle: { width: '10%' }, sortable: true,
      },
      {
        key: 'categoria', label: 'Nombre Categoria', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '30%' }, sortable: true,
      },
      {
        key: 'subcategoria_linae', label: 'Nombre Sub Categoria', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '30%' }, sortable: true,
      },
    ],
    componentShow: 'TheIndexCategoriaLinae',

    // data crear y editar
    nombreCategoria: null,
    codigoCategoria: null,
    listaSubCategorias: [],
    codigoSubCategoria: null,
    nombreSubCategoria: null,
    readInputs: [],
    numero: null,
    itemId: null,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setBusqueda: (state, data) => { state.busqueda = data; },
    setListarTabla: (state, data) => { state.listarTabla = data; },
    setEstado: (state, data) => { state.estado = data; },
    setTotalFila: (state, data) => { state.totalFilas = data; },
    setComponentShow: (state, data) => { state.componentShow = data; },
    setPorPagina: (state, data) => { state.porPagina = data; },
    setPaginaActual: (state, data) => { state.paginaActual = data; },
    setIsLoadin: (state, data) => { state.isLoading = data; },

    setNombreCategoria: (state, data) => { state.nombreCategoria = data; },
    setCodigoCategoria: (state, data) => { state.codigoCategoria = data; },
    setListaSubCategoria: (state, data) => { state.listaSubCategorias = data; },
    setCodigoSubCategoria: (state, data) => { state.codigoSubCategoria = data; },
    setNombreSubCategoria: (state, data) => { state.nombreSubCategoria = data; },
    setReadInputs: (state, data) => { state.readInputs = data; },
    setNumero: (state, data) => { state.numero = data; },
    setItemId: (state, data) => { state.itemId = data; },
  },
  actions: {
    resetFormularioCategoriaLinae({ commit }) {
      commit('setBusqueda', null);
      commit('setComponentShow', 'TheIndexCategoriaLinae');

      commit('setNombreCategoria', null);
      commit('setCodigoCategoria', null);
      commit('setListaSubCategoria', []);
      commit('setCodigoSubCategoria', null);
      commit('setNombreSubCategoria', null);
      commit('setReadInputs', []);
      commit('setItemId', null);
    },
    resetFoumularioCategoriStore({ commit }) {
      commit('setNombreCategoria', null);
      commit('setCodigoCategoria', null);
      commit('setListaSubCategoria', []);
      commit('setCodigoSubCategoria', null);
      commit('setNombreSubCategoria', null);
      commit('setReadInputs', []);
      commit('setNumero', null);
      commit('setIsLoadin', false);
      commit('setItemId', null);
    },
  },
};
