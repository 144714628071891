import { updateField } from 'vuex-map-fields';

export default {
  updateField,
  setStateSelected(state, data) { state.stateSelected = data; },
  setPacientes(state, data) { state.pacientes = data; },
  setMedico(state, data) { state.medico = data; },
  setPerPage(state, data) { state.perPage = data; },
  setTotal(state, data) { state.total = data; },
  setPacienteAtendidos(state, data) { state.listaPacienteAtendidos = data; },
  setPacientesInConsultaExternas(state, data) { state.pacientesInConsultaExternas = data; },
  setPacientesInConsultaExternaSelected(state, data) { state.pacientesInConsultaExternaSelected = data; },
  setBusqueda(state, data) { state.busqueda = data; },
};
