import { hasPermision, hasFuncionalidadEmpresa } from './utilidades';

export default {
  hasPermisionListarEnfermeria(state) {
    const KEY = 'ENF_LISTAR';
    const ACCION = 'listar';
    return hasPermision(state, KEY, ACCION);
  },
  hasFuncionalidadEnfermeria(state) {
    const FUNCIONALIDADS = state.funcionalidadAccions;
    const FUNCIONALIDAD_ID = FUNCIONALIDADS.ENF_LISTAR.funcionalidad_id;
    const ACCION = FUNCIONALIDADS.ENF_LISTAR.accion;
    return hasFuncionalidadEmpresa(state, FUNCIONALIDAD_ID, ACCION);
  },
};
