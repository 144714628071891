import { getField, updateField } from 'vuex-map-fields';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  namespaced: true,
  state: {
    indicacionMedica: null,
    recetas: [],
    preEscribirs: [],
    indicacionEnfermera: null,
    nombreParametroCatFarmacia: null,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setIndicacionMedica: (state, data) => { state.indicacionMedica = data; },
    setRecetas: (state, data) => { state.recetas = data; },
    setPreEscribir: (state, data) => { state.preEscribirs = data; },
    setIndicacionEnfermera: (state, data) => { state.indicacionEnfermera = data; },
    setNombreParametroCatFarmacia: (state, data) => { state.nombreParametroCatFarmacia = data; },
  },
  actions: {
    resetTheModalIndicacionMedica({ commit }) {
      commit('setIndicacionMedica', null);
      commit('setRecetas', []);
      commit('setPreEscribir', []);
      commit('setIndicacionEnfermera', null);
      commit('setNombreParametroCatFarmacia', null);
    },
    async getIndicacionMedica({ commit }, { id, origen }) {
      try {
        const RESPONSE = await axios.get(`/clinic/tratamientos/enfermeria/indicacion_medica/${id}/edit`, {
          params: { origen },
        });
        commit('setIndicacionMedica', RESPONSE.data.data.indicacion_medica);
        commit('setIndicacionEnfermera', RESPONSE.data.data.indicacion_medica.descripcion_enfermeria);
        commit('setPreEscribir', RESPONSE.data.data.prescribir);
        commit('setRecetas', RESPONSE.data.data.receta);
        commit('setNombreParametroCatFarmacia', RESPONSE.data.data.nombre_parametro_categoria_farmacia);
        return true;
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
        return false;
      }
    },
    getStaticData({ commit }) {
      commit('setIndicacionMedica', {
        id: 76,
        numero: 3,
        medico: 'Jorge',
        indicacion_medico: '4x3=12',
        descripcion_enfermeria: null,
        cierre: 0,
      });
      // eslint-disable-next-line no-undef
      commit('setIndicacionEnfermera', null); // O el valor que corresponda
      // eslint-disable-next-line no-undef
      commit('setRecetas', [
        {
          medico: 'Jorge', medicamento: 'HILO MONONYLON 2', indicacion: null, via: null, cantidad: 1, farmacia: '',
        },
        {
          medico: 'Jorge', medicamento: 'PARACETAMOL GOTAS', indicacion: null, via: null, cantidad: 1, farmacia: '',
        },
      ]);

      // eslint-disable-next-line no-undef
      commit('setPreEscribir', [
        {
          medico: 'Jorge', servicio: 'ECOGRAFIA ABDOMINAL', descripcion: 'ecografia', cantidad: 1, estado: '',
        },
        {
          medico: 'Jorge', servicio: 'ECOGRAFIA HEPATOBILIOPANCREATICA', descripcion: 'ecografia', cantidad: 1, estado: '',
        },
      ]);

      // eslint-disable-next-line no-undef
      commit('setNombreParametroCatFarmacia', {
        id: 12,
        nombre: 'Medicamento1',
        estado: 1,
        visualiza_stock: 1,
        agrega_producto: 1,
        servicio_complementario_categoria_id: 8,
      });
    },
    async updateIndicacion({ state }, dto) {
      try {
        const INDICACION_MEDICAS = [];
        const objIndicacion = {
          id: state.indicacionMedica.id,
          descripcion_enfermeria: state.indicacionEnfermera,
          cierre: dto.cierre,
        };
        INDICACION_MEDICAS.push(objIndicacion);
        const params = {
          tratamiento_id: dto.tratamiento_id,
          indicacion_medica: INDICACION_MEDICAS,
        };
        const RESPONSE = await axios.put('/clinic/tratamientos/enfermeria/update/indicacion', params);
        util.showNotify(RESPONSE.data.message, 'success');
        return true;
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
        return false;
      }
    },
  },
};
