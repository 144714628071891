import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function callIndexApi({ commit, rootState }) {
  try {
    const FILTER_KEY = rootState.formularioCategoriaLinae.busqueda;
    const STATE = rootState.formularioCategoriaLinae.estado;
    const PAGE = rootState.formularioCategoriaLinae.paginaActual;
    const PER_PAGE = rootState.formularioCategoriaLinae.porPagina;
    const PARAMS = {
      estado: STATE,
      filter_key: FILTER_KEY,
      page: PAGE,
      per_page: PER_PAGE,
    };
    const RESPONSE = await axios.get('/clinic/parametros/categoria_linae', {
      params: PARAMS,
    });
    const CATEGORIA_LINAE = RESPONSE.data.data.categorias.data;
    const TOTAL_FILA = RESPONSE.data.data.categorias.total;
    commit('formularioCategoriaLinae/setListarTabla', CATEGORIA_LINAE, { root: true });
    commit('formularioCategoriaLinae/setTotalFila', TOTAL_FILA, { root: true });
  } catch (error) {
    util.showNotify(error.response.data.message, 'error');
  }
}
