// store/modules/salaCama.js
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    horaSalida: '',
  },

  mutations: {
    updateField,
    setHoraSalida(state, payload) {
      state.horaSalida = payload;
    },
  },

  actions: {
    async saveHoraSalida({ state }) {
      const REQUEST = {
        hora_salida_sala_cama: state.horaSalida,
      };
      try {
        const RESPONSE = await axios.post('/clinic/parametros/sala_camas/store_hora_salida', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
  },

  getters: {
    getField,
  },

};
