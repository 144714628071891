export default {
  namespaced: true,
  state: {
    listaDatosFactura: [],
    typeDocuments: [],
  },
  getters: {
    getlistaDatosFactura: (state) => state.listaDatosFactura,
  },
  mutations: {
    setlistaDatosFactura: (state, data) => { state.listaDatosFactura = data; },
    addItemlistaDatos: (state, item) => state.listaDatosFactura.push(item),
    setTypeDocuments: (state, data) => { state.typeDocuments = data; },
  },
};
