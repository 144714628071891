import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import moment from 'moment/moment';

export default {
  async create({ commit, dispatch }, id) {
    try {
      dispatch('resetState');
      dispatch('theModalAntecedente/resetTheModalAntecedente', null, { root: true });
      const params = {
        paciente_id: id,
      };
      const RESPONSE = await axios.get('/clinic/consultation/patient/create', { params });
      commit('setPaciente', RESPONSE.data.data.paciente);
      const ALMACENES = RESPONSE.data.data.almacenes;
      commit('setAlmacenes', ALMACENES);
      commit('setAlmacenSelected', ALMACENES[0]);
      const MONEDAS = RESPONSE.data.data.monedas;
      commit('setMonedas', MONEDAS);
      const CLASIFICACIONCIES = RESPONSE.data.data.clasificacion_cies;
      commit('setClasificacionCies', CLASIFICACIONCIES);
      const NOMBRE_DIAGNOSTICO = RESPONSE.data.data.nombre_parametro_consulta_externa;
      if (NOMBRE_DIAGNOSTICO) {
        commit('setNombreDiagnostico', NOMBRE_DIAGNOSTICO.nombre_diagnostico);
      }
      const ACTIVIDAD_FISICAS = RESPONSE.data.data.t_actividad_fisica;
      commit('theModalAntecedente/settipoActividadFisicas', ACTIVIDAD_FISICAS, { root: true });

      const TIPO_ALCOHOS = RESPONSE.data.data.t_alcohol;
      commit('theModalAntecedente/setTipoAlcohos', TIPO_ALCOHOS, { root: true });

      const TIPO_CIRUGIAS = RESPONSE.data.data.t_cirugia;
      commit('theModalAntecedente/setTipoCirugias', TIPO_CIRUGIAS, { root: true });

      const TIPO_DIETAS = RESPONSE.data.data.t_dieta;
      commit('theModalAntecedente/setTipoDietas', TIPO_DIETAS, { root: true });

      const TIPO_MEDICAMENTOS = RESPONSE.data.data.t_medicamento;
      commit('theModalAntecedente/setTipoMedicamentos', TIPO_MEDICAMENTOS, { root: true });

      const TIPO_OCUPACIONS = RESPONSE.data.data.t_ocupacion;
      commit('theModalAntecedente/setTipoOcupacions', TIPO_OCUPACIONS, { root: true });

      const TIPO_PATOLOGIAS = RESPONSE.data.data.t_patologia;
      commit('theModalAntecedente/setTipoPatologias', TIPO_PATOLOGIAS, { root: true });

      const TIPO_PSICOSOCIALS = RESPONSE.data.data.t_psicosocial;
      commit('theModalAntecedente/setTipoPsicosocials', TIPO_PSICOSOCIALS, { root: true });

      const TIPO_TABACOS = RESPONSE.data.data.t_tabaco;
      commit('theModalAntecedente/setTipoTabacos', TIPO_TABACOS, { root: true });

      const TIPO_VIVIENDAS = RESPONSE.data.data.t_vivienda;
      commit('theModalAntecedente/setTipoViviendas', TIPO_VIVIENDAS, { root: true });

      // consulta
      const CONSULTA = RESPONSE.data.data.consulta;
      commit('setConsulta', CONSULTA);
      if (CONSULTA) {
        commit('setTratamiento', CONSULTA.tratamiento);
        commit('setExamenFisico', CONSULTA.examen_fisico);
        commit('setEnfermedadActual', CONSULTA.enfermedad_actual);
        commit('setMotivoConsulta', CONSULTA.motivo_consulta);
        commit('setDescripcionDiagnostico1', CONSULTA.diagnostico1);
        commit('setDescripcionDiagnostico2', CONSULTA.diagnostico2);
        commit('setDescripcionDiagnostico3', CONSULTA.diagnositoc3);
        commit('setdescripcionDiagnostico4', CONSULTA.diagnostico4);
        commit('setFecha', new Date(moment(CONSULTA.fecha_reconsulta)));

        // clasificacionCie
        const CLASIFICACION_CIE1 = CLASIFICACIONCIES.find((el) => (
          el.id === CONSULTA.clasificacion_cie1_id));
        const CLASIFICACION_CIE2 = CLASIFICACIONCIES.find((el) => (
          el.id === CONSULTA.clasificacion_cie2_id));
        const CLASIFICACION_CIE3 = CLASIFICACIONCIES.find((el) => (
          el.id === CONSULTA.clasificacion_cie3_id));
        const CLASIFICACION_CIE4 = CLASIFICACIONCIES.find((el) => (
          el.id === CONSULTA.clasificacion_cie4_id));

        commit('setDiagnostico1', CLASIFICACION_CIE1);
        commit('setDiagnostico2', CLASIFICACION_CIE2);
        commit('setDiagnostico3', CLASIFICACION_CIE3);
        commit('setDiagnostico4', CLASIFICACION_CIE4);
      }

      // CIERRE DE CONSULTA
      const CONSULTA_CIERRE = RESPONSE.data.data.consulta_cierre;
      commit('setConsultaCierre', CONSULTA_CIERRE);
      // if (CONSULTA_CIERRE) {
      //   commit('setTratamiento', CONSULTA_CIERRE.tratamiento);
      //   commit('setExamenFisico', CONSULTA_CIERRE.examen_fisico);
      //   commit('setEnfermedadActual', CONSULTA_CIERRE.enfermedad_actual);
      //   commit('setMotivoConsulta', CONSULTA_CIERRE.motivo_consulta);
      //   commit('setDescripcionDiagnostico1', CONSULTA_CIERRE.diagnostico1);
      //   commit('setDescripcionDiagnostico2', CONSULTA_CIERRE.diagnostico2);
      //   commit('setDescripcionDiagnostico3', CONSULTA_CIERRE.diagnositoc3);
      //   commit('setdescripcionDiagnostico4', CONSULTA_CIERRE.diagnostico4);
      //   commit('setFecha', new Date(moment(CONSULTA_CIERRE.fecha_reconsulta)));
      //   // clasificacionCie
      //   const CLASIFICACION_CIE1 = CLASIFICACIONCIES.find((el) => (
      //     el.id === CONSULTA_CIERRE.clasificacion_cie1_id));
      //   const CLASIFICACION_CIE2 = CLASIFICACIONCIES.find((el) => (
      //     el.id === CONSULTA_CIERRE.clasificacion_cie2_id));
      //   const CLASIFICACION_CIE3 = CLASIFICACIONCIES.find((el) => (
      //     el.id === CONSULTA_CIERRE.clasificacion_cie3_id));
      //   const CLASIFICACION_CIE4 = CLASIFICACIONCIES.find((el) => (
      //     el.id === CONSULTA_CIERRE.clasificacion_cie4_id));

      //   commit('setDiagnostico1', CLASIFICACION_CIE1);
      //   commit('setDiagnostico2', CLASIFICACION_CIE2);
      //   commit('setDiagnostico3', CLASIFICACION_CIE3);
      //   commit('setDiagnostico4', CLASIFICACION_CIE4);
      // }
    } catch (error) {
      util.showNotify(error.response.data.message, 'error');
    }
  },
  async edit({ commit }, id) {
    try {
      const response = await axios.get(`/clinic/consultation/patient/${id}/edit`);
      commit('setPaciente', response.data.data.paciente);
      const ALMACENES = response.data.data.almacenes;
      commit('setAlmacenes', ALMACENES);
      commit('setAlmacenSelected', ALMACENES[0]);
      const MONEDAS = response.data.data.monedas;
      commit('setMonedas', MONEDAS);
      commit('setMonedaPrincipal', MONEDAS);
      const CLASIFICACIONCIES = response.data.data.clasificacion_cies;
      commit('setClasificacionCies', CLASIFICACIONCIES);

      const NOMBRE_DIAGNOSTICO = response.data.data.nombre_parametro_consulta_externa;
      if (NOMBRE_DIAGNOSTICO) {
        commit('setCantidadDiagnostico', NOMBRE_DIAGNOSTICO.cantidad_diagnostico);
        commit('setNombreDiagnostico', NOMBRE_DIAGNOSTICO.nombre_diagnostico);
      }
      const CONSULTA = response.data.data.consulta;
      commit('setConsulta', CONSULTA);
      if (CONSULTA) {
        commit('setTratamiento', CONSULTA.tratamiento);
        commit('setExamenFisico', CONSULTA.examen_fisico);
        commit('setEnfermedadActual', CONSULTA.enfermedad_actual);
        commit('setMotivoConsulta', CONSULTA.motivo_consulta);
        commit('setDescripcionDiagnostico1', CONSULTA.diagnostico1);
        commit('setDescripcionDiagnostico2', CONSULTA.diagnostico2);
        commit('setDescripcionDiagnostico3', CONSULTA.diagnositoc3);
        commit('setdescripcionDiagnostico4', CONSULTA.diagnostico4);
        if (CONSULTA.fecha_reconsulta) {
          commit('setFecha', new Date(moment(CONSULTA.fecha_reconsulta)));
        }
        /* // clasificacionCie
        const CLASIFICACION_CIE1 = CLASIFICACIONCIES.find((el) => (
          el.id === CONSULTA.clasificacion_cie1_id));
        const CLASIFICACION_CIE2 = CLASIFICACIONCIES.find((el) => (
          el.id === CONSULTA.clasificacion_cie2_id));
        const CLASIFICACION_CIE3 = CLASIFICACIONCIES.find((el) => (
          el.id === CONSULTA.clasificacion_cie3_id));
        const CLASIFICACION_CIE4 = CLASIFICACIONCIES.find((el) => (
          el.id === CONSULTA.clasificacion_cie4_id)); */

        commit('setDiagnostico1', CONSULTA.clasificacion_cie1_id);
        commit('setDiagnostico2', CONSULTA.clasificacion_cie2_id);
        commit('setDiagnostico3', CONSULTA.clasificacion_cie3_id);
        commit('setDiagnostico4', CONSULTA.clasificacion_cie4_id);

        /* const SUBCLASIFICACION_CIE1 = CLASIFICACIONCIES.find((el) => (
          el.id === CONSULTA.subclasificacion_cie1_id));
        const SUBCLASIFICACION_CIE2 = CLASIFICACIONCIES.find((el) => (
          el.id === CONSULTA.subclasificacion_cie2_id));
        const SUBCLASIFICACION_CIE3 = CLASIFICACIONCIES.find((el) => (
          el.id === CONSULTA.subclasificacion_cie3_id));
        const SUBCLASIFICACION_CIE4 = CLASIFICACIONCIES.find((el) => (
          el.id === CONSULTA.subclasificacion_cie4_id)); */

        commit('setSubclasificacion_cie1_id', CONSULTA.subclasificacion_cie1_id);
        commit('setSubclasificacion_cie2_id', CONSULTA.subclasificacion_cie2_id);
        commit('setSubclasificacion_cie3_id', CONSULTA.subclasificacion_cie3_id);
        commit('setSubclasificacion_cie4_id', CONSULTA.subclasificacion_cie4_id);
      }

      // CIERRE DE CONSULTA
      const CONSULTA_CIERRE = response.data.data.consulta_cierre;
      commit('setConsultaCierre', CONSULTA_CIERRE);
      // if (CONSULTA_CIERRE) {
      //   commit('setTratamiento', CONSULTA_CIERRE.tratamiento);
      //   commit('setExamenFisico', CONSULTA_CIERRE.examen_fisico);
      //   commit('setEnfermedadActual', CONSULTA_CIERRE.enfermedad_actual);
      //   commit('setMotivoConsulta', CONSULTA_CIERRE.motivo_consulta);
      //   commit('setDescripcionDiagnostico1', CONSULTA_CIERRE.diagnostico1);
      //   commit('setDescripcionDiagnostico2', CONSULTA_CIERRE.diagnostico2);
      //   commit('setDescripcionDiagnostico3', CONSULTA_CIERRE.diagnositoc3);
      //   commit('setdescripcionDiagnostico4', CONSULTA_CIERRE.diagnostico4);
      //   commit('setFecha', new Date(moment(CONSULTA_CIERRE.fecha_reconsulta)));
      //   // clasificacionCie
      //   const CLASIFICACION_CIE1 = CLASIFICACIONCIES.find((el) => (
      //     el.id === CONSULTA_CIERRE.clasificacion_cie1_id));
      //   const CLASIFICACION_CIE2 = CLASIFICACIONCIES.find((el) => (
      //     el.id === CONSULTA_CIERRE.clasificacion_cie2_id));
      //   const CLASIFICACION_CIE3 = CLASIFICACIONCIES.find((el) => (
      //     el.id === CONSULTA_CIERRE.clasificacion_cie3_id));
      //   const CLASIFICACION_CIE4 = CLASIFICACIONCIES.find((el) => (
      //     el.id === CONSULTA_CIERRE.clasificacion_cie4_id));

      //   commit('setDiagnostico1', CLASIFICACION_CIE1);
      //   commit('setDiagnostico2', CLASIFICACION_CIE2);
      //   commit('setDiagnostico3', CLASIFICACION_CIE3);
      //   commit('setDiagnostico4', CLASIFICACION_CIE4);
      // }
      // modal ANTECEDENTES
      const ACTIVIDAD_FISICAS = response.data.data.t_actividad_fisica;
      commit('theModalAntecedente/settipoActividadFisicas', ACTIVIDAD_FISICAS, { root: true });

      const TIPO_ALCOHOS = response.data.data.t_alcohol;
      commit('theModalAntecedente/setTipoAlcohos', TIPO_ALCOHOS, { root: true });

      const TIPO_CIRUGIAS = response.data.data.t_cirugia;
      commit('theModalAntecedente/setTipoCirugias', TIPO_CIRUGIAS, { root: true });

      const TIPO_DIETAS = response.data.data.t_dieta;
      commit('theModalAntecedente/setTipoDietas', TIPO_DIETAS, { root: true });

      const TIPO_MEDICAMENTOS = response.data.data.t_medicamento;
      commit('theModalAntecedente/setTipoMedicamentos', TIPO_MEDICAMENTOS, { root: true });

      const TIPO_OCUPACIONS = response.data.data.t_ocupacion;
      commit('theModalAntecedente/setTipoOcupacions', TIPO_OCUPACIONS, { root: true });

      const TIPO_PATOLOGIAS = response.data.data.t_patologia;
      commit('theModalAntecedente/setTipoPatologias', TIPO_PATOLOGIAS, { root: true });

      const TIPO_PSICOSOCIALS = response.data.data.t_psicosocial;
      commit('theModalAntecedente/setTipoPsicosocials', TIPO_PSICOSOCIALS, { root: true });

      const TIPO_TABACOS = response.data.data.t_tabaco;
      commit('theModalAntecedente/setTipoTabacos', TIPO_TABACOS, { root: true });

      const TIPO_VIVIENDAS = response.data.data.t_vivienda;
      commit('theModalAntecedente/setTipoViviendas', TIPO_VIVIENDAS, { root: true });
      const TIPO_ADMINISTRACION_FARMACOS = response.data.data.tipo_via_administracions_farmacos;
      commit('setTipoViaAdministracions', TIPO_ADMINISTRACION_FARMACOS);

      const ANTECEDENTES = response.data.data.antecedente;
      if (ANTECEDENTES) {
        commit('setAntecedente', ANTECEDENTES);
        const TIPO_DIETA_SELECTED = TIPO_DIETAS.find((el) => el.id === ANTECEDENTES.tipo_dieta_id);
        const TIPO_VIVIENDA_SELECTED = TIPO_VIVIENDAS.find((el) => (
          el.id === ANTECEDENTES.tipo_vivienda_id));
        const TIPO_TABACO_SELECTED = TIPO_TABACOS.find((el) => (
          el.id === ANTECEDENTES.tipo_tabaco_id));
        const TIPO_ALCOHOL_SELECTED = TIPO_ALCOHOS.find((el) => (
          el.id === ANTECEDENTES.tipo_alcohol_id));
        const FECHA_INICIO_TABACO = ANTECEDENTES.f_inicio_tabaco
          ? new Date(moment(ANTECEDENTES.f_inicio_tabaco)) : new Date();
        const FECHA_INICIO_ALCOHOL = ANTECEDENTES.f_inicio_alcohol
          ? new Date(moment(ANTECEDENTES.f_inicio_alcohol)) : new Date();
        const TIPO_MEDICAMENTO_SELECTED = TIPO_MEDICAMENTOS.find((el) => (
          el.id === ANTECEDENTES.tipo_medicamento_id));
        const TIPO_ACTIVIDAD_FISICA_SELECTED = ACTIVIDAD_FISICAS.find((el) => (
          el.id === ANTECEDENTES.tipo_actividad_fisica_id));
        const TIPO_OCUPACION_SELECTED = TIPO_OCUPACIONS.find((el) => (
          el.id === ANTECEDENTES.tipo_ocupacion_id));
        const TIPO_PSICOSOCIAL_SELECTED = TIPO_PSICOSOCIALS.find((el) => (
          el.id === ANTECEDENTES.tipo_psicosocial_id));

        commit('theModalAntecedente/setTipoDietaSelected', TIPO_DIETA_SELECTED, { root: true });
        commit('theModalAntecedente/setTipoViviendaSelected', TIPO_VIVIENDA_SELECTED, { root: true });
        commit('theModalAntecedente/setTipoTabacoSelected', TIPO_TABACO_SELECTED, { root: true });
        commit('theModalAntecedente/setTipoAlcohosSelected', TIPO_ALCOHOL_SELECTED, { root: true });
        commit('theModalAntecedente/setTipoMedicamentoSelected', TIPO_MEDICAMENTO_SELECTED, { root: true });
        commit('theModalAntecedente/setTipoActividadFisicaSelected', TIPO_ACTIVIDAD_FISICA_SELECTED, { root: true });
        commit('theModalAntecedente/setTipoOcupacionSelected', TIPO_OCUPACION_SELECTED, { root: true });
        commit('theModalAntecedente/setTipoPsicosocialSelected', TIPO_PSICOSOCIAL_SELECTED, { root: true });
        commit('theModalAntecedente/setFechaInicio', FECHA_INICIO_TABACO, { root: true });
        commit('theModalAntecedente/setFechaInicio2', FECHA_INICIO_ALCOHOL, { root: true });
        commit('theModalAntecedente/setCardiovasculares', ANTECEDENTES.cardiovasculares, { root: true });
        commit('theModalAntecedente/setDescripcionOtros', ANTECEDENTES.otros, { root: true });
        commit('theModalAntecedente/setAlergias', ANTECEDENTES.alergias, { root: true });

        const PATOLOGIAS = response.data.data.patologias;
        const SET_PATOLOGIA = [];
        PATOLOGIAS.forEach((ele) => {
          const TIPO_PATOLOGIA_SELECTED = TIPO_PATOLOGIAS.find((el) => (
            el.id === ele.tipo_patologia_id));
          const OBJ = {
            id: ele.id,
            descripcion: ele.descripcion,
            tipoPatologiaSelected: TIPO_PATOLOGIA_SELECTED,
            fecha: ele.fecha,
            hora: ele.hora,
            estado: ele.estado,
          };
          SET_PATOLOGIA.push(OBJ);
        });
        commit('theModalAntecedente/setPatologias', SET_PATOLOGIA, { root: true });

        const CIRUGIAS = response.data.data.cirugias;
        const SET_CIRUGIAS = [];
        CIRUGIAS.forEach((ele) => {
          const TIPO_CIRUGIA_SELECTED = TIPO_CIRUGIAS.find((el) => (
            el.id === ele.tipo_cirugia_id));
          const OBJ = {
            id: ele.id,
            descripcion: ele.descripcion,
            tipoCirugiaSelected: TIPO_CIRUGIA_SELECTED,
            fecha: ele.fecha,
            hora: ele.hora,
            estado: ele.estado,
          };
          SET_CIRUGIAS.push(OBJ);
        });
        commit('theModalAntecedente/setCirugias', SET_CIRUGIAS, { root: true });
      }
      const SET_SERVICIO_COMPLEMENTATIOS = [];
      const SERVICIO_COMPLEMENTARIOS = response.data.data.servicios_complementarios;
      SERVICIO_COMPLEMENTARIOS.forEach((ele) => {
        const OBJ = {
          id: ele.id,
          servicio_complementario_id: ele.servicio_complementario_id,
          estado: true,
          session: ele.session,
          categoria_serv_compl_id: ele.categoria_serv_compl_id,
          numero: ele.numero,
          numero_interno: ele.numero,
          categoria: ele.categoria,
          nombre: ele.descripcion_servicio,
          cantidad: ele.cantidad,
          key: `SC - ${ele.id}`,
        };
        SET_SERVICIO_COMPLEMENTATIOS.push(OBJ);
      });
      commit('setLisServiciosComplementarios', SET_SERVICIO_COMPLEMENTATIOS);
    } catch (error) {
      util.showNotify(error.response.data.message, 'error');
    }
  },
  async store({ state, rootState }, dto) {
    try {
      // if (await dispatch('validar')) {
      const CONSULTA_ID = rootState.ConsultaExternaEdit.consulta
        ? rootState.ConsultaExternaEdit.consulta.id : 0;
      const TIPO_DIETA_ID = rootState.theModalAntecedente.tipoDietaSelected
        ? rootState.theModalAntecedente.tipoDietaSelected.id : null;
      const TIPO_VIVIENDA_ID = rootState.theModalAntecedente.tipoViviendaSelected
        ? rootState.theModalAntecedente.tipoViviendaSelected.id : null;
      const TIPO_TABACO_ID = rootState.theModalAntecedente.tipoTabacoSelected
        ? rootState.theModalAntecedente.tipoTabacoSelected.id : null;
      const TIPO_ALCOHOL_ID = rootState.theModalAntecedente.tipoAlcohosSelected
        ? rootState.theModalAntecedente.tipoAlcohosSelected.id : null;
      const TIPO_MEDICAMENTO_ID = rootState.theModalAntecedente.tipoMedicamentoSelected
        ? rootState.theModalAntecedente.tipoMedicamentoSelected.id : null;
      const TIPO_ACTIVIDAD_FISICA_ID = rootState.theModalAntecedente.tipoActividadFisicaSelected
        ? rootState.theModalAntecedente.tipoActividadFisicaSelected.id : null;
      const TIPO_OCUPACION_ID = rootState.theModalAntecedente.tipoOcupacionSelected
        ? rootState.theModalAntecedente.tipoOcupacionSelected.id : null;
      const TIPO_PSICOSOCIAL_ID = rootState.theModalAntecedente.tipoPsicosocialSelected
        ? rootState.theModalAntecedente.tipoPsicosocialSelected.id : null;
      const PATALOGIAS = rootState.theModalAntecedente.patologias;
      PATALOGIAS.forEach((el, index) => {
        PATALOGIAS[index].tipo_patologia_id = el.tipoPatologiaSelected
          ? el.tipoPatologiaSelected.id : null;
      });
      const CIRUGIAS = rootState.theModalAntecedente.cirugias;
      CIRUGIAS.forEach((el, index) => {
        CIRUGIAS[index].tipo_cirugia_id = el.tipoCirugiaSelected
          ? el.tipoCirugiaSelected.id : null;
      });
      const FECHA_INICIO_TABACO = rootState.theModalAntecedente.fechaInicio;
      const FECHA_INICIO_ALCOHOL = rootState.theModalAntecedente.fechaInicio2;
      const data = {
        motivo_consulta: state.motivoConsulta,
        enfermedad_actual: state.enfermedadActual,
        examen_fisico: state.examenFisico,
        tratamiento: state.tratamiento,
        diagnostico1: state.descripcionDiagnostico1,
        diagnostico2: state.descripcionDiagnostico2,
        diagnostico3: state.descripcionDiagnostico3,
        diagnostico4: state.descripcionDiagnostico4,
        clasificacion_cie1_id: state.diagnostico1Selected ? state.diagnostico1Selected.id : '',
        clasificacion_cie2_id: state.diagnostico2Selected ? state.diagnostico2Selected.id : '',
        clasificacion_cie3_id: state.diagnostico3Selected ? state.diagnostico3Selected.id : '',
        clasificacion_cie4_id: state.diagnostico4Selected ? state.diagnostico4Selected.id : '',
        subclasificacion_cie1_id: state.diagnostico1Selected ? state.diagnostico1Selected.id_sub : '',
        subclasificacion_cie2_id: state.diagnostico2Selected ? state.diagnostico2Selected.id_sub : '',
        subclasificacion_cie3_id: state.diagnostico3Selected ? state.diagnostico3Selected.id_sub : '',
        subclasificacion_cie4_id: state.diagnostico4Selected ? state.diagnostico4Selected.id_sub : '',
        fecha_reconsulta: moment(state.fecha).format('YYYY-MM-DD'),
        paciente_id: dto.paciente_id,
        venta_id: dto.venta_id,
        medico_id: dto.medico_id,
        antecedente_id: state.antecedente ? state.antecedente.id : 0,
        tipo_dieta_id: TIPO_DIETA_ID,
        tipo_vivienda_id: TIPO_VIVIENDA_ID,
        tipo_tabaco_id: TIPO_TABACO_ID,
        tipo_alcohol_id: TIPO_ALCOHOL_ID,
        f_inicio_tabaco: FECHA_INICIO_TABACO
          ? moment(FECHA_INICIO_TABACO).format('YYYY-MM-DD') : null,
        f_inicio_alcohol: FECHA_INICIO_ALCOHOL
          ? moment(rootState.theModalAntecedente.fechaInicio2).format('YYYY-MM-DD') : null,
        tipo_medicamento_id: TIPO_MEDICAMENTO_ID,
        tipo_actividad_fisica_id: TIPO_ACTIVIDAD_FISICA_ID,
        tipo_ocupacion_id: TIPO_OCUPACION_ID,
        tipo_psicosocial_id: TIPO_PSICOSOCIAL_ID,
        cardiovasculares: rootState.theModalAntecedente.cardiovasculares,
        alergias: rootState.theModalAntecedente.alergias,
        otros: rootState.theModalAntecedente.descripcionOtros,
        cierre: dto.cierre,
        patologias: PATALOGIAS,
        cirugias: CIRUGIAS,
        servicios_complementarios: dto.servicios_complementarios,
      };
      const RESPONSE = await axios.put(`/clinic/consultation/patient/${CONSULTA_ID}`, data);
      util.showNotify(RESPONSE.data.message, 'success');
      // }
    } catch (error) {
      console.log('error', error);
      util.showNotify(error.response.data.message, 'error');
    }
  },
  validar(state) {
    let valido = true;
    if (!state.fecha) {
      util.showNotify('La fecha de reconsulta es requerido', 'error');
      valido = false;
    }
    return valido;
  },
  async getListProducto({ state, commit }, query) {
    try {
      state.filterProducto = query;
      state.temporaPageComboProducto += 1;
      const params = {
        page: 1,
        perPage: 100 * state.temporaPageComboProducto,
        filterKey: state.filterProducto,
      };
      const response = await axios.get('/configuration/tipoProducto/combo/clase', { params });
      const DATA = response.data;
      commit('setProductos', DATA.data);
      commit('setTotalRegistroProducto', DATA.total);
    } catch (error) {
      console.log('error', error);
    }
  },
  async getDataProducto({ state, commit, dispatch }) {
    try {
      if (!state.almacenSelected) {
        util.showNotify('Debe seleccionar un almacen', 'warn');
        return;
      }
      const params = {
        almacen_id: state.almacenSelected.id,
        producto_id: state.productoSelected.tipo_producto_id,
        fecha: moment(state.fecha).format('DD/MM/YYYY'),
        moneda_id: state.monedaPrincipal.id,
      };
      axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
      const response = await axios.get('/clinic/consulta_externa/get_data_almacen_by_product', { params });
      const DATA = response.data.data;
      commit('setStockSaldoUp', Math.round(DATA.stock.saldo_up));
      commit('setStockSaldoUa', Math.round(DATA.stock.saldo_ua));
      commit('setUnidad', true);
      commit('setProductoSelected', DATA.producto);
      if (DATA.stock_lotes.length > 0) {
        commit('setStockLotes', DATA.stock_lotes);
      }
    } catch (error) {
      console.log('error', error.response);
      if (error.response) {
        const response = error.response.data;
        switch (response.error) {
          case 6: {
            util.showNotify(response.message, 'warn');
            dispatch('resetDataModalFarmacia');
            break;
          }
          case 7: {
            util.showNotify(response.message, 'warn', response.data.title);
            dispatch('resetDataModalFarmacia');
            break;
          }
          default: {
            const message = (response.error) ? response.message : 'Error, hubo problemas con el servidor, contactese con el Administrador.';
            util.showNotify(message, 'error');
          }
        }
      } else if (error.request) {
        util.showNotify('La solicitud se realizó pero no se recibió respuesta del servidor.', 'error');
      } else {
        util.showNotify('Algo sucedio al solicitar la solicitud al servidor.', 'error');
      }
    }
  },
  resetState({ commit }) {
    commit('setAlmacenes', []);
    commit('setMonedas', []);
    commit('setMonedaPrincipal', null);
    commit('setFecha', new Date());
    commit('setAlmacenSelected', null);
    commit('setProductos', []);
    commit('setProductoSelected', null);
    commit('setFilterProducto', null);
    commit('setTemporalPageComboProducto', 0);
    commit('setTotalRegistroProducto', 0);
    commit('setStockSaldoUp', 0);
    commit('setStockSaldoUa', 0);
    commit('setUnidad', false);
    commit('setStockLotes', []);
    commit('setTipoViaAdministracions', []);
    commit('setTipoViaAdministracionSelected', null);
    commit('setClasificacionCies', []);
    commit('setDiagnostico1', null);
    commit('setDiagnostico2', null);
    commit('setDiagnostico3', null);
    commit('setDiagnostico4', null);
    commit('setNombreDiagnostico', 'S/N');
    commit('setTratamiento', null);
    commit('setExamenFisico', null);
    commit('setEnfermedadActual', null);
    commit('setMotivoConsulta', null);
    commit('setDescripcionDiagnostico1', null);
    commit('setDescripcionDiagnostico2', null);
    commit('setDescripcionDiagnostico3', null);
    commit('setdescripcionDiagnostico4', null);
    commit('setConsulta', null);
    commit('setAntecedente', null);
    commit('setConsultaCierre', null);
    commit('setPaciente', null);
    commit('setLisServiciosComplementarios', []);
  },
  resetDataModalFarmacia({ commit }) {
    commit('setStockSaldoUp', 0);
    commit('setStockSaldoUa', 0);
    commit('setStockLotes', []);
    // commit('setUnidad', false);
    commit('setProductoSelected', null);
  },
};
