import { isBloqueado } from '../utilidades';

export default {
  isBloqueadoSeccionMedicoTercero(state) {
    const KEY = 'REP_MEDICO_TERCERO';
    return isBloqueado(state, KEY);
  },
  isBloqueadoTodosLosUsuarios(state) {
    const KEY = 'VC_USUARIO';
    return isBloqueado(state, KEY);
  },
  isBloqueadoPagoMedicoTercero(state) {
    const KEY = 'CLINICA_PAGO_MEDICO_TERCERO';
    return isBloqueado(state, KEY);
  },
};
