import store from '@/store/index';
import { redirectIfHasPermission } from '@/store/modules/privilegios/utilidades';

const title = 'Reportes';
const reporteRoutes = [
  {
    path: 'reportes',
    name: 'reportes-index',
    component: () => import('../views/index.vue'),
    meta: {
      title,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionListarReportes'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'reportes/informe-del-dia/:hash',
    name: 'reportes-informe-del-dia',
    component: () => import('../views/reporteDelDia.vue'),
    meta: {
      title: `${title} | Informe del Dia`,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionListarReportes'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'reportes/resumen-venta/:hash',
    name: 'reportes-resumen-venta',
    component: () => import('../views/reporteResumenVenta.vue'),
    meta: {
      title: `${title} | Resumen de Venta`,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionListarReportes'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'reportes/resumen-venta-detallado/:hash',
    name: 'reportes-resumen-venta-detallado',
    component: () => import('../views/reporteResumenVentaDetallado.vue'),
    meta: {
      title: `${title} | Resumen de Venta Detallado`,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionListarReportes'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'reportes/venta-tipo-servicios/:hash',
    name: 'reportes-venta-tipo-servicios',
    component: () => import('../views/reporteVentaTipoServicios.vue'),
    meta: {
      title: `${title} | Venta por Tipo de Servicio`,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionListarReportes'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'reportes/medico-terceros/:hash',
    name: 'reportes-medico-terceros',
    component: () => import('../views/reporteMedicoTerceros.vue'),
    meta: {
      title: `${title} | Medico Terceros`,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionListarReportes'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
];
export default reporteRoutes;
