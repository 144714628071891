import store from '@/store/index';
import { redirectIfHasPermission } from '@/store/modules/privilegios/utilidades';

const title = 'Cotización';
const cotizacionRoutes = [
  {
    path: 'cotizacion',
    name: 'cotizacion-index',
    component: () => import('../views/index.vue'),
    meta: {
      title,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionListarCotizacion'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'cotizacion/create',
    name: 'cotizacion-create',
    component: () => import('../views/create.vue'),
    meta: {
      title: `${title} | Nuevo`,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionNuevoCotizacion'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'cotizacion/:id/edit',
    name: 'cotizacion-edit',
    component: () => import('../views/edit.vue'),
    meta: {
      title: `${title} | Editar`,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionModificarCotizacion'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
];
export default cotizacionRoutes;
