import { updateField } from 'vuex-map-fields';

export default {
  updateField,
  setAlmacenes(state, data) { state.almacenes = data; },
  setMonedas(state, data) { state.monedas = data; },
  setMonedaPrincipal(state, data) {
    if (data) {
      state.monedaPrincipal = data.find((el) => el.is_principal === 1);
    } else {
      state.monedaPrincipal = data;
    }
  },
  setFecha(state, data) { state.fecha = data; },
  setAlmacenSelected(state, data) { state.almacenSelected = data; },
  setProductos(state, data) { state.productos = data; },
  setProductoSelected(state, data) { state.productoSelected = data; },
  setFilterProducto(state, data) { state.filterProducto = data; },
  setTemporalPageComboProducto(state, data) { state.temporaPageComboProducto = data; },
  setTotalRegistroProducto(state, data) { state.totalRegistroProducto = data; },
  setStockSaldoUp(state, data) { state.stockSaldoUp = data; },
  setStockSaldoUa(state, data) { state.stockSaldoUa = data; },
  setUnidad(state, data) { state.unidad = data; },
  setStockLotes(state, data) { state.stockLotes = data; },
  setTipoViaAdministracions(state, data) { state.tipoViaAdministracions = data; },
  setTipoViaAdministracionSelected(state, data) { state.tipoViaAdministracionSelected = data; },
  setClasificacionCies(state, data) { state.clasificacionCies = data; },
  setDiagnostico1(state, data) { state.diagnostico1Selected = data; },
  setDiagnostico2(state, data) { state.diagnostico2Selected = data; },
  setDiagnostico3(state, data) { state.diagnostico3Selected = data; },
  setDiagnostico4(state, data) { state.diagnostico4Selected = data; },
  setNombreDiagnostico(state, data) { state.nombreDiagnostico = data; },
  setTratamiento(state, data) { state.tratamiento = data; },
  setExamenFisico(state, data) { state.examenFisico = data; },
  setEnfermedadActual(state, data) { state.enfermedadActual = data; },
  setMotivoConsulta(state, data) { state.motivoConsulta = data; },
  setDescripcionDiagnostico1(state, data) { state.descripcionDiagnostico1 = data; },
  setDescripcionDiagnostico2(state, data) { state.descripcionDiagnostico2 = data; },
  setDescripcionDiagnostico3(state, data) { state.descripcionDiagnostico3 = data; },
  setdescripcionDiagnostico4(state, data) { state.descripcionDiagnostico4 = data; },
  setSubclasificacion_cie1_id(state, data) { state.subclasificacion_cie1_id = data; },
  setSubclasificacion_cie2_id(state, data) { state.subclasificacion_cie2_id = data; },
  setSubclasificacion_cie3_id(state, data) { state.subclasificacion_cie3_id = data; },
  setSubclasificacion_cie4_id(state, data) { state.subclasificacion_cie4_id = data; },
  setConsulta(state, data) { state.consulta = data; },
  setAntecedente(state, data) { state.antecedente = data; },
  setConsultaCierre(state, data) { state.consultaCierre = data; },
  setPaciente(state, data) { state.paciente = data; },
  setCantidadDiagnostico(state, data) { state.cantidad_diagnostico = data; },
  setLisServiciosComplementarios(state, data) { state.lisServiciosComplementarios = data; },
};
