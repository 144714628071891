import { updateField } from 'vuex-map-fields';

export default {
  updateField,
  setServicios(state, data) { state.servicios = data; },
  setServicioSelected(state, data) { state.servicioSelected = data; },
  setLoading(state, data) { state.loading = data; },
  setListaPrecioSeguros(state, data) { state.listaPrecioSeguros = data; },
  setListaPrecios(state, data) { state.listaPrecios = data; },
  setPrecioSeguroSelected(state, data) { state.precioSeguroSelected = data; },
  setPrecioNormalSelected(state, data) { state.precioNormalSelected = data; },
  setPrecioTipoCambio(state, data) {
    state.listaPrecios = data.precios;
    state.listaPrecioSeguros = data.seguroServicio ?? [];
    state.listaPrecios.forEach((precio) => {
      let conversion = 1;
      const MONEDA_PRINCIPAL = 1;
      const monedaIdServicio = data.monedaServicioID;
      if (parseInt(monedaIdServicio, 10) !== parseInt(data.monedaSelectedId, 10)) {
        if (data.tipoMoneda === MONEDA_PRINCIPAL) {
          const { tc } = data.monedas.find((moneda) => moneda.id === monedaIdServicio);
          conversion = tc;
        } else {
          conversion = (1 / data.tipoCambio);
        }
      }
      /* eslint no-param-reassign: "error" */
      precio.monto *= parseFloat(conversion);
    });
    state.listaPrecioSeguros.forEach((precio) => {
      let conversion = 1;
      const MONEDA_PRINCIPAL = 1;
      const monedaIdServicio = data.monedaServicioID;
      if (parseInt(monedaIdServicio, 10) !== parseInt(data.monedaSelectedId, 10)) {
        if (data.tipoMoneda === MONEDA_PRINCIPAL) {
          const { tc } = data.monedas.find((moneda) => moneda.id === monedaIdServicio);
          conversion = tc;
        } else {
          conversion = (1 / data.tipoCambio);
        }
      }
      /* eslint no-param-reassign: "error" */
      precio.monto *= parseFloat(conversion);
      precio.seguro *= parseFloat(conversion);
    });
  },
  setPrecioMontoNormal(state, data) { state.precioMontoNormal = data; },
  setInputPrecioSeguro(state, data) { state.inputPrecioSeguro = data; },
};
