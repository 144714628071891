import { updateField } from 'vuex-map-fields';

export default {
  updateField,
  setEstado(state, data) { state.estado = data; },
  setPorPagina(state, data) { state.porPagina = data; },
  setBusqueda(state, data) { state.busqueda = data; },
  setListaTablas(state, data) { state.listaTablas = data; },
  setPaginaActual(state, data) { state.paginaActual = data; },
  setTotalFilas(state, data) { state.totalFilas = data; },
  setPaciente(state, data) { state.paciente = data; },
  setIndicacionMedicas(state, data) { state.indicacionsMedicas = data; },
  setNumeroTratamiento(state, data) { state.numeroTratamiento = data; },
};
