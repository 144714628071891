import store from '@/store/index';
import { redirectIfHasPermission } from '@/store/modules/privilegios/utilidades';

const title = 'Facturacion';
const facturacionRoutes = [
  {
    path: 'facturacion',
    name: 'facturacion-index',
    component: () => import('../views/index.vue'),
    meta: {
      title,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionListarVentas'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'facturacion/create',
    name: 'facturacion-create',
    component: () => import('../views/create.vue'),
    meta: {
      title: `${title} | Nuevo`,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionNuevoVentas'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'facturacion/:id/edit',
    name: 'facturacion-edit',
    component: () => import('../views/edit.vue'),
    meta: {
      title: `${title} | Editar`,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionModificarVentas'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'facturacion/:id/print',
    name: 'facturacion-print',
    component: () => import('../views/print.vue'),
    meta: {
      title: `${title} | Imprimir`,
    },
  },
  {
    path: 'reconsulta',
    name: 'reconsulta-index',
    component: () => import('../views/IndexReconsulta.vue'),
    meta: {
      title: 'Re Consulta',
    },
  },
];
export default facturacionRoutes;
