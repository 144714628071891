import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function updateCantidadClasificacionCIE({ commit, rootState }) {
  try {
    const cantidad = rootState.formularioClasificacion.cantidadSelected;
    const ID = rootState.formularioClasificacion.idNombreDiagnostico;

    const REQUEST = {
      id: ID,
      cantidad_diagnostico: cantidad,
    };
    const RESPONSE = await axios.post('/clinic/parametros/nombre_diagnostico', REQUEST);
    const NOMBRE_PARAMETO = RESPONSE.data.data.nombre_parametro;
    if (NOMBRE_PARAMETO) {
      commit('formularioClasificacion/setCantidadSelected', NOMBRE_PARAMETO.cantidad_diagnostico, { root: true });
      commit('formularioClasificacion/setIdNombreDiagnositco', NOMBRE_PARAMETO.id, { root: true });
    }
    util.showNotify(RESPONSE.data.message, 'success');
  } catch (error) {
    util.showNotify(error.response.data.message, 'error');
  }
}
