<template>
  <div>
    <notifications group="foo" />
    <router-view v-if="appOnline"></router-view>
    <div v-else class="bg-color">
      <div class="row">
        <div class="col-sm-12 error-template">
          <h1 class="display-4">Oops!</h1>
          <h2 class="mt-4 text-white">Sin Conexión a Internet</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      appOnline: navigator.onLine,
    };
  },
  computed: {
    ...mapGetters(['getThemeMode']),
    themeName() {
      return this.getThemeMode.dark ? 'dark-theme' : ' ';
    },
    rtl() {
      return this.getThemeMode.rtl ? 'rtl' : ' ';
    },
  },
  metaInfo() {
    return {
      bodyAttrs: {
        class: [this.themeName, 'text-left'],
      },
      htmlAttrs: {
        dir: this.rtl,
      },
    };
  },
  methods: {
    onchange() {
      this.appOnline = navigator.onLine;
      this.$emit(this.appOnline ? 'online' : 'offline');
    },
  },
  mounted() {
    window.addEventListener('online', this.onchange);
    window.addEventListener('offline', this.onchange);
    this.onchange();
  },
  beforeDestroy() {
    window.removeEventListener('online', this.onchange);
    window.removeEventListener('offline', this.onchange);
  },
};
</script>

<style>
.bg-color{
  background-color:#fbdf33;
  height: 100vh;
}
.error-template{
  transform: translateY(33vh);
  text-align: center;
}
</style>
