import { hasPermision } from './utilidades';
import bloqueoReportes from './bloqueos/reportes';

export default {
  ...bloqueoReportes,
  hasPermisionListarReportes(state) {
    const KEY = 'RCLIN_LISTAR';
    const ACCION = 'listar';
    return hasPermision(state, KEY, ACCION);
  },
};
