import mainForm from './mainForm';
import datosFactura from './datosFactura';
import seguroMedico from './seguroMedico';
import contacto from './contacto';
import familiar from './familiar';
import medicoCabecera from './medicoCabecera';
import coberturaSeguro from './coberturaSeguro';
import aseguradoras from './common/aseguradoras';
import parentescos from './common/parentescos';
import situaciones from './common/situaciones';
import tipoFamiliares from './common/tipoFamiliares';

const pacienteStore = {
  mainForm,
  datosFactura,
  seguroMedico,
  contacto,
  familiar,
  medicoCabecera,
  coberturaSeguro,
  aseguradoras,
  parentescos,
  situaciones,
  tipoFamiliares,
};

export default pacienteStore;
