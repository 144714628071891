import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function callIndexApi({ commit, rootState }) {
  try {
    const FILTER_KEY = rootState.formularioClasificacion.busqueda;
    const STATE = rootState.formularioClasificacion.estado;
    const PAGE = rootState.formularioClasificacion.paginaActual;
    const PER_PAGE = rootState.formularioClasificacion.porPagina;
    const PARAMS = {
      estado: STATE,
      filter_key: FILTER_KEY,
      page: PAGE,
      per_page: PER_PAGE,
    };
    const RESPONSE = await axios.get('/clinic/parametros/clasificacion_cie', {
      params: PARAMS,
    });
    const CLASIFICACION_CIE = RESPONSE.data.data.clasificacions.data;
    const TOTAL_FILA = RESPONSE.data.data.clasificacions.total;
    commit('formularioClasificacion/setListarTabla', CLASIFICACION_CIE, { root: true });
    commit('formularioClasificacion/setTotalFila', TOTAL_FILA, { root: true });
    const NOMBRE_DIAGNOSTICO = RESPONSE.data.data.nombre_parametro_consulta_externa;
    if (NOMBRE_DIAGNOSTICO) {
      commit('formularioClasificacion/setNombreDiagnositco', NOMBRE_DIAGNOSTICO.nombre_diagnostico, { root: true });
      commit('formularioClasificacion/setIdNombreDiagnositco', NOMBRE_DIAGNOSTICO.id, { root: true });
      commit('formularioClasificacion/setNombreTratamientoPrincipal', NOMBRE_DIAGNOSTICO.nombre_tratamiento_principal, { root: true });
      commit('formularioClasificacion/setCantidadSelected', NOMBRE_DIAGNOSTICO.cantidad_diagnostico, { root: true });
    }
  } catch (error) {
    util.showNotify(error.response.data.message, 'error');
  }
}
