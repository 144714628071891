import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    id: null,
    pacientes: [],
    pacienteSelected: null,
    descripcion: '',
    totalTratamiento: 0,
    tesorerias: [],
    tesoreriaSelected1: null,
    monto1: 0,
    tesoreriaSelected2: null,
    monto2: 0,
    tesoreriaSelected3: null,
    monto3: 0,
    tesoreriasAUtilizar: 1,
    tesoreriaID1: 0,
    tesoreriaID2: 0,
    tesoreriaID3: 0,
    responseEdit: null,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setId: (state, data) => { state.id = data; },
    setPacientes: (state, data) => { state.pacientes = data; },
    setPacienteSelected: (state, data) => { state.pacienteSelected = data; },
    setDescripcion: (state, data) => { state.descripcion = data; },
    setTotalTratamiento: (state, data) => { state.totalTratamiento = data; },
    setTesorerias: (state, data) => { state.tesorerias = data; },
    setTesoreriaSelected1: (state, data) => { state.tesoreriaSelected1 = data; },
    setMonto1: (state, data) => { state.monto1 = data; },
    setTesoreriaSelected2: (state, data) => { state.tesoreriaSelected2 = data; },
    setMonto2: (state, data) => { state.monto2 = data; },
    setTesoreriaSelected3: (state, data) => { state.tesoreriaSelected3 = data; },
    setMonto3: (state, data) => { state.monto3 = data; },
    setTesoreriasAUtilizar: (state, data) => { state.tesoreriasAUtilizar = data; },
    setTesoreriaID1: (state, data) => { state.tesoreriaID1 = data; },
    setTesoreriaID2: (state, data) => { state.tesoreriaID2 = data; },
    setTesoreriaID3: (state, data) => { state.tesoreriaID3 = data; },
    setResponseEdit: (state, data) => { state.responseEdit = data; },

    cargarPacienteSelected(state, id) {
      if (id === null) {
        return;
      }
      state.pacienteSelected = state.pacientes.find((el) => el.id === id);
    },
    cargarAnticiposFromEdit(state, lista) {
      state.tesoreriasAUtilizar = lista.length;
      lista.forEach((item, index) => {
        if (index === 0) {
          state.tesoreriaID1 = item.id;
          state.tesoreriaSelected1 = state.tesorerias.find((el) => el.id === item.tesoreria_id);
          state.monto1 = item.monto;
        }
        if (index === 1) {
          state.tesoreriaID2 = item.id;
          state.tesoreriaSelected2 = state.tesorerias.find((el) => el.id === item.tesoreria_id);
          state.monto2 = item.monto;
        }
        if (index === 2) {
          state.tesoreriaID3 = item.id;
          state.tesoreriaSelected3 = state.tesorerias.find((el) => el.id === item.tesoreria_id);
          state.monto3 = item.monto;
        }
      });
    },
  },
  actions: {
    resetModalRegistro({ commit }) {
      commit('setId', null);
      commit('setPacientes', []);
      commit('setPacienteSelected', null);
      commit('setDescripcion', '');
      commit('setTotalTratamiento', 0);
      commit('setTesorerias', []);
      commit('setTesoreriaSelected1', null);
      commit('setMonto1', 0);
      commit('setTesoreriaSelected2', null);
      commit('setMonto2', 0);
      commit('setTesoreriaSelected3', null);
      commit('setMonto3', 0);
      commit('setTesoreriasAUtilizar', 1);
      commit('setTesoreriaID1', 0);
      commit('setTesoreriaID2', 0);
      commit('setTesoreriaID3', 0);
      commit('setResponseEdit', null);
    },
  },
};
