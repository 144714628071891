export default () => ({
  estado: true,
  busqueda: null,
  pagina_actual: 1,
  por_pagina: 50, // 50
  totalFilas: 1,
  pagina_opciones: [50, 100],
  sortBy: 'numero',
  sortDesc: true,
  fields: [
    {
      key: 'editar', label: ' ', thClass: 'th-custom-color', class: 'text-center px-0', thStyle: { width: '3%' },
    },
    {
      key: 'ver', label: ' ', thClass: 'th-custom-color px-0', class: 'text-center px-0', thStyle: { width: '2%' },
    },
    {
      key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center px-0', thStyle: { width: '3%' },
    },
    {
      key: 'siat_sync', label: ' ', thClass: 'th-custom-color px-0', class: 'text-center px-0', thStyle: { width: '3%' },
    },
    {
      key: 'numero', label: 'N°', thClass: 'th-custom-color', thStyle: { width: '5%' }, sortable: true,
    },
    {
      key: 'numero_colegio_medico', label: 'N° Col. Med.', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
    },
    {
      key: 'nombre', label: 'Nombre', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '17%' }, sortable: true,
    },
    {
      key: 'edad', label: 'Edad', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' }, sortable: true,
    },
    {
      key: 'especialidad', label: 'Especialidad', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '18%' }, sortable: true,
    },
    {
      key: 'celular', label: 'Celular', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' }, sortable: true,
    },
    {
      key: 'carnet_identidad', label: 'C.I.', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
    },
    {
      key: 'usuario', label: 'Usuario', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
    },
  ],
  listaTabla: [],
  delayTimeout: null,
});
