import { hasPermision } from './utilidades';

export default {
  hasPermisionListarPaciente(state) {
    const KEY = 'PAC_LISTAR';
    const ACCION = 'listar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionNuevoPaciente(state) {
    const KEY = 'PAC_NUEVO';
    const ACCION = 'nuevo';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionModificarPaciente(state) {
    const KEY = 'PAC_EDITAR';
    const ACCION = 'modificar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionVerPaciente(state) {
    const KEY = 'PAC_VER';
    const ACCION = 'ver';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionEliminarPaciente(state) {
    const KEY = 'PAC_ELIMINAR';
    const ACCION = 'eliminar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionImprimirPaciente(state) {
    const KEY = 'PAC_IMPRIMIR';
    const ACCION = 'imprimir';
    return hasPermision(state, KEY, ACCION);
  },
};
