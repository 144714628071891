import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    isLoading: true,
    estado: true,
    busqueda: '',
    pagina_actual: 1,
    por_pagina: 100,
    listaTabla: [],
    totalFilas: 0,
    listaComprobantes: [],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setIsLoading: (state, data) => { state.isLoading = data; },
    setEstado: (state, data) => { state.estado = data; },
    setBusqueda: (state, data) => { state.busqueda = data; },
    setPaginaActual: (state, data) => { state.pagina_actual = data; },
    setPorPagina: (state, data) => { state.por_pagina = data; },
    setListaTabla: (state, data) => { state.listaTabla = data; },
    setTotalFilas: (state, data) => { state.totalFilas = data; },
    setListaComprobantes: (state, data) => { state.listaComprobantes = data; },
  },
  actions: {
    resetListarAnticipos({ commit }) {
      commit('setIsLoading', true);
      commit('setEstado', true);
      commit('setBusqueda', '');
      commit('setPaginaActual', 1);
      commit('setPorPagina', 100);
      commit('setListaTabla', []);
      commit('setTotalFilas', 0);
      commit('setListaComprobantes', []);
    },
  },
};
