import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function callCreateApi({ commit }) {
  try {
    const RESPONSE = await axios.get('/clinic/quotation/create');
    const { aseguradora } = RESPONSE.data.data;
    const CATEGORIA_SERVICIOS = RESPONSE.data.data.categoria_servicios;
    const { medicos } = RESPONSE.data.data;
    const { monedas } = RESPONSE.data.data;
    const LISTA_FORMA_PAGOS = RESPONSE.data.data.tipo_forma_pagos;
    const { validez } = RESPONSE.data.data;
    commit('formularioCotizacion/setListaAseguradoras', aseguradora, { root: true });
    commit('modalServicios/setCategoriaServicios', CATEGORIA_SERVICIOS, { root: true });
    commit('modalServicios/setCategoriaServiciosSelected', CATEGORIA_SERVICIOS[0], { root: true });
    commit('formularioCotizacion/setListaMedicos', medicos, { root: true });
    commit('formularioCotizacion/cargarComboMoneda', monedas, { root: true });
    commit('formularioCotizacion/setListaFormaPago', LISTA_FORMA_PAGOS, { root: true });
    commit('formularioCotizacion/setListaValidez', validez, { root: true });
    commit('formularioCotizacion/predeterminarFormaPagoContado', null, { root: true });
    commit('formularioCotizacion/predeterminarValidez', null, { root: true });
  } catch (error) {
    util.showNotify(error.response.data.message, 'error');
  }
}
