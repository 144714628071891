import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import router from '../router';

const googleAnalyticsID = process.env.VUE_APP_GOOGLE_ANALYTICS_ID;

Vue.use(VueAnalytics, {
  id: googleAnalyticsID,
  router,
});
