// es la misma convencion que maneja el backend -- revisado el 26/04/2023 --
export const MODALIDAD_FACTURACION = Object.freeze({
  SIN_FACTURA: '', // este valor no se encuentra en la convencion del backend.
  COMPUTARIZADA: 1,
  MANUAL: 2,
  COMPUTARIZADA_EN_LINEA: 3,
  ELECTRONICA_EN_LINEA: 4,
  PREVENTA: 5,
});

// ESTADOS DE LA FACTURA ELECTRONICA
export const TIPO_EMISION = Object.freeze({
  EN_LINEA: 1,
  FUERA_LINEA: 2,
  CONTINGENCIA: 4,
});

export const TIPOS_VENTA = {
  VENTA_SERVICIOS: 'VS',
  VENTA_MEDICO_TERCERO: 'VM',
};

export const FORMAS_PAGO = {
  CONTADO: 1,
  CREDITO: 2,
  TARJETA: 3,
};

export const FUNCIONALIDADES = {
  PACIENTE: 163,
  MEDICO: 162,
  SEGURO: 164,
  ANTICIPO: 179,
  COTIZACION: 180,
  INTERNACION: 181,
  VENTAS: 161,
  CONSULTA_EXTERNA: 202,
  ENFERMERIA: 203,
};
export const CATEGORIA_SERVICIO_COMPL = {
  ECOGRAFIA: 1,
  RAYOS_X: 2,
  TOMOGRAFIA: 3,
  MAMOGRAFIA: 4,
  ARCO_C: 5,
  FISIOTERAPIA: 6,
  TRATAMIENTO: 7,
  FARMACIA: 8,
  LABORATORIO: 9,
};

export const MODALIDAD_FACTURA_MEDICO = {
  COMPUTARIZADA: 1,
  ELECTRONICA: 2,
};

export const NOMBRE_MODALIDAD_FACTURACION = {
  COMPUTARIZADA: 'Computarizada',
  MANUAL: 'Manual',
  SIN_FACTURA: 'Sin Factura',
  COMPUTARIZADA_EN_LINEA: 'Computarizada en linea',
  ELECTRONICA_EN_LINEA: 'Electronica en linea',
  PREVENTA: 'Pre Venta',
};
export const TIPO_CONSUMO_PRODUCTO_SERVICIO = {
  VENTA: 1,
  PREESCRIPCION: 2,
};
export const TIPO_FUNCION_COMISION = {
  PRE_FACTURA: 1,
  PRE_RETENCION: 2,
  S_FACTURA: 3,
  INTERNO: 4,
  PRE_RENTECION_EM: 5,
};

export const TIPO_DOCUMENTOS = {
  NIT: 1,
  CI: 2,
  CEX: 3,
  PASS: 4,
  OTROS: 5,
};
