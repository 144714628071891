import moment from 'moment';
import { getField, updateField } from 'vuex-map-fields';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  namespaced: true,
  state: {
    medioPagoCredito: null, // este dato viene del sistema facturador
    radioFormaPagoselected: null,
    nroCajas: 1,
    inputDiasCredito: '',
    fechaCreditoVencimiento: '',
    modalComboMedioPagoSelected: null,
    comboModalMedioPago: [],
    comprobante: null,
    nroTransaccion: null,
    checkCXP: false,
    ajustesPersonas: [],
    ajustesEnUso: [],
    modalComboSelected: null,
    modalComboSelected2: null,
    modalComboSelected3: null,
    comboModalPago: [
      { value: null, text: 'Seleccionar' },
    ],
    tipoFormaPagos: null,
    monto1: 0,
    monto2: 0,
    monto3: 0,
    classButton: 'btn-danger',
    numeroTarjeta: null,
    medioPagoAjusteAnticipo: null,
    isAjuste: false,
  },
  getters: {
    getField,
    getInputDiasCredito: (state) => state.inputDiasCredito,
    getComboModalMedioPago: (state) => state.comboModalMedioPago,
    getComboModalPago: (state) => state.comboModalPago,
    getTipoFormaPagos: (state) => state.tipoFormaPagos,
    isEnabledFormaPago(state) {
      return state.classButton === 'btn-success';
    },
    getFormaPagoTesorerias(state, getters, rootState) {
      let limite = 0.00;
      state.ajustesEnUso.forEach((item) => {
        limite = parseFloat(limite) + parseFloat(item.monto);
      });
      if (state.checkCXP) {
        if (limite === rootState.detalleFactura.total) {
          return [];
        }
      }
      const LISTA_TESORERIAS = [];
      const CONTADO = 1;
      if (state.radioFormaPagoselected === CONTADO) {
        if (state.modalComboSelected !== null) {
          const ITEM_1 = {
            tesoreria_id: state.modalComboSelected.id,
            cuenta_contable_id: state.modalComboSelected.cuenta_contable_id,
            monto: state.monto1,
            tipo_id: state.modalComboSelected.tipo_id,
          };
          LISTA_TESORERIAS.push(ITEM_1);
        }
        if (state.modalComboSelected2 !== null) {
          const ITEM_2 = {
            tesoreria_id: state.modalComboSelected2.id,
            cuenta_contable_id: state.modalComboSelected2.cuenta_contable_id,
            monto: state.monto2,
            tipo_id: state.modalComboSelected2.tipo_id,
          };
          LISTA_TESORERIAS.push(ITEM_2);
        }
        if (state.modalComboSelected3 !== null) {
          const ITEM_3 = {
            tesoreria_id: state.modalComboSelected3.id,
            cuenta_contable_id: state.modalComboSelected3.cuenta_contable_id,
            monto: state.monto3,
            tipo_id: state.modalComboSelected3.tipo_id,
          };
          LISTA_TESORERIAS.push(ITEM_3);
        }
      }
      return LISTA_TESORERIAS;
    },
    getNameFormaPagoSelected(state) {
      return state.tipoFormaPagos.find((el) => el.id === state.radioFormaPagoselected).name;
    },
    getMedioPago(state, getters, rootState) {
      let limite = 0.00;
      state.ajustesEnUso.forEach((item) => {
        limite = parseFloat(limite) + parseFloat(item.monto);
      });
      if (state.checkCXP) {
        if (limite === rootState.detalleFactura.total) {
          return null;
        }
      }
      const CREDITO = state.tipoFormaPagos[1].id;
      if (state.modalComboMedioPagoSelected === null || state.radioFormaPagoselected === CREDITO) {
        return null;
      }
      const CHEQUE = 5;
      let { comprobante } = state;
      if (state.modalComboMedioPagoSelected.id === CHEQUE) {
        comprobante = moment(state.comprobante).format('YYYY-MM-DD');
      }
      const MEDIO_PAGO = {
        tipo_medio_pago_id: state.modalComboMedioPagoSelected.id,
        siat_tipo_medio_pago_id: state.modalComboMedioPagoSelected.siat_tipo_medio_pago_id,
        comprobante,
        nro_transaccion: state.nroTransaccion,
        // numero_tarjeta
        numero_tarjeta: state.numeroTarjeta,
      };
      return MEDIO_PAGO;
    },
    getFormaPago(state, getters, rootState, rootGetters) {
      const CONTADO = 1;
      let dias = state.inputDiasCredito;
      if (state.radioFormaPagoselected === CONTADO) {
        dias = null;
      }
      const FORMA_PAGO = {
        tipo_forma_pago_id: state.radioFormaPagoselected,
        dias,
        monto: rootGetters['detalleFactura/getTotal'],
      };
      return FORMA_PAGO;
    },
  },
  mutations: {
    updateField,
    setMedioPagoCredito: (state, data) => { state.medioPagoCredito = data; },
    setRadioFormaPagoselected: (state, data) => { state.radioFormaPagoselected = data; },
    setNroCajas: (state, data) => { state.nroCajas = data; },
    setInputDiasCredito: (state, data) => { state.inputDiasCredito = data; },
    setFechaCreditoVencimiento: (state, data) => { state.fechaCreditoVencimiento = data; },
    setModalComboMedioPagoSelected: (state, data) => { state.modalComboMedioPagoSelected = data; },
    setComboModalMedioPago: (state, data) => { state.comboModalMedioPago = data; },
    setComprobante: (state, data) => { state.comprobante = data; },
    setNroTransaccion: (state, data) => { state.nroTransaccion = data; },
    setCheckCXP: (state, data) => { state.checkCXP = data; },
    setAjustesPersonas: (state, data) => { state.ajustesPersonas = data; },
    setAjustesEnUso: (state, data) => { state.ajustesEnUso = data; },
    setModalComboSelected: (state, data) => { state.modalComboSelected = data; },
    setModalComboSelected2: (state, data) => { state.modalComboSelected2 = data; },
    setModalComboSelected3: (state, data) => { state.modalComboSelected3 = data; },
    setComboModalPago: (state, data) => { state.comboModalPago = data; },
    setTipoFormaPagos: (state, data) => { state.tipoFormaPagos = data; },
    setMonto1: (state, data) => { state.monto1 = data; },
    setMonto2: (state, data) => { state.monto2 = data; },
    setMonto3: (state, data) => { state.monto3 = data; },
    setClassButton: (state, data) => { state.classButton = data; },
    setNroTarjeta: (state, data) => { state.numeroTarjeta = data; },

    agregarMedioPagoContadoPorDefecto(state) {
      const ID_CAJA = 1;
      const ID_EFECTIVO = 1;
      state.radioFormaPagoselected = state.tipoFormaPagos[0].id;
      const CAJA = state.comboModalPago.find((el) => el.tipo_id === ID_CAJA);
      state.modalComboSelected = CAJA;
      const MEDIO_PAGO_EFECTIVO = state.comboModalMedioPago.find((el) => el.id === ID_EFECTIVO);
      state.modalComboMedioPagoSelected = MEDIO_PAGO_EFECTIVO;
      state.classButton = 'btn-success';
    },
    setFormaPago(state, data) {
      const DIAS = data.dias ?? '';
      state.inputDiasCredito = DIAS;
      state.radioFormaPagoselected = data.tipo_forma_pago_id;
    },
    setFormaPagoFacturaTercero(state, data) {
      state.radioFormaPagoselected = data.tipo_forma_pago_id;
    },
    setMedioPago(state, data) {
      const CONTADO = 1;
      const CHEQUE = 5;
      if (state.radioFormaPagoselected === CONTADO) {
        const MEDIO_PAGO = state.comboModalMedioPago.find((el) => (
          el.id === data.tipo_medio_pago_id
        ));
        state.modalComboMedioPagoSelected = MEDIO_PAGO;
        if (MEDIO_PAGO.id === CHEQUE) {
          state.comprobante = new Date(moment(data.comprobante));
        } else {
          state.comprobante = data.comprobante;
        }
        state.nroTransaccion = data.nro_transaccion;
        state.numeroTarjeta = data.numero_tarjeta;
      }
    },
    setTesoreriasSelected(state, data) {
      data.forEach((tesoreria, index) => {
        if (index === 0) {
          state.modalComboSelected = tesoreria;
          state.monto1 = tesoreria.monto;
        }
        if (index === 1) {
          state.modalComboSelected2 = tesoreria;
          state.monto2 = tesoreria.monto;
        }
        if (index === 2) {
          state.modalComboSelected3 = tesoreria;
          state.monto3 = tesoreria.monto;
        }
      });
    },
    setMedioPagoAjusteAnticipo(state, data) { state.medioPagoAjusteAnticipo = data; },
    totalMontoAjusteIguales: (state, data) => { state.isAjuste = data; },
  },
  actions: {
    async getFormaPagoApi({ state, commit }) {
      try {
        const RESPONSE = await axios.get('/clinic/invoicing/form_payments');
        const { tesorerias } = RESPONSE.data.data;
        commit('setComboModalPago', tesorerias);
        if (tesorerias.length !== 0 && state.setModalComboSelected === null) {
          commit('setModalComboSelected', tesorerias[0]);
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async getAjustesPersonaApi({ rootState, commit }) {
      try {
        const PACIENTE = rootState.formFactura.pacienteSelected;
        if (PACIENTE !== null) {
          const RESPONSE = await axios.get(`/persona/personas_x_pagar/${PACIENTE.id}`);
          commit('setAjustesPersonas', RESPONSE.data);
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    resetDatosFormaPagoModal({ commit }) {
      commit('setRadioFormaPagoselected', null);
      commit('setInputDiasCredito', '');
      commit('setModalComboMedioPagoSelected', null);
      commit('setComprobante', null);
      commit('setNroTransaccion', null);
      commit('setNroTarjeta', null);
      commit('setCheckCXP', false);
      commit('setModalComboSelected', null);
      commit('setModalComboSelected2', null);
      commit('setModalComboSelected3', null);
      commit('setMonto1', 0);
      commit('setMonto2', 0);
      commit('setMonto3', 0);
      commit('setClassButton', 'btn-danger');
    },
    resetFormaPagoModal({ commit }) {
      commit('setMedioPagoCredito', null);
      commit('setRadioFormaPagoselected', null);
      commit('setNroCajas', 1);
      commit('setInputDiasCredito', '');
      commit('setFechaCreditoVencimiento', '');
      commit('setModalComboMedioPagoSelected', null);
      commit('setComboModalMedioPago', []);
      commit('setComprobante', null);
      commit('setNroTransaccion', null);
      commit('setNroTarjeta', null);
      commit('setCheckCXP', false);
      commit('setAjustesPersonas', []);
      commit('setAjustesEnUso', []);
      commit('setModalComboSelected', null);
      commit('setModalComboSelected2', null);
      commit('setModalComboSelected3', null);
      commit('setComboModalPago', [
        { value: null, text: 'Seleccionar' },
      ]);
      commit('setTipoFormaPagos', null);
      commit('setMonto1', 0);
      commit('setMonto2', 0);
      commit('setMonto3', 0);
      commit('setClassButton', 'btn-danger');
      commit('setMedioPagoAjusteAnticipo', null);
    },
    resetAjustesModal({ commit }) {
      commit('setCheckCXP', false);
      commit('setAjustesPersonas', []);
      commit('setAjustesEnUso', []);
      commit('totalMontoAjusteIguales', false);
    },
  },
};
