export default {
  namespaced: true,
  state: {
    tipoCoberturas: [],
    monedas: [],
    listaCoberturaSeguro: [],
  },
  getters: {
    getTipoCoberturas: (state) => state.tipoCoberturas,
    getMonedas: (state) => state.monedas,
    getlistaCoberturaSeguro: (state) => state.listaCoberturaSeguro,
  },
  mutations: {
    setTipoCoberturas: (state, data) => { state.tipoCoberturas = data; },
    setMonedas: (state, data) => { state.monedas = data; },
    setlistaCoberturaSeguro: (state, data) => { state.listaCoberturaSeguro = data; },
    addItemlistaCoberturaSeguro: (state, item) => state.listaCoberturaSeguro.push(item),
  },
};
