export default {
  namespaced: true,
  state: {
    listaTipoFamiliares: [],
  },
  getters: {
    getTiposFamiliares: (state) => state.listaTipoFamiliares,
  },
  mutations: {
    setTiposFamiliares: (state, data) => { state.listaTipoFamiliares = data; },
  },
};
