export default () => ({
  servicios: [],
  servicioSelected: null,
  loading: false,
  listaPrecioSeguros: [],
  listaPrecios: [],
  precioSeguroSelected: null,
  precioNormalSelected: null,
  precioMontoNormal: 0,
  inputPrecioSeguro: 0,
});
