import { getField } from 'vuex-map-fields';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state,
  getters: {
    getField,
  },
  actions,
  mutations,
};
