export default {
  namespaced: true,
  state: {
    situaciones: [],
  },
  getters: {
    getSituaciones: (state) => state.situaciones,
  },
  mutations: {
    setSituaciones: (state, data) => { state.situaciones = data; },
  },
};
