import { getField, updateField } from 'vuex-map-fields';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  namespaced: true,
  state: {
    actividadEconomicaId: 0,
    documentoSectorId: null,
    siatTipoFacturaId: null,
    categoriaServiciosSelected: null,
    categoriaServicios: [],
    serviciosSelected: [],
    servicios: [],
    listaTemporal: [],
    listaDetalleTemporal: [],
    precioSelected: null,
    precios: [],
    precioModal: '',
    cantidad: 1,
    impuestos: null,
    isDisabledAddButton: true,
    precioSeguroSelected: null,
    listaPrecioSeguros: [],
    inputPrecioSeguro: 0,
    idRow: 0,
  },
  getters: {
    getField,
    getCategoriaServicios: (state) => state.categoriaServicios,
    getServiciosSelected: (state) => state.serviciosSelected,
    getServicios: (state) => state.servicios,
    getListaTemporal: (state) => state.listaTemporal,
    getListaDetalleTemporal: (state) => state.listaDetalleTemporal,
    getPrecios: (state) => state.precios,
    getSubTotalModal(state) {
      let subTotalModal = 0.00;
      state.listaDetalleTemporal.forEach((item) => {
        subTotalModal = parseFloat(subTotalModal) + parseFloat(item.precio_unitario);
      });
      return subTotalModal;
    },
    getActividadEconomicaId: (state) => state.actividadEconomicaId,
  },
  mutations: {
    updateField,
    setActividadEconomicaId: (state, data) => { state.actividadEconomicaId = data; },
    setDocumentoSectorId: (state, data) => { state.documentoSectorId = data; },
    setSiatTipoFacturaId: (state, data) => { state.siatTipoFacturaId = data; },
    setCategoriaServiciosSelected: (state, data) => { state.categoriaServiciosSelected = data; },
    setCategoriaServicios: (state, data) => { state.categoriaServicios = data; },
    setServiciosSelected: (state, data) => { state.serviciosSelected = data; },
    setServicios: (state, data) => { state.servicios = data; },
    setListaTemporal: (state, data) => { state.listaTemporal = data; },
    setListaDetalleTemporal: (state, data) => { state.listaDetalleTemporal = data; },
    setItemListaTemporal: (state, data) => { state.listaTemporal.push(data); },
    setItemListaDetalleTemporal: (state, data) => { state.listaDetalleTemporal.push(data); },
    deleteLastListaDetalleTemporal: (state) => { state.listaDetalleTemporal.pop(); },
    setPrecioSelected: (state, data) => { state.precioSelected = data; },
    setPrecios: (state, data) => { state.precios = data; },
    setPrecioTipoCambio(state, data) {
      state.precios = data.precios;
      state.listaPrecioSeguros = data.seguroServicio ?? [];
      state.precios.forEach((precio) => {
        let conversion = 1;
        const MONEDA_PRINCIPAL = 1;
        const monedaIdServicio = data.monedaServicioID;
        if (parseInt(monedaIdServicio, 10) !== parseInt(data.monedaSelectedId, 10)) {
          if (data.tipoMoneda === MONEDA_PRINCIPAL) {
            const { tc } = data.monedas.find((moneda) => moneda.id === monedaIdServicio);
            conversion = tc;
          } else {
            conversion = (1 / data.tipoCambio);
          }
        }
        /* eslint no-param-reassign: "error" */
        precio.monto *= parseFloat(conversion);
      });
      state.listaPrecioSeguros.forEach((precio) => {
        let conversion = 1;
        const MONEDA_PRINCIPAL = 1;
        const monedaIdServicio = data.monedaServicioID;
        if (parseInt(monedaIdServicio, 10) !== parseInt(data.monedaSelectedId, 10)) {
          if (data.tipoMoneda === MONEDA_PRINCIPAL) {
            const { tc } = data.monedas.find((moneda) => moneda.id === monedaIdServicio);
            conversion = tc;
          } else {
            conversion = (1 / data.tipoCambio);
          }
        }
        /* eslint no-param-reassign: "error" */
        precio.monto *= parseFloat(conversion);
        precio.seguro *= parseFloat(conversion);
      });
    },
    setPrecioModal: (state, data) => { state.precioModal = data; },
    setCantidad: (state, data) => { state.cantidad = data; },
    setImpuestos: (state, data) => { state.impuestos = data; },
    setIsDisabledAddButton: (state, data) => { state.isDisabledAddButton = data; },
    setPrecioSeguroSelected: (state, data) => { state.precioSeguroSelected = data; },
    setListaPrecioSeguros: (state, data) => { state.listaPrecioSeguros = data; },
    setInputPrecioSeguro: (state, data) => { state.inputPrecioSeguro = data; },
    setIdRow: (state, data) => { state.idRow = data; },
    incrementarIdRow: (state) => { state.idRow += 1; },

    deleteItemListaTemporal(state, data) {
      state.listaTemporal = state.listaTemporal.filter((el) => (
        el.id !== data
      ));
    },
    deleteItemListaDetalleTemporal(state, data) {
      state.listaDetalleTemporal = state.listaDetalleTemporal.filter((el) => (
        el.servicio_id !== data
      ));
    },
  },
  actions: {
    async getServiciosApi({
      commit,
      state,
      rootState,
    }) {
      let categoriaId = 0;
      if (state.categoriaServiciosSelected !== null) {
        categoriaId = state.categoriaServiciosSelected.id;
      }
      let seguro = 0;
      if (rootState.formFactura.aseguradoraSelected) {
        seguro = rootState.formFactura.aseguradoraSelected.id;
      }
      const REQUEST = {
        categoria_id: categoriaId,
        actividad_economica_id: state.actividadEconomicaId,
        factura_tercero: rootState.formFactura.checkFacturaTercero,
        aseguradora_id: seguro,
      };
      try {
        const RESPONSE = await axios.get('/clinic/invoicing/services', {
          params: REQUEST,
        });
        const { servicios } = RESPONSE.data.data;
        // bloquear servicios que no tengan asignados un seguro
        let seguroDoble = null;
        seguroDoble = rootState.detalleFactura.seguroDobleSelected;
        if (seguroDoble) {
          servicios.forEach((element) => {
            element.$isDisabled = !element.is_insurer_service;
          });
        } else {
          servicios.forEach((element) => {
            element.$isDisabled = false;
          });
        }
        commit('setServicios', servicios);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async getPreciosServicio({
      commit, state, rootState, rootGetters,
    }) {
      commit('setIsDisabledAddButton', true);
      let medicoID = null;
      if (rootState.formFactura.medicoSelected !== null) {
        medicoID = rootState.formFactura.medicoSelected.id;
      }
      let aseguradoraID = null;
      if (rootState.formFactura.aseguradoraSelected !== null) {
        aseguradoraID = rootState.formFactura.aseguradoraSelected.id;
      }
      const INDICE_ULTIMO_SERVICIO = state.listaTemporal.length - 1;
      const REQUEST = {
        servicio_id: state.listaTemporal[INDICE_ULTIMO_SERVICIO].id,
        is_factura: rootGetters['formFactura/isTipoFacturaConFactura'],
        tipo_factura: rootGetters['formFactura/getTipoFactura'],
        factura_tercero: rootState.formFactura.checkFacturaTercero,
        medico_id: medicoID,
        seguro_medico: rootState.formFactura.checkSeguroEstado,
        aseguradora_id: aseguradoraID,
      };
      try {
        const RESPONSE = await axios.get('/clinic/invoicing/service/prices', {
          params: REQUEST,
        });
        const CONFIG_FACTURA_ELECTRONICA = RESPONSE.data.data.configuracion_factura_electronica;
        const siatMedioPagoMedico = RESPONSE.data.data.siat_medio_pago;
        const siatTipoDocumentoMedico = RESPONSE.data.data.siat_tipo_documento;

        const { impuestos } = RESPONSE.data.data;
        const { precios } = RESPONSE.data.data;
        const SEGURO_SERVICIO = RESPONSE.data.data.seguro_servicio;
        const { servicio } = RESPONSE.data.data;
        const NUMERO_FACTURA = RESPONSE.data.data.numero_factura;
        const DOCUMENTO_SECTOR_ID = RESPONSE.data.data.documento_sector_id;
        const TIPO_DOCUMENTO_ID = RESPONSE.data.data.tipo_documento_id;
        /* const TIPO_IMPUESTO = RESPONSE.data.data.tipo_impuesto; */
        commit('setImpuestos', impuestos);
        const PRECIOS_CONFIG = {
          precios,
          seguroServicio: SEGURO_SERVICIO,
          monedas: rootState.formFactura.comboMoneda,
          monedaSelectedId: rootState.formFactura.monedaSelected.id,
          tipoMoneda: rootState.formFactura.monedaSelected.is_principal,
          monedaServicioID: servicio.moneda_id,
          tipoCambio: rootState.formFactura.tipoCambio,
        };
        commit('setPrecioTipoCambio', PRECIOS_CONFIG);
        commit('setPrecioSelected', precios[0]);
        commit('setPrecioModal', precios[0].monto);
        const CON_FACTURA = rootGetters['formFactura/isTipoFacturaConFactura'];
        const MODO_EDICION = rootGetters['facturacion/getModoEdicion'];
        if (CON_FACTURA && MODO_EDICION === false) {
          const FACTURA_TERCERO = rootState.formFactura.checkFacturaTercero;
          const TIPO_FACTURA_MANUAL = rootGetters['formFactura/isTipoFacturaManual'];
          if (!(FACTURA_TERCERO && TIPO_FACTURA_MANUAL)) {
            commit('formFactura/setNroFactura', NUMERO_FACTURA, { root: true });
          }
        }
        const precioUnitario = parseFloat(precios[0].monto).toFixed(2);
        /*
          para modificar itemServicio tambien modificar el item de servicio en AddServiceModal.vue
          en la funcion agregarServicio, ya que los 2 objetos estan relacionados.
          es decir si agrego alguna propiedad o quito tambien hacerlo en los 2 lados.
        */
        let siatActividadEconomicaId = servicio.siat_actividad_economica_id;
        let siatDescripcionService = servicio.siat_descripcion_service;
        let siatDescripcionUnidadMedida = servicio.siat_descripcion_unidad_medida;
        let siatTipoProductoServiceId = servicio.siat_tipo_producto_service_id;
        let siatUnidadMedidaId = servicio.siat_unidad_medida_id;
        const medico = rootState.formFactura.medicoSelected;
        if (medico) {
          if (medico.configuracion_factura_tercero === 0) {
            siatActividadEconomicaId = medico.siat_actividad_economica_id;
            siatDescripcionService = medico.siat_nombre_producto_servicio;
            siatDescripcionUnidadMedida = medico.siat_nombre_unidad_medida;
            siatTipoProductoServiceId = medico.siat_producto_servicio_id;
            siatUnidadMedidaId = medico.siat_unidad_medida_id;
            // //
            // const TYPE_DOCUMENT_ID = rootState.formFactura.pacienteSelected.type_document_id;
            // if (!TYPE_DOCUMENT_ID) {
            //   commit('setIndentificacionSelected', siatTipoDocumentoMedico[0]);
            // } else {
            //   const IDENTIFICACION_SELECTED = siatTipoDocumentoMedico.find((el) => el.id === TYPE_DOCUMENT_ID) ?? null;
            //   commit('setIndentificacionSelected', IDENTIFICACION_SELECTED);
            // }
          }
        }
        const itemServicio = {
          numero: servicio.numero,
          servicio_id: servicio.id,
          descripcion: servicio.descripcion,
          medicoTempSelected: null,
          cantidad: 1,
          precio_unitario: parseFloat(precios[0].monto).toFixed(2),
          total: precioUnitario,
          servicio: servicio.nombre,
          /* desc: '-', */
          seguro: '0.00',
          is_turno_manual: false,
          fecha_turno: null,
          hora_turno: null,
          numero_ticket: null,
          numero_turno: null,
          turno_medico_id: null,
          siat_actividad_economica_id: siatActividadEconomicaId,
          siat_descripcion_service: siatDescripcionService,
          siat_descripcion_unidad_medida: siatDescripcionUnidadMedida,
          siat_tipo_producto_service_id: siatTipoProductoServiceId,
          siat_unidad_medida_id: siatUnidadMedidaId,
          idRow: state.idRow,
          tipo_atencion_id: null,
          medicoDerivadorSelected: null,
        };
        commit('incrementarIdRow');
        commit('setItemListaDetalleTemporal', itemServicio);
        commit('setActividadEconomicaId', servicio.actividad_economica_id);
        commit('formFactura/setConfigFacturaElectronicaMedico', CONFIG_FACTURA_ELECTRONICA, { root: true });
        commit('formFactura/setSiatMedioPagoMedico', siatMedioPagoMedico, { root: true });
        commit('formFactura/setSiatTipoDocumentoMedico', siatTipoDocumentoMedico, { root: true });
        commit('setDocumentoSectorId', DOCUMENTO_SECTOR_ID);
        commit('setSiatTipoFacturaId', TIPO_DOCUMENTO_ID);
        commit('setIsDisabledAddButton', false);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    resetModalWhenClosed({ commit, rootState, dispatch }) {
      commit('setServiciosSelected', []);
      commit('setListaTemporal', []);
      commit('setListaDetalleTemporal', []);
      commit('setPrecioSelected', null);
      commit('setPrecios', []);
      commit('setPrecioModal', '');
      commit('setPrecioSeguroSelected', null);
      commit('setListaPrecioSeguros', []);
      commit('setInputPrecioSeguro', 0);
      commit('setCantidad', 1);
      if (rootState.tableAndAddService.listaTabla.length === 0) {
        commit('setActividadEconomicaId', 0);
        commit('setDocumentoSectorId', null);
        commit('setSiatTipoFacturaId', null);
        dispatch('getServiciosApi');
      }
      commit('setIsDisabledAddButton', true);
    },
    resetDatosAddServiceModal({ commit, dispatch }) {
      commit('setActividadEconomicaId', 0);
      commit('setDocumentoSectorId', null);
      commit('setSiatTipoFacturaId', null);
      commit('setServiciosSelected', []);
      commit('setListaTemporal', []);
      commit('setListaDetalleTemporal', []);
      commit('setPrecioSelected', null);
      commit('setPrecios', []);
      commit('setPrecioModal', '');
      commit('setCantidad', 1);
      commit('setPrecioSeguroSelected', null);
      commit('setListaPrecioSeguros', []);
      commit('setInputPrecioSeguro', 0);
      commit('formFactura/setConfigFacturaElectronicaMedico', null, { root: true });
      commit('formFactura/setSiatMedioPagoMedico', [], { root: true });
      commit('formFactura/setSiatTipoDocumentoMedico', [], { root: true });
      dispatch('getServiciosApi');
      commit('setIsDisabledAddButton', true);
    },
    resetAddServiceModal({ commit }) {
      commit('setActividadEconomicaId', 0);
      commit('setDocumentoSectorId', null);
      commit('setSiatTipoFacturaId', null);
      commit('setCategoriaServiciosSelected', null);
      commit('setCategoriaServicios', []);
      commit('setServiciosSelected', []);
      commit('setServicios', []);
      commit('setListaTemporal', []);
      commit('setListaDetalleTemporal', []);
      commit('setPrecioSelected', null);
      commit('setPrecios', []);
      commit('setPrecioModal', '');
      commit('setCantidad', 1);
      commit('setImpuestos', null);
      commit('setIsDisabledAddButton', true);
      commit('setPrecioSeguroSelected', null);
      commit('setListaPrecioSeguros', []);
      commit('setInputPrecioSeguro', 0);
      commit('setIdRow', 0);
    },
  },
};
