import store from '@/store/index';
import { redirectIfHasPermission } from '@/store/modules/privilegios/utilidades';

const title = 'Internacion';
const tratamientoRoutes = [
  {
    path: 'internacion',
    name: 'internacion-index',
    component: () => import('../views/index.vue'),
    meta: {
      title,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionListarTratamiento'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'internacion/create',
    name: 'internacion-create',
    component: () => import('../views/create.vue'),
    meta: {
      title: `${title} | Nuevo`,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionNuevoTratamiento'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'internacion/:id/edit',
    name: 'internacion-edit',
    component: () => import('../views/edit.vue'),
    meta: {
      title: `${title} | Editar`,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionModificarTratamiento'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'internacion/:id/atencion-enfermeria/edit/:paciente_id',
    name: 'internacion-atencion-enfermeria-edit',
    component: () => import('../views/atencionEnfermeria.vue'),
    meta: {
      title: `${title} | Atención Enfermeria`,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionModificarTratamiento'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: '/cirugia',
    name: 'cirugia-index',
    component: () => import('../views/cirugia.vue'),
    meta: {
      title: 'cirugia',
    },
  },
  {
    path: 'internacion/historial-clinico',
    name: 'historial-clinico',
    component: () => import('../views/historiaClinicaInternacion.vue'),
    meta: {
      title: 'historial-clinico',
    },
  },
];
export default tratamientoRoutes;
