import moment from 'moment';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function callStoreApi({
  state,
  rootState,
  rootGetters,
  dispatch,
}) {
  const PACIENTE = rootGetters['formFactura/getPacienteSelected'];
  const PACIENTE_ID = PACIENTE === null ? null : PACIENTE.id;
  const MEDICO = rootGetters['formFactura/getMedicoSelected'];
  const MEDICO_ID = MEDICO === null ? null : MEDICO.id;
  const ASEGURADORA = rootState.formFactura.aseguradoraSelected;
  const ASEGURADORA_DOBLE = rootState.detalleFactura.seguroDobleSelected;
  const ASEGURADORA_ID = ASEGURADORA === null ? null : ASEGURADORA.id;
  const ASEGURADORA_DOBLE_ID = ASEGURADORA_DOBLE === null ? null : ASEGURADORA_DOBLE.id;
  let tipo = 'VS';
  let formaPagoTesorias = rootGetters['formaPagoModal/getFormaPagoTesorerias'];
  let formaPago = rootGetters['formaPagoModal/getFormaPago'];
  let medioPago = rootGetters['formaPagoModal/getMedioPago'];
  if (rootGetters['formFactura/getCheckFacturaTercero']) {
    tipo = 'VM';
    formaPagoTesorias = null;
    formaPago = {
      tipo_forma_pago_id: 1,
      caja: rootState.formaPagoFacturaTercero.caja,
      banco: rootState.formaPagoFacturaTercero.banco,
      tarjeta: rootState.formaPagoFacturaTercero.tarjeta,
    };
    medioPago = null;
  }
  const RESERVA_ID = rootState.modalReserva.reservaId;
  let subTotal = rootGetters['detalleFactura/getTotal'];
  const TOTAL_SEGURO = rootState.detalleFactura.totalSeguro;
  if (rootState.detalleFactura.seguroDobleSelected) {
    subTotal -= TOTAL_SEGURO;
  }
  const REQUEST = {
    extra_factura_electronica: rootState.main.extrafacturaElectronica,
    venta: {
      paciente_id: PACIENTE_ID,
      fecha: moment(rootGetters['formFactura/getDatePicker']).format('YYYY-MM-DD'),
      moneda_id: rootState.formFactura.monedaSelected.id,
      total: rootGetters['detalleFactura/getTotal'],
      descuento_porcentaje: '0',
      descuento_monto: '0',
      glosa: rootGetters['detalleFactura/getGlosa'],
      glosa_tipo: rootGetters['detalleFactura/getGlosaTipo'],
      sucursal_id: state.sucursal.id,
      vendedor_id: null,
      factura_nombre: rootState.formFactura.razonSocial,
      factura_nit: rootState.formFactura.nit,
      dato_factura: rootState.formFactura.datoFactura,
      numero_factura: rootState.formFactura.nroFactura,
      factura_numero_manual: rootState.formFactura.facturaNumeroManual,
      factura: rootGetters['formFactura/isTipoFacturaConFactura'],
      factura_tercero: rootGetters['formFactura/getCheckFacturaTercero'],
      tipo_factura: rootGetters['formFactura/getTipoFactura'],
      tipo,
      actividad_economica_id: rootState.addServiceModal.actividadEconomicaId,
      medico_id: MEDICO_ID,
      tipo_venta: 3,
      seguro_medico: rootState.formFactura.checkSeguroEstado,
      aseguradora_id: ASEGURADORA_ID,
      aseguradora_doble_id: ASEGURADORA_DOBLE_ID,
      total_seguro: rootState.detalleFactura.totalSeguro,
      total_seguro_doble: rootState.detalleFactura.totalSeguroDoble,
      tratamiento: rootState.modalCierreTratamiento.cierreConfirmado,
      valor_cero: rootState.formFactura.checkValorCero,
      reserva_id: RESERVA_ID,
      monto_reserva: rootGetters['detalleFactura/getTotal'],
      subtotal: subTotal,
    },
    detalles: rootGetters['tableAndAddService/getListaTabla'],
    moneda_secundaria: rootGetters['formFactura/getMonedaSecundaria'],
    forma_pago: formaPago,
    forma_pago_tesorias: formaPagoTesorias,
    medio_pago: medioPago,
    ajustes: rootState.formaPagoModal.ajustesEnUso,
  };
  if (rootState.main.extrafacturaElectronica) {
    REQUEST.tipo_contingencia_id = rootState.formFactura.tipoContingenciaSelected ? rootState.formFactura.tipoContingenciaSelected.id : '';
    REQUEST.siat_leyenda_id = rootState.formFactura.siatLeyendaSelected ? rootState.formFactura.siatLeyendaSelected.id : '';
    REQUEST.siat_nombre_leyenda = rootState.formFactura.siatLeyendaSelected ? rootState.formFactura.siatLeyendaSelected.leyenda : '';
    let siatVentaId = null;
    let dataFacturador = null;
    if (rootGetters['formFactura/isTipoFacturaComputarizadaEnLinea'] || rootGetters['formFactura/isTipoFacturaElectronicaEnLinea']) {
      REQUEST.siat_tipo_emision_id = rootState.formFactura.tipoEmisionSelected ? rootState.formFactura.tipoEmisionSelected.id : '';
      REQUEST.siat_cod_clasificador_tipo_emision = rootState.formFactura.tipoEmisionSelected ? rootState.formFactura.tipoEmisionSelected.cod_clasificador : '';
      const RESPONSE_FACTURADOR = await dispatch('saveFacturaVenta');
      if (RESPONSE_FACTURADOR.STORE_OK) {
        siatVentaId = RESPONSE_FACTURADOR.data.siat_venta_id;
        dataFacturador = RESPONSE_FACTURADOR.data.factura;
      } else {
        const ERROR_MESSAGE = 'Hubo un error al guardar la venta en el facturador';
        throw new Error(ERROR_MESSAGE);
      }
    }
    REQUEST.siat_venta_id = siatVentaId;
    REQUEST.data_facturador = dataFacturador;
  }

  try {
    const response = await axios.post('/clinic/invoicing', REQUEST);
    util.showNotify(response.data.message, 'success');

    return {
      STORE_OK: true,
      ventaId: response.data.data.id,
    };
  } catch (error) {
    const FALLO_VALIDACION = 422;
    if (error.response.status !== FALLO_VALIDACION) {
      util.showNotify(error.response.data.message, 'error');
    } else {
      Object.entries(error.response.data.data).forEach(([, mensajes]) => {
        mensajes.forEach((texto) => util.showNotify(texto, 'error'));
      });
    }
    return false;
  }
}
