import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function callEditApi({ commit }, id) {
  try {
    const RESPONSE = await axios.get(`/clinic/parametros/clasificacion_cie/${id}`);
    const SUB_CLASIFICACIONS = RESPONSE.data.data.subclasificacion_cies;
    const readInputs = [];
    SUB_CLASIFICACIONS.forEach((element, index) => {
      SUB_CLASIFICACIONS[index].estado = true;
      SUB_CLASIFICACIONS[index].key = `CL-${element.id}`;
      readInputs.push({ value: true });
    });
    const NOMBRE_CLASIFICACION = RESPONSE.data.data.clasificacion_cie.nombre;
    const CODIGO = RESPONSE.data.data.clasificacion_cie.codigo;
    const NUMERO = RESPONSE.data.data.clasificacion_cie.numero;
    const ID = RESPONSE.data.data.clasificacion_cie.id;
    commit('formularioClasificacion/setListaSubClasificacions', SUB_CLASIFICACIONS, { root: true });
    commit('formularioClasificacion/setNombreClasificacion', NOMBRE_CLASIFICACION, { root: true });
    commit('formularioClasificacion/setCodigoClasificacion', CODIGO, { root: true });
    commit('formularioClasificacion/setNumero', NUMERO, { root: true });
    commit('formularioClasificacion/setIsLoadin', false, { root: true });
    commit('formularioClasificacion/setReadInputs', readInputs, { root: true });
    commit('formularioClasificacion/setItemId', ID, { root: true });
    return true;
  } catch (error) {
    util.showNotify(error.response.data.message, 'error');
    return false;
  }
}
