import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function callEditApi({ commit }, id) {
  try {
    const RESPONSE = await axios.get(`/clinic/parametros/categoria_linae/${id}`);
    const SUB_CATEGORIAS = RESPONSE.data.data.subcategoria_linae;
    const readInputs = [];
    SUB_CATEGORIAS.forEach((element, index) => {
      SUB_CATEGORIAS[index].estado = true;
      SUB_CATEGORIAS[index].key = `C-${element.id}`;
      readInputs.push({ value: true });
    });
    const NOMBRE_CATEGORIA = RESPONSE.data.data.categoria_linae.nombre;
    const CODIGO = RESPONSE.data.data.categoria_linae.codigo;
    const NUMERO = RESPONSE.data.data.categoria_linae.numero;
    const ID = RESPONSE.data.data.categoria_linae.id;
    commit('formularioCategoriaLinae/setListaSubCategoria', SUB_CATEGORIAS, { root: true });
    commit('formularioCategoriaLinae/setNombreCategoria', NOMBRE_CATEGORIA, { root: true });
    commit('formularioCategoriaLinae/setCodigoCategoria', CODIGO, { root: true });
    commit('formularioCategoriaLinae/setNumero', NUMERO, { root: true });
    commit('formularioCategoriaLinae/setIsLoadin', false, { root: true });
    commit('formularioCategoriaLinae/setReadInputs', readInputs, { root: true });
    commit('formularioCategoriaLinae/setItemId', ID, { root: true });
    return true;
  } catch (error) {
    util.showNotify(error.response.data.message, 'error');
    return false;
  }
}
