export default {
  namespaced: true,
  state: {
    aseguradoraSelected: null,
    comboAseguradora: [
      { value: null, text: 'Selecciona un Item' },
      { value: '1', text: 'Bisa Seguro' },
    ],
  },
  getters: {
    getAseguradoraSelected: (state) => state.aseguradoraSelected,
    getComboAseguradora: (state) => state.comboAseguradora,
  },
  mutations: {
    setAseguradoraSelected: (state, data) => { state.aseguradoraSelected = data; },
    setComboAseguradora: (state, data) => { state.comboAseguradora = data; },
  },
};
