/* import store from '@/store/index';
import { redirectIfHasPermission } from '@/store/modules/privilegios/utilidades';
 */
const title = 'Orden de Trabajo';
const ordenTrabajoRoutes = [
  {
    path: 'orden-trabajo',
    name: 'orden-trabajo-index',
    component: () => import('../views/index.vue'),
    meta: {
      title,
    },
    /* beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionListarOrdenTrabajo'];
      redirectIfHasPermission(PERMISO, next);
    }, */
  },
  {
    path: 'orden-trabajo/:id/form1',
    name: 'orden-trabajo-form1',
    component: () => import('../views/formulario1.vue'),
    meta: {
      title: `${title} | Form. 1`,
    },
  },
  {
    path: 'orden-trabajo/:id/form2',
    name: 'orden-trabajo-form2',
    component: () => import('../views/formulario2.vue'),
    meta: {
      title: `${title} | Form. 2`,
    },
  },
];
export default ordenTrabajoRoutes;
