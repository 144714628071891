import Vuex from 'vuex';
import Vue from 'vue';
import largeSidebar from './modules/largeSidebar';
import compactSidebar from './modules/compactSidebar';
import config from './modules/config';
import verticalSidebar from './modules/verticalSidebar';
import auth from './modules/auth';
import main from './modules/main';
import pacienteStore from './modules/paciente/pacienteStore';
import medicoStore from './modules/medico/medicoStore';
import MedicoList from './modules/medico/ApiList';
import facturacionStore from './modules/facturacion/facturacionStore';
import anticipoStore from './modules/anticipo/anticipoStore';
import cotizacionStore from './modules/cotizacion/index';
import tratamientoStore from './modules/tratamiento/tratamientoStore';
import clasificacionCie from './modules/parametros/clasificacionCie/index';
import categoriaLinae from './modules/parametros/categoriaLinae/index';
import ConsultaExternaList from './modules/consultaExterna/ApiList';
import ConsultaExternaEdit from './modules/consultaExterna/ApiEdit';
import ConsultaExternaPaciente from './modules/consultaExterna/paciente/ApiList';
import consulaExternaStore from './modules/consultaExterna/consultaExternaStore';
import theModalServicios from './modules/consultaExterna/theModalServicios';
import calendarioStore from './modules/calendario/calendarioStore';
import atencionEnfermeria from './modules/tratamiento/atencionEnfermeria/apiList';
import theModalMedicalIndication from './modules/tratamiento/atencionEnfermeria/theModalMedicalIndication';

Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    largeSidebar,
    compactSidebar,
    config,
    verticalSidebar,
    auth,
    main,
    ...pacienteStore,
    ...medicoStore,
    MedicoList,
    ...facturacionStore,
    ...anticipoStore,
    ...cotizacionStore,
    ...tratamientoStore,
    ...clasificacionCie,
    ...categoriaLinae,
    ConsultaExternaList,
    ConsultaExternaEdit,
    ConsultaExternaPaciente,
    ...consulaExternaStore,
    theModalServicios,
    ...calendarioStore,
    atencionEnfermeria,
    theModalMedicalIndication,
  },
});
