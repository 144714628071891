import { util } from '@/plugins/util';

export default function validarParametroFacturacionSiat(data, showNotificacion = true, extrafacturaElectronica) {
  let results = [];
  if (extrafacturaElectronica) {
    if (data && data.original) {
      if (showNotificacion) {
        util.showNotify(data.original.message, 'error');
      }
      results = data.original.data;
    } else {
      results = data;
    }
  }
  return results;
}
