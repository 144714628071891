export default {
  namespaced: true,
  state: {
    medicos: [],
    listaMedicosCabecera: [],
  },
  getters: {
    getMedicos: (state) => state.medicos,
    getlistaMedicosCabecera: (state) => state.listaMedicosCabecera,
  },
  mutations: {
    setMedicos: (state, data) => { state.medicos = data; },
    setlistaMedicosCabecera: (state, data) => { state.listaMedicosCabecera = data; },
    addItemlistaMedicosCabecera: (state, item) => state.listaMedicosCabecera.push(item),
  },
};
