export default {
  state: () => ({
    compactSidebarToggleProperties: {
      isSideNavOpen: true,

      isActiveSecondarySideNav: false,
    },
    compactLeftSideBarBgColor: 'sidebar-dark-purple',
  }),
  getters: {
    getCompactSideBarToggleProperties: (state) => state.compactSidebarToggleProperties,
    getcompactLeftSideBarBgColor: (state) => state.compactLeftSideBarBgColor,
  },
  actions: {
    changeCompactSidebarProperties({ commit }) {
      commit('toggleCompactSidebarProperties');
    },
    changecompactLeftSideBarBgColor({ commit }, data) {
      commit('togglecompactLeftSideBarBgColor', data);
    },
  },
  mutations: {
    toggleCompactSidebarProperties: (state) => {
      state.compactSidebarToggleProperties.isSideNavOpen = !state.compactSidebarToggleProperties.isSideNavOpen;
    },
    togglecompactLeftSideBarBgColor(state, data) {
      state.compactLeftSideBarBgColor = data;
    },
  },
};
