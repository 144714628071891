import cotizacionAPIS from './cotizacionAPIS';
import formularioCotizacion from './formularioCotizacion';
import tablaServicios from './tablaServicios';
import modalServicios from './modalServicios';
import glosa from './glosa';

const cotizacionStore = {
  cotizacionAPIS,
  formularioCotizacion,
  tablaServicios,
  modalServicios,
  glosa,
};

export default cotizacionStore;
