import store from '@/store/index';
import { redirectIfHasPermission } from '@/store/modules/privilegios/utilidades';

const title = 'Aseguradora';
const aseguradoraRoutes = [
  {
    path: 'aseguradora',
    name: 'aseguradora-index',
    component: () => import('../views/index.vue'),
    meta: {
      title,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionListarSeguro'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'aseguradora/create',
    name: 'aseguradora-create',
    component: () => import('../views/create.vue'),
    meta: {
      title: `${title} | Nuevo`,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionNuevoSeguro'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'aseguradora/:id/edit',
    name: 'aseguradora-edit',
    component: () => import('../views/edit.vue'),
    meta: {
      title: `${title} | Editar`,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionModificarSeguro'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
  {
    path: 'aseguradora/importar',
    name: 'aseguradora-import',
    component: () => import('../views/import.vue'),
    meta: {
      title: `${title} | Importar`,
    },
  },
];
export default aseguradoraRoutes;
