import { hasPermision } from './utilidades';

export default {
  hasPermisionListarAnticipo(state) {
    const KEY = 'ANT_LISTAR';
    const ACCION = 'listar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionNuevoAnticipo(state) {
    const KEY = 'ANT_NUEVO';
    const ACCION = 'nuevo';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionModificarAnticipo(state) {
    const KEY = 'ANT_EDITAR';
    const ACCION = 'modificar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionVerAnticipo(state) {
    const KEY = 'ANT_VER';
    const ACCION = 'ver';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionEliminarAnticipo(state) {
    const KEY = 'ANT_ELIMINAR';
    const ACCION = 'eliminar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionImprimirAnticipo(state) {
    const KEY = 'ANT_IMPRIMIR';
    const ACCION = 'imprimir';
    return hasPermision(state, KEY, ACCION);
  },
};
