import { getField, updateField } from 'vuex-map-fields';
import axios from '@/modules/common/axiosERP';

export default {
  namespaced: true,
  state: {
    familiar: [], // Aquí se almacenará la respuesta de la API
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_FAMILIARES(state, familiares) {
      state.familiar = familiares; // Mutación para actualizar el estado
    },
  },
  actions: {
    async getFamiliares({ commit }, pacienteId) {
      try {
        const response = await axios.get(`/clinic/patient/familiar_contacto/${pacienteId}`);
        commit('SET_FAMILIARES', response.data.data.familiar_paciente); // Guardar familiares en el estado
      } catch (error) {
        console.error('Error fetching familiares:', error);
      }
    },
  },
};
