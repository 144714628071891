import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function getDatosPacienteSelected({ commit }, idPaciente) {
  try {
    const RESPONSE = await axios.get(`/clinic/patient/secure_medic/${idPaciente}`);
    const { paciente } = RESPONSE.data.data;
    commit('formularioCotizacion/setPacienteToShow', paciente, { root: true });
  } catch (error) {
    util.showNotify(error.response.data.message, 'error');
  }
}
