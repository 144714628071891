import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    glosa: '',
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setGlosa: (state, data) => { state.glosa = data; },
  },
  actions: {
    resetGlosa({ commit }) {
      commit('setGlosa', '');
    },
  },
};
