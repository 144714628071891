import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function obtenerListaPacientes({ commit, rootState }, query) {
  commit('formularioCotizacion/setIsLoadingComboPacientes', true, { root: true });
  if (query !== null) {
    commit('formularioCotizacion/setQueryTemporalPaciente', query, { root: true });
  } else {
    commit('formularioCotizacion/setTemporaPageComboPacientes', rootState.formularioCotizacion.temporaPageComboPacientes + 1, { root: true });
  }
  try {
    const REQUEST = {
      filter_key: rootState.formularioCotizacion.queryTemporalPaciente,
      page: 100 * rootState.formularioCotizacion.temporaPageComboPacientes,
      per_page: 1,
    };
    const RESPONSE = await axios.get('/clinic/patient/create/search', {
      params: REQUEST,
    });
    let pacientes = RESPONSE.data.data.data;
    if (pacientes === undefined) {
      console.error('la lista de pacientes llega como undefined de la api.');
      const MENSAJE_ERROR = `Error al traer la lista de pacientes,
       contactese con el Administrador.`;
      util.showNotify(MENSAJE_ERROR, 'error');
      pacientes = [];
    }
    const { total } = RESPONSE.data.data;
    commit('formularioCotizacion/setPacientes', pacientes, { root: true });
    commit('formularioCotizacion/setTotalSearchPacientes', total, { root: true });
  } catch (error) {
    util.showNotify(error.response.data.message, 'error');
  } finally {
    commit('formularioCotizacion/setIsLoadingComboPacientes', false, { root: true });
  }
}
