import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    busqueda: null,
    listarTabla: [],
    estado: true,
    porPagina: 50,
    paginaActual: 1,
    paginaOpciones: [50, 100],
    sortBy: 'numero',
    isLoading: true,
    sortDesc: true,
    totalFilas: 0,
    fields: [
      {
        key: 'editar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
      },
      {
        key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
      },
      {
        key: 'numero', label: 'N°', thClass: 'th-custom-color', thStyle: { width: '5%' }, sortable: true,
      },
      {
        key: 'codigo', label: 'Grupo', thClass: 'th-custom-color', thStyle: { width: '10%' }, sortable: true,
      },
      {
        key: 'clasificacion', label: 'Producto de Salud', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '30%' }, sortable: true,
      },
      {
        key: 'subclasificacion_cie', label: 'Diagnostico', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '30%' }, sortable: true,
      },
    ],
    componentShow: 'TheIndexClasificacionCie',

    // data crear y editar
    nombreClasificacion: null,
    codigoClasificacion: null,
    listaSubClasificacions: [],
    codigoSubClasificacion: null,
    nombreSubClasificacion: null,
    readInputs: [],
    numero: null,
    itemId: null,
    nombreDiagnostico: null,
    nombreTratamientoPrincipal: null,
    idNombreDiagnostico: 0,
    cantidadSelected: null,
    cantidades: [0, 1, 2, 3, 4],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setBusqueda: (state, data) => { state.busqueda = data; },
    setListarTabla: (state, data) => { state.listarTabla = data; },
    setEstado: (state, data) => { state.estado = data; },
    setTotalFila: (state, data) => { state.totalFilas = data; },
    setComponentShow: (state, data) => { state.componentShow = data; },
    setPorPagina: (state, data) => { state.porPagina = data; },
    setPaginaActual: (state, data) => { state.paginaActual = data; },
    setIsLoadin: (state, data) => { state.isLoading = data; },

    setNombreClasificacion: (state, data) => { state.nombreClasificacion = data; },
    setCodigoClasificacion: (state, data) => { state.codigoClasificacion = data; },
    setListaSubClasificacions: (state, data) => { state.listaSubClasificacions = data; },
    setCodigoSubClasificacion: (state, data) => { state.codigoSubClasificacion = data; },
    setNombreSubClasificacion: (state, data) => { state.nombreSubClasificacion = data; },
    setReadInputs: (state, data) => { state.readInputs = data; },
    setNumero: (state, data) => { state.numero = data; },
    setItemId: (state, data) => { state.itemId = data; },
    setNombreDiagnositco(state, data) {
      state.nombreDiagnostico = data;
    },
    setIdNombreDiagnositco: (state, data) => { state.idNombreDiagnostico = data; },
    setNombreTratamientoPrincipal: (state, data) => { state.nombreTratamientoPrincipal = data; },
    setCantidadSelected: (state, data) => { state.cantidadSelected = data; },
    setCantidades: (state, data) => { state.cantidades = data; },
  },
  actions: {
    resetFormularioClasificacion({ commit }) {
      commit('setBusqueda', null);
      commit('setComponentShow', 'TheIndexClasificacionCie');

      commit('setNombreClasificacion', null);
      commit('setCodigoClasificacion', null);
      commit('setListaSubClasificacions', []);
      commit('setCodigoSubClasificacion', null);
      commit('setNombreSubClasificacion', null);
      commit('setReadInputs', []);
      commit('setItemId', null);
      commit('setNombreDiagnositco', null);
      commit('setIdNombreDiagnositco', 0);
      commit('setNombreTratamientoPrincipal', null);
      commit('setCantidadSelected', null);
    },

    resetFormularioClasificacionStore({ commit }) {
      commit('setNombreClasificacion', null);
      commit('setCodigoClasificacion', null);
      commit('setListaSubClasificacions', []);
      commit('setCodigoSubClasificacion', null);
      commit('setNombreSubClasificacion', null);
      commit('setReadInputs', []);
      commit('setNumero', null);
      commit('setIsLoadin', false);
      commit('setItemId', null);
    },
  },
};
