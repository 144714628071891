import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    tipoFacturas: [],
    tipoFacturaSelected: null,
    categoriaServicios: [],
    tipoCategoriaSelected: null,
    tipoComisions: [],
    tipoComisionSelected: null,
    tipoReconsultas: [
      {
        id: 1,
        nombre: 'Si',
      },
      {
        id: 2,
        nombre: 'No',
      },
    ],
    tipoReconsultaSelected: null,
    listaComisiones: [],
    fields: [
      {
        key: 'numero', label: 'N°', thClass: 'th-custom-gris', class: 'text-center', thStyle: { width: '5%' },
      },
      {
        key: 'servicio', label: 'Servicio', thClass: 'th-custom-gris', class: 'text-left', thStyle: { width: '25%' },
      },
      {
        key: 'comision', label: 'Comision', thClass: 'th-custom-gris', class: 'text-center', thStyle: { width: '15%' },
      },
      {
        key: 'categoria', label: 'Categoria', thClass: 'th-custom-gris', class: 'text-center', thStyle: { width: '15%' },
      },
      {
        key: 'reconsulta_detallado', label: 'ReConsulta', thClass: 'th-custom-gris', class: 'text-center', thStyle: { width: '15%' },
      },
      {
        key: 'calculo', label: 'Calculo', thClass: 'th-custom-gris', class: 'text-center', thStyle: { width: '15%' },
      },
      {
        key: 'tratante', label: 'Tratante', thClass: 'th-custom-gris', class: 'text-center', thStyle: { width: '20%' },
      },
      {
        key: 'referido', label: 'Derivador', thClass: 'th-custom-gris', class: 'text-center', thStyle: { width: '10%' },
      },
      {
        key: 'editar', label: ' ', thClass: 'th-custom-gris', class: 'text-center', thStyle: { width: '3%' },
      },
      {
        key: 'eliminar', label: ' ', thClass: 'th-custom-gris', class: 'text-center', thStyle: { width: '3%' },
      },
    ],
    tipoServicio: null,
    tiempoValidez: null,
    tratante: 0,
    referido: 0,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setTipoFactura: (state, data) => { state.tipoFacturas = data; },
    setTipoFacturaSelected: (state, data) => { state.tipoFacturaSelected = data; },
    setCategoriaServicios: (state, data) => { state.categoriaServicios = data; },
    setTipoCategoriaSelected: (state, data) => { state.tipoCategoriaSelected = data; },
    setTipoComision: (state, data) => { state.tipoComisions = data; },
    setTipoComisionSelected: (state, data) => { state.tipoComisionSelected = data; },
    setTipoReconsulta: (state, data) => { state.tipoReconsultas = data; },
    setTipoReconsultaSelected: (state, data) => { state.tipoReconsultaSelected = data; },
    setTipoServicio: (state, data) => { state.tipoServicio = data; },
    setTiempoValidez: (state, data) => { state.tiempoValidez = data; },
    setTratante: (state, data) => { state.tratante = data; },
    setReferido: (state, data) => { state.referido = data; },
    setListaComisiones: (state, data) => { state.listaComisiones = data; },
  },
  actions: {
    resetModalComisiones({ commit }) {
      commit('setTipoFactura', []);
      commit('setTipoFacturaSelected', null);
      commit('setCategoriaServicios', []);
      commit('setTipoCategoriaSelected', null);
      commit('setTipoComision', []);
      commit('setTipoComisionSelected', null);
      // commit('setTipoReconsulta', []);
      commit('setTipoReconsultaSelected', null);
      commit('setTipoServicio', null);
      commit('setTiempoValidez', null);
      commit('setTratante', 0);
      commit('setReferido', 0);
      commit('setListaComisiones', []);
    },
  },
};
