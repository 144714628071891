import { hasPermision } from './utilidades';

export default {
  hasPermisionListarCotizacion(state) {
    const KEY = 'COT_LISTAR';
    const ACCION = 'listar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionNuevoCotizacion(state) {
    const KEY = 'COT_NUEVO';
    const ACCION = 'nuevo';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionModificarCotizacion(state) {
    const KEY = 'COT_EDITAR';
    const ACCION = 'modificar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionVerCotizacion(state) {
    const KEY = 'COT_VER';
    const ACCION = 'ver';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionEliminarCotizacion(state) {
    const KEY = 'COT_ELIMINAR';
    const ACCION = 'eliminar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionImprimirCotizacion(state) {
    const KEY = 'COT_IMPRIMIR';
    const ACCION = 'imprimir';
    return hasPermision(state, KEY, ACCION);
  },
};
