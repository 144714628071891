import store from '@/store/index';
import { redirectIfHasPermission } from '@/store/modules/privilegios/utilidades';

const title = 'Servicio Complementario';
const servicioComplementarioRoutes = [
  {
    path: 'servicios-complementarios',
    name: 'servicios-complementarios-index',
    component: () => import('../views/index.vue'),
    meta: {
      title,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionListarServicioComplementarios'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
];
export default servicioComplementarioRoutes;
