import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    modoEdicion: false,
    showCortinaInformativa: false,
    pacienteToShow: {
      anticipo: null,
      edad: '',
      ultima: '',
      cobertura: '',
      tratamiento: null,
    },
    correlativo: null,
    estado: true,
    datepicker: new Date(),
    pacienteSelected: null,
    pacientes: [],
    isLoadingComboPacientes: false,
    queryTemporalPaciente: '',
    temporaPageComboPacientes: 1,
    totalSearchPacientes: 0,
    formaPagoSelected: null,
    listaFormaPago: [],
    checkSeguroEstado: false,
    contacto: '',
    validezSelected: null,
    listaValidez: [],
    aseguradoraSelected: null,
    listaAseguradoras: [],
    monedaSelected: null,
    listaMonedas: [],
    tipoCambio: '',
    medicoSelected: null,
    listaMedicos: [],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setModoEdicion: (state, data) => { state.modoEdicion = data; },
    setShowCortinaInformativa: (state, data) => { state.showCortinaInformativa = data; },
    setPacienteToShow: (state, data) => { state.pacienteToShow = data; },
    setCorrelativo: (state, data) => { state.correlativo = data; },
    setEstado: (state, data) => { state.estado = data; },
    setDatepicker: (state, data) => { state.datepicker = data; },
    setPacienteSelected: (state, data) => { state.pacienteSelected = data; },
    setPacientes: (state, data) => { state.pacientes = data; },
    setIsLoadingComboPacientes: (state, data) => { state.isLoadingComboPacientes = data; },
    setQueryTemporalPaciente: (state, data) => { state.queryTemporalPaciente = data; },
    setTemporaPageComboPacientes: (state, data) => { state.temporaPageComboPacientes = data; },
    setTotalSearchPacientes: (state, data) => { state.totalSearchPacientes = data; },
    setFormaPagoSelected: (state, data) => { state.formaPagoSelected = data; },
    setListaFormaPago: (state, data) => { state.listaFormaPago = data; },
    setCheckSeguroEstado: (state, data) => { state.checkSeguroEstado = data; },
    setContacto: (state, data) => { state.contacto = data; },
    setValidezSelected: (state, data) => { state.validezSelected = data; },
    setListaValidez: (state, data) => { state.listaValidez = data; },
    setAseguradoraSelected: (state, data) => { state.aseguradoraSelected = data; },
    setListaAseguradoras: (state, data) => { state.listaAseguradoras = data; },
    setMonedaSelected: (state, data) => { state.monedaSelected = data; },
    setListaMonedas: (state, data) => { state.listaMonedas = data; },
    setTipoCambio: (state, data) => { state.tipoCambio = data; },
    setMedicoSelected: (state, data) => { state.medicoSelected = data; },
    setListaMedicos: (state, data) => { state.listaMedicos = data; },

    cargarComboMoneda(state, listaMonedas) {
      state.listaMonedas = listaMonedas;
      const ITEM_SELECTED = listaMonedas.find((el) => el.is_principal === 1);
      state.monedaSelected = ITEM_SELECTED;
      if (ITEM_SELECTED.tc === 0) {
        state.tipoCambio = null;
      } else {
        state.tipoCambio = ITEM_SELECTED.tc;
      }
    },
    cargarAseguradoraSelectedById(state, id) {
      if (id === null) {
        return;
      }
      state.aseguradoraSelected = state.listaAseguradoras.find((el) => el.id === id);
    },
    cargarFormaPagoSelectedById(state, id) {
      if (id === null) {
        return;
      }
      state.formaPagoSelected = state.listaFormaPago.find((el) => el.id === id);
    },
    cargarMedicoSelectedById(state, id) {
      if (id === null) {
        return;
      }
      state.medicoSelected = state.listaMedicos.find((el) => el.id === id);
    },
    cargarMonedaSelectedById(state, id) {
      const ITEM_MONEDA = state.listaMonedas.find((el) => el.id === id);
      state.monedaSelected = ITEM_MONEDA;
      if (state.monedaSelected.is_principal === 1) {
        return;
      }
      state.tipoCambio = state.monedaSelected.tc;
    },
    cargarValidezSelectedById(state, id) {
      if (id === null) {
        return;
      }
      state.validezSelected = state.listaValidez.find((el) => el.id === id);
    },
    predeterminarFormaPagoContado(state) {
      if (state.listaFormaPago.length > 0) {
        const FIRST_ITEM = state.listaFormaPago[0];
        state.formaPagoSelected = FIRST_ITEM;
      }
    },
    predeterminarValidez(state) {
      if (state.listaValidez.length > 1) {
        const SECOND_ITEM = state.listaValidez[1];
        state.validezSelected = SECOND_ITEM;
      }
    },
  },
  actions: {
    resetFormularioCotizacion({ commit }) {
      commit('setModoEdicion', false);
      commit('setShowCortinaInformativa', false);
      commit('setPacienteToShow', {
        anticipo: null,
        edad: '',
        ultima: '',
        cobertura: '',
        tratamiento: null,
      });
      commit('setCorrelativo', null);
      commit('setEstado', true);
      commit('setDatepicker', new Date());
      commit('setPacienteSelected', null);
      commit('setPacientes', []);
      commit('setIsLoadingComboPacientes', false);
      commit('setQueryTemporalPaciente', '');
      commit('setTemporaPageComboPacientes', 1);
      commit('setTotalSearchPacientes', 0);
      commit('setFormaPagoSelected', null);
      commit('setListaFormaPago', []);
      commit('setCheckSeguroEstado', false);
      commit('setContacto', '');
      commit('setValidezSelected', null);
      commit('setListaValidez', []);
      commit('setAseguradoraSelected', null);
      commit('setListaAseguradoras', []);
      commit('setMonedaSelected', null);
      commit('setListaMonedas', []);
      commit('setTipoCambio', '');
      commit('setMedicoSelected', null);
      commit('setListaMedicos', []);
    },
  },
};
