import Vue from 'vue';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
// eslint-disable-next-line import/prefer-default-export
export function setUser(user, empresa) {
  const userForSentry = {
    id: user.id,
    username: user.name,
    email: user.email,
    Empresa: `${empresa.tradename}(ID:${empresa.id})`,
  };
  Sentry.setUser(userForSentry);
  Sentry.setTag('empresa_id', empresa.id);
}
