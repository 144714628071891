import anticipoAPIS from './anticipoAPIS';
import listarAnticipos from './listarAnticipos';
import modalRegistro from './modalRegistro';

const anticipoStore = {
  anticipoAPIS,
  listarAnticipos,
  modalRegistro,
};

export default anticipoStore;
