import callIndexApi from './apis/listarCategoria';
import callDestroyApi from './apis/destroyCategoria';
import callStoreApi from './apis/storeCategoria';
import callEditApi from './apis/editCategoria';
import callUpdateApi from './apis/updateCategoria';

export default {
  namespaced: true,
  actions: {
    callIndexApi,
    callDestroyApi,
    callStoreApi,
    callEditApi,
    callUpdateApi,
  },
};
