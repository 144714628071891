export default {
  togglelistaTablas(state, data) {
    state.listaTabla = data.listaTabla;
    state.totalFilas = data.totalFilas;
    state.pagina_actual = data.pagina_actual;
  },
  toggleEstado(state, data) { state.estado = data; },
  togglePaginaActual(state, data) { state.pagina_actual = data; },
  toggleBusqueda(state, data) { state.busqueda = data; },
  togglePorPagina(state, data) { state.por_pagina = data; },
  toggleSortBy(state, data) { state.sortBy = data; },
  togglgeSortDesc(state, data) { state.sortDesc = data; },
};
