import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  filtrarBusqueda({ state, dispatch }) {
    const DELAY = 400;
    clearTimeout(state.delayTimeout);
    state.delayTimeout = setTimeout(() => { dispatch('listaTablas'); }, DELAY);
  },
  async listaTablas({ state, commit }) {
    try {
      const data = {
        params: {
          filter_key: state.busqueda,
          page: state.por_pagina,
          estado: state.estado ? 1 : 0,
          pagina_actual: state.pagina_actual,
        },
      };
      const response = await axios.get('/clinic/doctors/medico', data);
      const datas = {
        listaTabla: response.data.data.data,
        totalFilas: response.data.data.total,
        pagina_actual: response.data.data.current_page,
      };
      commit('togglelistaTablas', datas);
    } catch (error) {
      util.showNotify(error.response.data.message, 'error');
    }
  },
  async deleteItems({ dispatch }, id) {
    try {
      const response = await axios.delete(`/clinic/doctors/medico/${id}`);
      dispatch('listaTablas');
      util.showNotify(response.data.message, 'success');
    } catch (error) {
      util.showNotify(error.response.data.message, 'error');
      Object.entries(error.response.data.data).forEach(([, mensajes]) => {
        mensajes.forEach((texto) => util.showNotify(texto, 'error'));
      });
    }
  },
};
