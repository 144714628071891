import { hasPermision } from './utilidades';

export default {
  hasPermisionListarConsultaExterna(state) {
    const KEY = 'CONSUL_EXTER_LISTAR';
    const ACCION = 'listar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionModificarConsultaExterna(state) {
    const KEY = 'CONSUL_EXTER_EDITAR';
    const ACCION = 'modificar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionImprimirConsultaExterna(state) {
    const KEY = 'CONSUL_EXTER_IMPRIMIR';
    const ACCION = 'imprimir';
    return hasPermision(state, KEY, ACCION);
  },
};
