import moment from 'moment';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

/**
 * guarda las ventas en el sistema del facturador
 */
export default async function saveFacturaVenta({
  rootState,
  getters,
  rootGetters,
  dispatch,
}) {
  const Medico = rootGetters['formFactura/getMedicoSelected'];
  const CONFIG_FACTURA_ELECTRONICA_MEDICO = rootState.formFactura.configuracion_factura_electronica;
  const CONFIG_FACTURA_TERCERO_MEDICO = Medico ? Medico.configuracion_factura_tercero : null;
  try {
    let REQUEST = null;

    if (CONFIG_FACTURA_TERCERO_MEDICO === 0 && CONFIG_FACTURA_ELECTRONICA_MEDICO !== null) {
      // venta con medico
      let eventoSignificativo = '';
      if (rootState.formFactura.tipoEmisionSelected && rootState.formFactura.tipoEmisionSelected.cod_clasificador === 2 /* Fuera de Linea */) {
        eventoSignificativo = rootState.formFactura.tipoContingenciaSelected ? rootState.formFactura.tipoContingenciaSelected.id : '';
      }
      const siatPuntoVentaId = Medico.siat_punto_venta_id ?? '';
      REQUEST = {
        siat_docsector_id: CONFIG_FACTURA_ELECTRONICA_MEDICO.siat_documento_sector_id,
        siat_emision_id: rootState.formFactura.tipoEmisionSelected ? rootState.formFactura.tipoEmisionSelected.id : '',
        siat_eventosignificativo_id: eventoSignificativo,
        siat_puntoventa_id: siatPuntoVentaId,
        siat_puntoventaespecifica: 1,
        siat_sucursal_id: Medico.siat_sucursal_id,
        siat_tipofactura_id: CONFIG_FACTURA_ELECTRONICA_MEDICO.siat_documento_id,
        fecha_envio: moment(rootGetters['formFactura/getDatePicker']).format('YYYY-MM-DD H:m:s'),
        factura: await dispatch('getDataMedicoFacturaElectronica'),
        medico_id: Medico.id,
        origen: 2, // 1. ERP, 2: MEDICO

      };
    } else {
      let siatPuntoVenta = null;
      getters.getPuntoVentas.forEach((ele) => {
        if (ele.siat_sucursal_id === getters.getSucursal.siat_sucursal_id) {
          siatPuntoVenta = ele;
        }
      });

      let eventoSignificativo = '';
      if (rootState.formFactura.tipoEmisionSelected && rootState.formFactura.tipoEmisionSelected.cod_clasificador === 2 /* Fuera de Linea */) {
        eventoSignificativo = rootState.formFactura.tipoContingenciaSelected ? rootState.formFactura.tipoContingenciaSelected.id : '';
      }
      REQUEST = {
        siat_docsector_id: rootState.addServiceModal.documentoSectorId,
        siat_emision_id: rootState.formFactura.tipoEmisionSelected ? rootState.formFactura.tipoEmisionSelected.id : '',
        siat_eventosignificativo_id: eventoSignificativo,
        siat_puntoventa_id: siatPuntoVenta ? siatPuntoVenta.siat_tipo_punto_venta_id : '',
        siat_puntoventaespecifica: 1,
        siat_sucursal_id: getters.getSucursal.siat_sucursal_id,
        siat_tipofactura_id: rootState.addServiceModal.siatTipoFacturaId,
        fecha_envio: moment(rootGetters['formFactura/getDatePicker']).format('YYYY-MM-DD H:m:s'),
        factura: await dispatch('getDataFacturaElectronica'),
        medico_id: null,
        origen: 1, // 1. ERP, 2: MEDICO

      };
    }
    const response = await axios.post('/synFactura/facturation/store', REQUEST);
    return {
      STORE_OK: true,
      data: response.data,
    };
  } catch (error) {
    const FALLO_VALIDACION = 422;
    if (error.response.status !== FALLO_VALIDACION) {
      util.showNotify(error.response.data.message, 'error');
    } else {
      Object.entries(error.response.data.data).forEach(([, mensajes]) => {
        mensajes.forEach((texto) => util.showNotify(texto, 'error'));
      });
    }
    return {
      STORE_OK: false,
      data: null,
    };
  }
}
