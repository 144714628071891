import moment from 'moment';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function callUpdateApi({ rootState, rootGetters }, id) {
  const PACIENTE = rootState.formularioCotizacion.pacienteSelected;
  const PACIENTE_ID = PACIENTE === null ? null : PACIENTE.id;
  const FORMA_PAGO = rootState.formularioCotizacion.formaPagoSelected;
  const FORMA_PAGO_ID = FORMA_PAGO === null ? null : FORMA_PAGO.id;
  const VALIDEZ = rootState.formularioCotizacion.validezSelected;
  const VALIDEZ_ID = VALIDEZ === null ? null : VALIDEZ.id;
  const ASEGURADORA = rootState.formularioCotizacion.aseguradoraSelected;
  const ASEGURADORA_ID = ASEGURADORA === null ? null : ASEGURADORA.id;
  const MONEDA = rootState.formularioCotizacion.monedaSelected;
  const MONEDA_ID = MONEDA === null ? null : MONEDA.id;
  const MEDICO = rootState.formularioCotizacion.medicoSelected;
  const MEDICO_ID = MEDICO === null ? null : MEDICO.id;
  const REQUEST = {
    paciente_id: PACIENTE_ID,
    contacto: rootState.formularioCotizacion.contacto,
    forma_pago_id: FORMA_PAGO_ID,
    validez_id: VALIDEZ_ID,
    seguro_medico: rootState.formularioCotizacion.checkSeguroEstado,
    aseguradora_id: ASEGURADORA_ID,
    factura: rootState.formularioCotizacion.estado,
    fecha: moment(rootState.formularioCotizacion.datepicker).format('YYYY-MM-DD'),
    moneda_id: MONEDA_ID,
    medico_id: MEDICO_ID,
    sub_total: rootGetters['tablaServicios/subTotal'],
    total: rootGetters['tablaServicios/Total'],
    total_seguro: rootGetters['tablaServicios/totalSeguro'],
    glosa: rootState.glosa.glosa,
    detalles: rootState.tablaServicios.listaServicios,
    actividad_economica_id: rootState.modalServicios.actividadEconomicaId,
  };
  try {
    const RESPONSE = await axios.put(`/clinic/quotation/${id}`, REQUEST);
    util.showNotify(RESPONSE.data.message, 'success');
    return true;
  } catch (error) {
    const FALLO_VALIDACION = 422;
    if (error.response.status !== FALLO_VALIDACION) {
      util.showNotify(error.response.data.message, 'error');
    } else {
      Object.entries(error.response.data.data).forEach(([, mensajes]) => {
        mensajes.forEach((texto) => util.showNotify(texto, 'error'));
      });
    }
    return false;
  }
}
