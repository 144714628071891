import { hastipoUserMedico } from './utilidades';

export default {
  hasTipoUser(state) {
    return hastipoUserMedico(state);
  },
  medico_id(state) {
    return state.user.medico_id;
  },
};
