import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  async listarMedicos({ state, commit }) {
    try {
      const data = {
        params: {
          filter_key: state.busqueda,
          page: state.paginaActual,
          estado: state.estado,
          per_page: state.porPagina,
        },
      };
      const response = await axios.get('/clinic/consultation/doctor', data);
      const datas = {
        listaTabla: response.data.data.data,
        totalFilas: response.data.data.total,
        paginaActual: response.data.data.per_page,
      };
      commit('setListarMedicos', datas);
    } catch (error) {
      util.showNotify(error.response.data.message, 'error');
    }
  },
  resetStateConsultaExterna({ commit }) {
    const datas = {
      listaTabla: [],
      totalFilas: null,
      paginaActual: 1,
    };
    commit('setListarMedicos', datas);
  },
};
