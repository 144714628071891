export default () => ({
  stateSelected: 1,
  pacientes: [],
  medico: null,
  porPagina: 50,
  paginaActual: 1,
  totalFilas: null,
  paginaOpciones: [50, 100],
  busqueda: null,
  estado: true,
  listaPacienteAtendidos: [],

  pacientesInConsultaExternas: [],
  pacientesInConsultaExternaSelected: null,
});
