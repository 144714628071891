import { getField, updateField } from 'vuex-map-fields';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  namespaced: true,
  state: {
    reconsulta: null,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setReconsulta: (state, data) => { state.reconsulta = data; },
  },
  actions: {
    async getDataReconsultaTratamiento({ commit }, consulta) {
      const RECONSULTA_ID = consulta ? consulta.id : null;
      try {
        const response = await axios.get(`/clinic/consultation/patient/reconsulta/${RECONSULTA_ID}`);
        commit('setReconsulta', response.data.data);
        return true;
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
        return false;
      }
    },
  },
};
