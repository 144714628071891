import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function callDestroyApi({ commit, rootState }, ID) {
  try {
    const OLD_LIST = rootState.formularioClasificacion.listarTabla;
    const UPDATED_LIST = OLD_LIST.filter((item) => (
      item.id !== ID
    ));
    const RESPONSE = await axios.delete(`clinic/parametros/clasificacion_cie/${ID}`);
    util.showNotify(RESPONSE.data.message, 'success');
    commit('formularioClasificacion/setListarTabla', UPDATED_LIST, { root: true });
    return true;
  } catch (error) {
    util.showNotify(error.response.data.message, 'error');
    return false;
  }
}
