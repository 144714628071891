/* eslint-disable */
import Vue from 'vue'
var loader = null;
var _fullPage = false;
export const util = {
    loading: {
        fullPage: false
    },
    showSpinnerLoading (fullPage = false) {
        _fullPage = fullPage;
        if (_fullPage) {
            loader = Vue.$loading.show();
        } else {
            this.loading.fullPage = true;
        }
    },
    hideSpinnerLoading () {
        if (_fullPage) {
            if (loader) loader.hide();
        } else {
            if (loader) loader.hide();
            this.loading.fullPage = false;
        }

    },
    showErrorsValidation (errors) {
        for (let key in errors) {
            errors[key].forEach(error => {
                this.showNotify(error, 'error');
            })
        }
    },
    showNotify (text, type = 'success', title = "") {
        if (typeof text === 'object') {
            let data = text;
            text = data.MESSAGE;
            type = data.TYPE_NOTIFY;
            title = data.title ? data.title : '';
        }
        let time = 3000;   //  si es necesario x tipo podemos configurar su tiempo duration en notificar (X EL MOMENTO NC STA UTIALIZANDO)
        switch (type) {
            case 'success':
                title = (title == "") ? 'Satisfactorio' : title;
                break;
            case 'warn':
                title = (title == "") ? 'Advertencia' : title;
                break;
            case 'error':
                title = (title == "") ? 'Error!' : title;
                time = 10000;
                break;
            case 'info':
                title = (title == "") ? 'Información' : title;
                break;
            default:
                console.log('No existe tipo de notificacion!!');
        }
        if (title != "") {
            Vue.notify({
                group: 'foo',
                title,
                text,
                type,
                duration: time,
            });
        }
    }
};
