import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    tipoActividadFisicas: [],
    tipoActividadFisicaSelected: null,
    tipoAlcohos: [],
    tipoAlcohosSelected: null,
    tipoCirugias: [],
    tipoDietas: [],
    tipoDietaSelected: null,
    tipoMedicamentos: [],
    tipoMedicamentoSelected: null,
    tipoOcupacions: [],
    tipoOcupacionSelected: null,
    tipoPatologias: [],
    tipoPsicosocials: [],
    tipoPsicosocialSelected: null,
    tipoTabacos: [],
    tipoTabacoSelected: null,
    tipoViviendas: [],
    tipoViviendaSelected: null,
    fechaInicio: new Date(),
    fechaInicio2: new Date(),
    descripcionOtros: null,
    alergias: null,
    cardiovasculares: null,
    patologias: [],
    cirugias: [],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    settipoActividadFisicas: (state, data) => { state.tipoActividadFisicas = data; },
    setTipoActividadFisicaSelected: (state, data) => { state.tipoActividadFisicaSelected = data; },
    setTipoAlcohos: (state, data) => { state.tipoAlcohos = data; },
    setTipoAlcohosSelected: (state, data) => { state.tipoAlcohosSelected = data; },
    setTipoCirugias: (state, data) => { state.tipoCirugias = data; },
    setTipoDietas: (state, data) => { state.tipoDietas = data; },
    setTipoDietaSelected: (state, data) => { state.tipoDietaSelected = data; },
    setTipoMedicamentos: (state, data) => { state.tipoMedicamentos = data; },
    setTipoMedicamentoSelected: (state, data) => { state.tipoMedicamentoSelected = data; },
    setTipoOcupacions: (state, data) => { state.tipoOcupacions = data; },
    setTipoOcupacionSelected: (state, data) => { state.tipoOcupacionSelected = data; },
    setTipoPatologias: (state, data) => { state.tipoPatologias = data; },
    setTipoPsicosocials: (state, data) => { state.tipoPsicosocials = data; },
    setTipoPsicosocialSelected: (state, data) => { state.tipoPsicosocialSelected = data; },
    setTipoTabacos: (state, data) => { state.tipoTabacos = data; },
    setTipoTabacoSelected: (state, data) => { state.tipoTabacoSelected = data; },
    setTipoViviendas: (state, data) => { state.tipoViviendas = data; },
    setTipoViviendaSelected: (state, data) => { state.tipoViviendaSelected = data; },
    setFechaInicio: (state, data) => { state.fechaInicio = data; },
    setFechaInicio2: (state, data) => { state.fechaInicio2 = data; },
    setCardiovasculares: (state, data) => { state.cardiovasculares = data; },
    setDescripcionOtros: (state, data) => { state.descripcionOtros = data; },
    setAlergias: (state, data) => { state.alergias = data; },
    setPatologias: (state, data) => { state.patologias = data; },
    setCirugias: (state, data) => { state.cirugias = data; },
  },
  actions: {
    resetTheModalAntecedente({ commit }) {
      commit('settipoActividadFisicas', []);
      commit('setTipoActividadFisicaSelected', null);
      commit('setTipoAlcohos', []);
      commit('setTipoAlcohosSelected', null);
      commit('setTipoCirugias', []);
      commit('setTipoDietas', []);
      commit('setTipoDietaSelected', null);
      commit('setTipoMedicamentos', []);
      commit('setTipoMedicamentoSelected', null);
      commit('setTipoOcupacions', []);
      commit('setTipoOcupacionSelected', null);
      commit('setTipoPatologias', []);
      commit('setTipoPsicosocials', []);
      commit('setTipoPsicosocialSelected', null);
      commit('setTipoTabacos', []);
      commit('setTipoTabacoSelected', null);
      commit('setTipoViviendas', []);
      commit('setTipoViviendaSelected', null);
      commit('setFechaInicio', new Date());
      commit('setFechaInicio2', new Date());
      commit('setCardiovasculares', null);
      commit('setDescripcionOtros', null);
      commit('setAlergias', null);
      commit('setPatologias', []);
      commit('setCirugias', []);
    },
  },
};
