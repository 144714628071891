import axios from 'axios';

const instancia = axios.create({
  baseURL: process.env.VUE_APP_ERP_API,
});
instancia.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('user-token')}`;

instancia.interceptors.response.use((response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 503) {
        window.location = '/503';
      }
      if (error.response.status === 401) {
        localStorage.removeItem('user-token');
        window.location = '/login';
      }
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }
    return Promise.reject(error);
  });

export default instancia;
