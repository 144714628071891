import { updateField } from 'vuex-map-fields';

export default {
  updateField,
  setListarMedicos(state, data) {
    state.medicos = data.listaTabla;
    state.totalFilas = data.totalFilas;
    state.pagina_actual = data.paginaActual;
  },
  toggleEstado(state, data) { state.estado = data; },
  togglePaginaActual(state, data) { state.paginaActual = data; },
  toggleBusqueda(state, data) { state.busqueda = data; },
  togglePorPagina(state, data) { state.porPagina = data; },
};
