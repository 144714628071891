import store from '@/store/index';
import { redirectIfHasPermission } from '@/store/modules/privilegios/utilidades';

const title = 'Dashboard';
const dashboardRoutes = [
  {
    path: 'dashboard',
    name: 'dashboard-index',
    component: () => import('../views/index.vue'),
    meta: {
      title,
    },
    async beforeEnter(to, from, next) {
      const PERMISO = store.getters['main/hasPermisionListarDashboard'];
      redirectIfHasPermission(PERMISO, next);
    },
  },
];
export default dashboardRoutes;
