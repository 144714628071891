import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function obtenerPreciosDelServicio({ commit, rootState }) {
  commit('modalServicios/setIsDisabledAddButton', true, { root: true });
  let medicoID = null;
  if (rootState.formularioCotizacion.medicoSelected !== null) {
    medicoID = rootState.formularioCotizacion.medicoSelected.id;
  }
  let aseguradoraID = null;
  if (rootState.formularioCotizacion.aseguradoraSelected !== null) {
    aseguradoraID = rootState.formularioCotizacion.aseguradoraSelected.id;
  }
  const INDICE_ULTIMO_SERVICIO = rootState.modalServicios.listaTemporal.length - 1;
  const DEFAULT_COMPUTARIZADA = 1;
  const REQUEST = {
    servicio_id: rootState.modalServicios.listaTemporal[INDICE_ULTIMO_SERVICIO].id,
    is_factura: rootState.formularioCotizacion.estado,
    tipo_factura: DEFAULT_COMPUTARIZADA,
    factura_tercero: false,
    medico_id: medicoID,
    seguro_medico: rootState.formularioCotizacion.checkSeguroEstado,
    aseguradora_id: aseguradoraID,
  };
  try {
    const RESPONSE = await axios.get('/clinic/invoicing/service/prices', {
      params: REQUEST,
    });
    const { impuestos } = RESPONSE.data.data;
    const { precios } = RESPONSE.data.data;
    const { servicio } = RESPONSE.data.data;
    const SEGURO_SERVICIO = RESPONSE.data.data.seguro_servicio;
    /* const TIPO_IMPUESTO = RESPONSE.data.data.tipo_impuesto; */
    commit('modalServicios/setImpuestos', impuestos, { root: true });
    const PRECIOS_CONFIG = {
      precios,
      seguroServicio: SEGURO_SERVICIO,
      monedas: rootState.formularioCotizacion.listaMonedas,
      monedaSelectedId: rootState.formularioCotizacion.monedaSelected.id,
      tipoMoneda: rootState.formularioCotizacion.monedaSelected.is_principal,
      monedaServicioID: servicio.moneda_id,
      tipoCambio: rootState.formularioCotizacion.tipoCambio,
    };
    commit('modalServicios/setPrecioTipoCambio', PRECIOS_CONFIG, { root: true });
    commit('modalServicios/setPrecioSelected', precios[0], { root: true });
    commit('modalServicios/setPrecioSelectedCombo', precios[0].monto, { root: true });
    const precioUnitario = parseFloat(precios[0].monto).toFixed(2);
    const itemServicio = {
      id: 0,
      tempId: new Date().getTime(),
      numero: servicio.numero,
      tipo_servicio_id: servicio.id,
      descripcion: servicio.descripcion,
      medicoTempSelected: null,
      cantidad: 1,
      precio_unitario: parseFloat(precios[0].monto).toFixed(2),
      sub_total: precioUnitario,
      servicio: servicio.nombre,
      /* desc: '-', */
      sub_total_seguro: '0.00',
      estado: 1,
    };
    commit('modalServicios/setItemListaDetalleTemporal', itemServicio, { root: true });
    commit('modalServicios/setActividadEconomicaId', servicio.actividad_economica_id, { root: true });
    commit('modalServicios/setIsDisabledAddButton', false, { root: true });
  } catch (error) {
    util.showNotify(error.response.data.message, 'error');
  }
}
