import { getField, updateField } from 'vuex-map-fields';

const ESTADO_INICIAL = {
  listaTratamientos: [],
};

export default {
  namespaced: true,
  state: { ...ESTADO_INICIAL },
  getters: {
    getField,
    getIdItemChecked(state) {
      const ITEM = state.listaTratamientos.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return '';
      }
      return ITEM.id;
    },
    getNumeroItemChecked(state) {
      const ITEM = state.listaTratamientos.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return '';
      }
      return ITEM.numero;
    },
    getTotalItemChecked(state) {
      const ITEM = state.listaTratamientos.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return 0;
      }
      return ITEM.total;
    },
    getAnticipoItemChecked(state) {
      const ITEM = state.listaTratamientos.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return 0;
      }
      return ITEM.anticipo;
    },
    getSaldoItemChecked(state) {
      const ITEM = state.listaTratamientos.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return 0;
      }
      return ITEM.saldo;
    },
    getGlosaPreCierreItemChecked(state) {
      const ITEM = state.listaTratamientos.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return '';
      }
      return ITEM.glosa_precierre;
    },
    getGlosaCierreItemChecked(state) {
      const ITEM = state.listaTratamientos.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return '';
      }
      return ITEM.glosa_cierre;
    },
    getUserPreCierreItemChecked(state) {
      const ITEM = state.listaTratamientos.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return '';
      }
      return ITEM.user_precierre;
    },
    getUserCierreItemChecked(state) {
      const ITEM = state.listaTratamientos.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return '';
      }
      return ITEM.user_cierre;
    },
  },
  mutations: {
    updateField,
    cargarListaTratamientos(state, data) {
      const TEMP_ARRAY = [];
      data.forEach((el) => {
        const ITEM = {
          anticipo: el.anticipo,
          estado: el.estado,
          fecha_cierre: el.fecha_cierre,
          fecha_registro: el.fecha_registro,
          glosa_cierre: el.glosa_cierre,
          glosa_precierre: el.glosa_precierre,
          id: el.id,
          numero: el.numero,
          saldo: el.saldo,
          total: el.total,
          user_cierre: el.user_cierre,
          user_precierre: el.user_precierre,
          checkInput: false,
        };
        TEMP_ARRAY.push(ITEM);
      });
      state.listaTratamientos = TEMP_ARRAY;
    },
  },
  actions: {
    resetModalTratamientoEnCurso({ commit }) {
      Object.entries(ESTADO_INICIAL).forEach(([key, value]) => {
        commit('updateField', { path: key, value });
      });
    },
  },
};
