import { hasPermision } from './utilidades';

export default {
  hasPermisionListarSeguro(state) {
    const KEY = 'SEG_LISTAR';
    const ACCION = 'listar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionNuevoSeguro(state) {
    const KEY = 'SEG_NUEVO';
    const ACCION = 'nuevo';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionModificarSeguro(state) {
    const KEY = 'SEG_EDITAR';
    const ACCION = 'modificar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionVerSeguro(state) {
    const KEY = 'SEG_VER';
    const ACCION = 'ver';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionEliminarSeguro(state) {
    const KEY = 'SEG_ELIMINAR';
    const ACCION = 'eliminar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionImprimirSeguro(state) {
    const KEY = 'SEG_IMPRIMIR';
    const ACCION = 'imprimir';
    return hasPermision(state, KEY, ACCION);
  },
};
