import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function callStoreApi({ commit, rootState }) {
  try {
    const NOMBRE_CLASIFICACION = rootState.formularioClasificacion.nombreClasificacion;
    const CODIGO_CLASIFICACION = rootState.formularioClasificacion.codigoClasificacion;
    const LISTA_SUB_CLASIFICACION = rootState.formularioClasificacion.listaSubClasificacions;
    const REQUEST = {
      nombre: NOMBRE_CLASIFICACION,
      codigo: CODIGO_CLASIFICACION,
      subclasificacions: LISTA_SUB_CLASIFICACION,
    };
    const RESPONSE = await axios.post('/clinic/parametros/clasificacion_cie/store', REQUEST);
    util.showNotify(RESPONSE.data.message, 'success');
    commit('formularioClasificacion/setComponentShow', 'TheIndexClasificacionCie', { root: true });
    return true;
  } catch (error) {
    const FALLO_VALIDACION = 422;
    if (error.response.status !== FALLO_VALIDACION) {
      util.showNotify(error.response.data.message, 'error');
    } else {
      Object.entries(error.response.data.data).forEach(([, mensajes]) => {
        mensajes.forEach((texto) => util.showNotify(texto, 'error'));
      });
    }
    return false;
  }
}
