import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  namespaced: true,
  actions: {
    async callIndexAPI({ commit, rootState }) {
      commit('listarAnticipos/setIsLoading', true, { root: true });
      try {
        const REQUEST = {
          estado: (rootState.listarAnticipos.estado) ? 1 : 0,
          filter_key: rootState.listarAnticipos.busqueda,
          current_page: rootState.listarAnticipos.pagina_actual,
          per_page: rootState.listarAnticipos.por_pagina,
        };
        const RESPONSE = await axios.get('/clinic/advance', {
          params: REQUEST,
        });
        const TOTAL_FILAS = RESPONSE.data.data.total;
        const LISTA = RESPONSE.data.data.data;
        commit('listarAnticipos/setTotalFilas', TOTAL_FILAS, { root: true });
        commit('listarAnticipos/setListaTabla', LISTA, { root: true });
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        commit('listarAnticipos/setIsLoading', false, { root: true });
      }
    },
    async callCreateAPI({ commit }) {
      try {
        const RESPONSE = await axios.get('/clinic/advance/create');
        const { tesorerias } = RESPONSE.data.data;
        const { pacientes } = RESPONSE.data.data;
        commit('modalRegistro/setTesorerias', tesorerias, { root: true });
        commit('modalRegistro/setPacientes', pacientes, { root: true });
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async callStoreAPI({ rootState }) {
      const REQUEST = new FormData();
      let pacienteId = null;
      if (rootState.modalRegistro.pacienteSelected !== null) {
        pacienteId = rootState.modalRegistro.pacienteSelected.id;
      }
      REQUEST.append('paciente_id', pacienteId);
      REQUEST.append('descripcion', rootState.modalRegistro.descripcion);
      REQUEST.append('total', rootState.modalRegistro.totalTratamiento);
      if (rootState.modalRegistro.tesoreriaSelected1 !== null) {
        REQUEST.append('tesoreria[0][tesoreria_id]', rootState.modalRegistro.tesoreriaSelected1.id);
        REQUEST.append('tesoreria[0][cuenta_contable_id]', rootState.modalRegistro.tesoreriaSelected1.cuenta_contable_id);
        REQUEST.append('tesoreria[0][monto]', rootState.modalRegistro.monto1);
        REQUEST.append('tesoreria[0][tipo_id]', rootState.modalRegistro.tesoreriaSelected1.tipo_id);
      }
      if (rootState.modalRegistro.tesoreriaSelected2 !== null) {
        REQUEST.append('tesoreria[1][tesoreria_id]', rootState.modalRegistro.tesoreriaSelected2.id);
        REQUEST.append('tesoreria[1][cuenta_contable_id]', rootState.modalRegistro.tesoreriaSelected2.cuenta_contable_id);
        REQUEST.append('tesoreria[1][monto]', rootState.modalRegistro.monto2);
        REQUEST.append('tesoreria[1][tipo_id]', rootState.modalRegistro.tesoreriaSelected2.tipo_id);
      }
      if (rootState.modalRegistro.tesoreriaSelected3 !== null) {
        REQUEST.append('tesoreria[2][tesoreria_id]', rootState.modalRegistro.tesoreriaSelected3.id);
        REQUEST.append('tesoreria[2][cuenta_contable_id]', rootState.modalRegistro.tesoreriaSelected3.cuenta_contable_id);
        REQUEST.append('tesoreria[2][monto]', rootState.modalRegistro.monto3);
        REQUEST.append('tesoreria[2][tipo_id]', rootState.modalRegistro.tesoreriaSelected3.tipo_id);
      }
      try {
        const response = await axios.post('/clinic/advance', REQUEST);
        util.showNotify(response.data.message, 'success');
        return true;
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
        return false;
      }
    },
    async callEditApi({ commit }, ID) {
      try {
        const RESPONSE = await axios.get(`/clinic/advance/${ID}/edit`);
        if (Object.prototype.hasOwnProperty.call(RESPONSE.data.data, 'comprobante')) {
          const LISTA = RESPONSE.data.data.comprobante;
          commit('listarAnticipos/setListaComprobantes', LISTA, { root: true });
        } else {
          const { anticipo } = RESPONSE.data.data;
          const { pacientes } = RESPONSE.data.data;
          const { tesorerias } = RESPONSE.data.data;
          const LISTA_ANTICIPO = JSON.parse(anticipo[0].tesoreria);
          commit('modalRegistro/setResponseEdit', LISTA_ANTICIPO, { root: true });
          commit('modalRegistro/setId', anticipo[0].id, { root: true });
          commit('modalRegistro/setTesorerias', tesorerias, { root: true });
          commit('modalRegistro/setPacientes', pacientes, { root: true });
          commit('modalRegistro/cargarPacienteSelected', anticipo[0].paciente_id, { root: true });
          commit('modalRegistro/setDescripcion', anticipo[0].descripcion, { root: true });
          commit('modalRegistro/setTotalTratamiento', anticipo[0].total, { root: true });
          commit('modalRegistro/cargarAnticiposFromEdit', LISTA_ANTICIPO, { root: true });
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async callUpdateApi({ rootState }) {
      const REQUEST = new FormData();
      let pacienteId = null;
      if (rootState.modalRegistro.pacienteSelected !== null) {
        pacienteId = rootState.modalRegistro.pacienteSelected.id;
      }
      const ID = rootState.modalRegistro.id;
      REQUEST.append('id', ID);
      REQUEST.append('paciente_id', pacienteId);
      REQUEST.append('descripcion', rootState.modalRegistro.descripcion);
      REQUEST.append('total', rootState.modalRegistro.totalTratamiento);
      const EDIT_RESPONSE = rootState.modalRegistro.responseEdit;
      if (EDIT_RESPONSE.length > rootState.modalRegistro.tesoreriasAUtilizar) {
        rootState.modalRegistro.responseEdit.forEach((item, index) => {
          const cuentaContable = rootState.modalRegistro.tesorerias.find((el) => el.id === item.tesoreria_id);
          if (index === 0) {
            if (rootState.modalRegistro.tesoreriaSelected1 !== null) {
              REQUEST.append('tesoreria[0][id]', rootState.modalRegistro.tesoreriaID1);
              REQUEST.append('tesoreria[0][tesoreria_id]', rootState.modalRegistro.tesoreriaSelected1.id);
              REQUEST.append('tesoreria[0][cuenta_contable_id]', rootState.modalRegistro.tesoreriaSelected1.cuenta_contable_id);
              REQUEST.append('tesoreria[0][monto]', rootState.modalRegistro.monto1);
              REQUEST.append('tesoreria[0][estado]', 1);
              REQUEST.append('tesoreria[0][tipo_id]', rootState.modalRegistro.tesoreriaSelected1.tipo_id);
            }
          }
          if (index === 1) {
            if (rootState.modalRegistro.tesoreriaSelected2 !== null) {
              REQUEST.append('tesoreria[1][id]', rootState.modalRegistro.tesoreriaID2);
              REQUEST.append('tesoreria[1][tesoreria_id]', rootState.modalRegistro.tesoreriaSelected2.id);
              REQUEST.append('tesoreria[1][cuenta_contable_id]', rootState.modalRegistro.tesoreriaSelected2.cuenta_contable_id);
              REQUEST.append('tesoreria[1][monto]', rootState.modalRegistro.monto2);
              REQUEST.append('tesoreria[1][estado]', 1);
              REQUEST.append('tesoreria[1][tipo_id]', rootState.modalRegistro.tesoreriaSelected2.tipo_id);
            } else {
              REQUEST.append('tesoreria[1][id]', item.id);
              REQUEST.append('tesoreria[1][tesoreria_id]', item.tesoreria_id);
              REQUEST.append('tesoreria[1][cuenta_contable_id]', cuentaContable.cuenta_contable_id);
              REQUEST.append('tesoreria[1][monto]', item.monto);
              REQUEST.append('tesoreria[1][estado]', 0);
              REQUEST.append('tesoreria[1][tipo_id]', cuentaContable.tipo_id);
            }
          }
          if (index === 2) {
            if (rootState.modalRegistro.tesoreriaSelected3 !== null) {
              REQUEST.append('tesoreria[2][id]', rootState.modalRegistro.tesoreriaID3);
              REQUEST.append('tesoreria[2][tesoreria_id]', rootState.modalRegistro.tesoreriaSelected3.id);
              REQUEST.append('tesoreria[2][cuenta_contable_id]', rootState.modalRegistro.tesoreriaSelected3.cuenta_contable_id);
              REQUEST.append('tesoreria[2][monto]', rootState.modalRegistro.monto3);
              REQUEST.append('tesoreria[2][estado]', 1);
              REQUEST.append('tesoreria[2][tipo_id]', rootState.modalRegistro.tesoreriaSelected3.tipo_id);
            } else {
              REQUEST.append('tesoreria[2][id]', item.id);
              REQUEST.append('tesoreria[2][tesoreria_id]', item.tesoreria_id);
              REQUEST.append('tesoreria[2][cuenta_contable_id]', cuentaContable.cuenta_contable_id);
              REQUEST.append('tesoreria[2][monto]', item.monto);
              REQUEST.append('tesoreria[2][estado]', 0);
              REQUEST.append('tesoreria[2][tipo_id]', cuentaContable.tipo_id);
            }
          }
        });
      } else {
        for (let index = 0; index < rootState.modalRegistro.tesoreriasAUtilizar; index += 1) {
          if (index === 0) {
            if (rootState.modalRegistro.tesoreriaSelected1 !== null) {
              REQUEST.append('tesoreria[0][id]', rootState.modalRegistro.tesoreriaID1);
              REQUEST.append('tesoreria[0][tesoreria_id]', rootState.modalRegistro.tesoreriaSelected1.id);
              REQUEST.append('tesoreria[0][cuenta_contable_id]', rootState.modalRegistro.tesoreriaSelected1.cuenta_contable_id);
              REQUEST.append('tesoreria[0][monto]', rootState.modalRegistro.monto1);
              REQUEST.append('tesoreria[0][estado]', 1);
              REQUEST.append('tesoreria[0][tipo_id]', rootState.modalRegistro.tesoreriaSelected1.tipo_id);
            }
          }
          if (index === 1) {
            if (rootState.modalRegistro.tesoreriaSelected2 !== null) {
              REQUEST.append('tesoreria[1][id]', rootState.modalRegistro.tesoreriaID2);
              REQUEST.append('tesoreria[1][tesoreria_id]', rootState.modalRegistro.tesoreriaSelected2.id);
              REQUEST.append('tesoreria[1][cuenta_contable_id]', rootState.modalRegistro.tesoreriaSelected2.cuenta_contable_id);
              REQUEST.append('tesoreria[1][monto]', rootState.modalRegistro.monto2);
              REQUEST.append('tesoreria[1][estado]', 1);
              REQUEST.append('tesoreria[1][tipo_id]', rootState.modalRegistro.tesoreriaSelected2.tipo_id);
            }
          }
          if (index === 2) {
            if (rootState.modalRegistro.tesoreriaSelected3 !== null) {
              REQUEST.append('tesoreria[2][id]', rootState.modalRegistro.tesoreriaID3);
              REQUEST.append('tesoreria[2][tesoreria_id]', rootState.modalRegistro.tesoreriaSelected3.id);
              REQUEST.append('tesoreria[2][cuenta_contable_id]', rootState.modalRegistro.tesoreriaSelected3.cuenta_contable_id);
              REQUEST.append('tesoreria[2][monto]', rootState.modalRegistro.monto3);
              REQUEST.append('tesoreria[2][estado]', 1);
              REQUEST.append('tesoreria[2][tipo_id]', rootState.modalRegistro.tesoreriaSelected3.tipo_id);
            }
          }
        }
      }
      try {
        const RESPONSE = await axios.post(`/clinic/advance/${ID}`, REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        return true;
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
        return false;
      }
    },
    async callDestroyAPI({ commit, rootState, dispatch }, ID) {
      try {
        const OLD_LIST = rootState.listarAnticipos.listaTabla;
        const UPDATED_LIST = OLD_LIST.filter((item) => (
          item.id !== ID
        ));
        commit('listarAnticipos/setListaTabla', UPDATED_LIST, { root: true });
        const RESPONSE = await axios.delete(`/clinic/advance/${ID}`);
        if (Array.isArray(RESPONSE.data.data)) {
          util.showNotify(RESPONSE.data.message, 'success');
        } else {
          commit('listarAnticipos/setListaTabla', OLD_LIST, { root: true });
          const LISTA = RESPONSE.data.data.comprobante;
          commit('listarAnticipos/setListaComprobantes', LISTA, { root: true });
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        dispatch('callIndexAPI');
      }
    },
  },
};
