import { hasPermision } from './utilidades';

export default {
  hasPermisionListarMedico(state) {
    const KEY = 'MED_LISTAR';
    const ACCION = 'listar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionNuevoMedico(state) {
    const KEY = 'MED_NUEVO';
    const ACCION = 'nuevo';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionModificarMedico(state) {
    const KEY = 'MED_EDITAR';
    const ACCION = 'modificar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionVerMedico(state) {
    const KEY = 'MED_VER';
    const ACCION = 'ver';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionEliminarMedico(state) {
    const KEY = 'MED_ELIMINAR';
    const ACCION = 'eliminar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionImprimirMedico(state) {
    const KEY = 'MED_IMPRIMIR';
    const ACCION = 'imprimir';
    return hasPermision(state, KEY, ACCION);
  },
};
