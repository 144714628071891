import callCreateApi from './apis/create';
import callStoreApi from './apis/store';
import callEditApi from './apis/edit';
import callUpdateApi from './apis/update';
import obtenerListaPacientes from './apis/obtenerPacientesEnCombo';
import getDatosPacienteSelected from './apis/obtenerDatosDelPaciente';
import obtenerServicios from './apis/obtenerServicios';
import obtenerPreciosDelServicio from './apis/obtenerPreciosDelServicio';
import getDatosPacienteEditable from './apis/obtenerDatosDelPacienteFromEdit';

export default {
  namespaced: true,
  actions: {
    callCreateApi,
    callStoreApi,
    callEditApi,
    callUpdateApi,
    obtenerListaPacientes,
    getDatosPacienteSelected,
    obtenerServicios,
    obtenerPreciosDelServicio,
    getDatosPacienteEditable,
  },
};
