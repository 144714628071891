import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    tipoSeguroSelected: null,
  },
  getters: {
    getField,
    getTipoSeguroSelected: (state) => state.tipoSeguroSelected,
    tipoSeguroisConjunto(state) {
      if (state.tipoSeguroSelected != null) {
        if (state.tipoSeguroSelected.id === 2 /* 2 => conjunto */) {
          return true;
        }
      }
      return false;
    },
  },
  mutations: {
    updateField,
    setTipoSeguroSelected: (state, data) => { state.tipoSeguroSelected = data; },
  },
  actions: {
    resetMainFormState({ commit }) {
      commit('setTipoSeguroSelected', null);
    },
  },
};
