export default () => ({
  tipoViaAdministracions: [],
  tipoViaAdministracionSelected: null,
  almacenes: [],
  almacenSelected: null,
  fecha: new Date(),
  monedas: [],
  monedaPrincipal: null,
  productos: [],
  productoSelected: null,
  filterProducto: null,
  temporaPageComboProducto: 0,
  totalRegistroProducto: 0,
  stockSaldoUp: 0,
  stockSaldoUa: 0,
  unidad: false,
  stockLotes: [],
  clasificacionCies: [],
  diagnostico1Selected: null,
  diagnostico2Selected: null,
  diagnostico3Selected: null,
  diagnostico4Selected: null,
  nombreDiagnostico: 'S/N',

  tratamiento: null,
  examenFisico: null,
  enfermedadActual: null,
  motivoConsulta: null,
  descripcionDiagnostico1: null,
  descripcionDiagnostico2: null,
  descripcionDiagnostico3: null,
  descripcionDiagnostico4: null,
  subclasificacion_cie1_id: null,
  subclasificacion_cie2_id: null,
  subclasificacion_cie3_id: null,
  subclasificacion_cie4_id: null,
  consulta: null,
  antecedente: null,
  consultaCierre: null,
  paciente: null,

  cantidad_diagnostico: null,

  lisServiciosComplementarios: [],
});
