export default {
  namespaced: true,
  state: {
    tipoContactos: [],
    paises: [],
    listaContactos: [],
  },
  getters: {
    getTipoContactos: (state) => state.tipoContactos,
    getPaises: (state) => state.paises,
    getlistaContactos: (state) => state.listaContactos,
  },
  mutations: {
    setTipoContactos: (state, data) => { state.tipoContactos = data; },
    setPaises: (state, data) => { state.paises = data; },
    setlistaContactos: (state, data) => { state.listaContactos = data; },
    addItemlistaContactos: (state, item) => state.listaContactos.push(item),
  },
};
