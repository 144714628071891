const title = 'Atencion-Enfermeria';
const aseguradoraRoutes = [
  {
    path: 'atencion/enfermeria',
    name: 'atencion-enfermeria-index',
    component: () => import('../views/index.vue'),
    meta: {
      title,
    },
  },
];
export default aseguradoraRoutes;
