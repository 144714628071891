export default {
  namespaced: true,
  state: {
    aseguradoras: [],
  },
  getters: {
    getAseguradoras: (state) => state.aseguradoras,
  },
  mutations: {
    setAseguradoras: (state, data) => { state.aseguradoras = data; },
  },
};
