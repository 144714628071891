import store from '@/store/index';

const loginRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/index.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.auth.isAuthenticated) {
        window.location.href = 'dashboard';
      } else {
        next();
      }
    },
  },
];
export default loginRoutes;
