import moment from 'moment';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function callEditApi({ commit, dispatch }, id) {
  try {
    const RESPONSE = await axios.get(`/clinic/quotation/${id}/edit`);
    const { aseguradora } = RESPONSE.data.data;
    const ACTIVIDAD_ECONOMICA_ID = RESPONSE.data.data.cotizacion.actividad_economica_id;
    const CATEGORIA_SERVICIOS = RESPONSE.data.data.categoria_servicios;
    const { medicos } = RESPONSE.data.data;
    const { monedas } = RESPONSE.data.data;
    const LISTA_FORMA_PAGOS = RESPONSE.data.data.tipo_forma_pagos;
    const { validez } = RESPONSE.data.data;
    const COTIZACION_DETALLE = RESPONSE.data.data.cotizacion_detalle;
    const { numero } = RESPONSE.data.data.cotizacion;
    const ASEGURADORA_ID = RESPONSE.data.data.cotizacion.aseguradora_id;
    const { contacto } = RESPONSE.data.data.cotizacion;
    const { factura } = RESPONSE.data.data.cotizacion;
    const ESTADO_FACTURA = (factura === 1);
    const { fecha } = RESPONSE.data.data.cotizacion;
    const FECHA_FACTURA = new Date(moment(fecha));
    const FORMA_PAGO_ID = RESPONSE.data.data.cotizacion.forma_pago_id;
    const { glosa } = RESPONSE.data.data.cotizacion;
    const MEDICO_SELECTED_ID = RESPONSE.data.data.cotizacion.medico_id;
    const MONEDA_SELECTED_ID = RESPONSE.data.data.cotizacion.moneda_id;
    const { paciente } = RESPONSE.data.data;
    const PACIENTE_ID = RESPONSE.data.data.cotizacion.paciente_id;
    const SEGURO_MEDICO = RESPONSE.data.data.cotizacion.seguro_medico;
    const VALIDEZ_SELECTED_ID = RESPONSE.data.data.cotizacion.validez_id;
    commit('formularioCotizacion/setListaAseguradoras', aseguradora, { root: true });
    commit('modalServicios/setCategoriaServicios', CATEGORIA_SERVICIOS, { root: true });
    commit('modalServicios/setCategoriaServiciosSelected', CATEGORIA_SERVICIOS[0], { root: true });
    commit('formularioCotizacion/setListaMedicos', medicos, { root: true });
    commit('formularioCotizacion/cargarComboMoneda', monedas, { root: true });
    commit('formularioCotizacion/setListaFormaPago', LISTA_FORMA_PAGOS, { root: true });
    commit('formularioCotizacion/setListaValidez', validez, { root: true });
    commit('tablaServicios/setListaServicios', COTIZACION_DETALLE, { root: true });
    commit('modalServicios/setActividadEconomicaId', ACTIVIDAD_ECONOMICA_ID, { root: true });
    commit('formularioCotizacion/setCorrelativo', numero, { root: true });
    commit('formularioCotizacion/cargarAseguradoraSelectedById', ASEGURADORA_ID, { root: true });
    commit('formularioCotizacion/setContacto', contacto, { root: true });
    commit('formularioCotizacion/setEstado', ESTADO_FACTURA, { root: true });
    commit('formularioCotizacion/setDatepicker', FECHA_FACTURA, { root: true });
    commit('formularioCotizacion/cargarFormaPagoSelectedById', FORMA_PAGO_ID, { root: true });
    commit('glosa/setGlosa', glosa, { root: true });
    commit('formularioCotizacion/cargarMedicoSelectedById', MEDICO_SELECTED_ID, { root: true });
    commit('formularioCotizacion/cargarMonedaSelectedById', MONEDA_SELECTED_ID, { root: true });
    commit('formularioCotizacion/setPacienteSelected', paciente, { root: true });
    dispatch('cotizacionAPIS/getDatosPacienteEditable', PACIENTE_ID, { root: true });
    commit('formularioCotizacion/setShowCortinaInformativa', true, { root: true });
    commit('formularioCotizacion/setCheckSeguroEstado', (SEGURO_MEDICO === 1), { root: true });
    commit('formularioCotizacion/cargarValidezSelectedById', VALIDEZ_SELECTED_ID, { root: true });
  } catch (error) {
    util.showNotify(error.response.data.message, 'error');
  }
}
