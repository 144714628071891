import { hasPermision } from './utilidades';
import bloqueoVentas from './bloqueos/ventas';

export default {
  ...bloqueoVentas,
  hasPermisionListarVentas(state) {
    const KEY = 'VC_LISTAR';
    const ACCION = 'listar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionNuevoVentas(state) {
    const KEY = 'VC_NUEVO';
    const ACCION = 'nuevo';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionModificarVentas(state) {
    const KEY = 'VC_EDITAR';
    const ACCION = 'modificar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionVerVentas(state) {
    const KEY = 'VC_VER';
    const ACCION = 'ver';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionEliminarVentas(state) {
    const KEY = 'VC_ELIMINAR';
    const ACCION = 'eliminar';
    return hasPermision(state, KEY, ACCION);
  },
  hasPermisionImprimirVentas(state) {
    const KEY = 'VC_IMPRIMIR';
    const ACCION = 'imprimir';
    return hasPermision(state, KEY, ACCION);
  },
};
