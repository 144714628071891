import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default async function obtenerServicios({ commit, rootState }) {
  let categoriaId = 0;
  if (rootState.modalServicios.categoriaServiciosSelected !== null) {
    categoriaId = rootState.modalServicios.categoriaServiciosSelected.id;
  }
  const REQUEST = {
    categoria_id: categoriaId,
    actividad_economica_id: rootState.modalServicios.actividadEconomicaId,
    factura_tercero: false,
  };
  try {
    const RESPONSE = await axios.get('/clinic/invoicing/services', {
      params: REQUEST,
    });
    const { servicios } = RESPONSE.data.data;
    commit('modalServicios/setServicios', servicios, { root: true });
  } catch (error) {
    util.showNotify(error.response.data.message, 'error');
  }
}
