import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    caja: 0,
    banco: 0,
    tarjeta: 0,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setCaja: (state, data) => { state.caja = data; },
    setBanco: (state, data) => { state.banco = data; },
    setTarjeta: (state, data) => { state.tarjeta = data; },
  },
  actions: {
    resetFormaPagoFacturaTercero({ commit }) {
      commit('setCaja', 0);
      commit('setBanco', 0);
      commit('setTarjeta', 0);
    },
  },
};
